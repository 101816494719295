import type { UUID } from '@famirytree/treelib/interfaces';

import { useStore } from '@/hooks/store';

import useIsDemoTree from './useIsDemoTree';
import { useIsOwnTree } from './useIsOwnTree';

export function useIsSharedTree(treeId: UUID): boolean {
  const isDemoTree = useIsDemoTree();
  const isOwnTree = useIsOwnTree(treeId);
  const grants = useStore(s => s.currentTree.grants ?? []).filter(g => g.refId === treeId);
  const { shareToken } = useStore(s => s.trees[treeId]?.__meta__ ?? {});
  if (isOwnTree || grants.length > 0) {
    return false;
  }
  return !!shareToken && !isDemoTree;
}
