import { createSelector } from 'reselect';

import { AppState } from '@famirytree/crdt-storage';

export const rootSelector = (store: AppState) => store || {};
export const currentTreeStoreSelector = (store: AppState) => store.currentTree || {};
export const currentUserStoreSelector = (store: AppState) => store.currentUser;
export const treeStoreSelector = (store: AppState) => store.trees || {};
export const personsStoreSelector = (store: AppState) => store.persons;
export const errorsSelector = (store: AppState) => store.errors || {};
export const treeFetchRequestPendingSelector = (store: AppState) =>
  store.currentTree.treeFetchRequestPending || false;

export const currentTreeIdSelector = createSelector(
  currentTreeStoreSelector,
  currentTreeState => currentTreeState.treeId || '',
);

export const currentTreeOwnerId = createSelector(rootSelector, store => {
  const currentTreeId = store.currentTree.treeId || '';
  const trees = treeStoreSelector(store);

  return trees[currentTreeId]?.owner?.resource || '';
});

export const currentUserSelector = createSelector(
  currentUserStoreSelector,
  currentUserState => currentUserState.user || ({} as AppState['currentUser']['user']),
);

export const currentUserIdSelector = createSelector(
  currentUserStoreSelector,
  currentUserState => currentUserState.user?.id || '',
);

export const currentUserAuthSelector = createSelector(
  currentUserStoreSelector,
  currentUserState => currentUserState.user?.passportUser ?? false,
);
