import { forwardRef, useCallback } from 'react';

import { Button, Icon, Input, InputProps } from '@/fui';
import Cancel from '@/fui/icons/cancel.svg';
import Label from '@/fui/icons/label-outline.svg';

const AliasInput = forwardRef<HTMLInputElement, InputProps>(
    ({ onReset, ...props }, ref) => {
        const handleClick = useCallback(
            event => {
                onReset?.(event);
            },
            [onReset]
        );

        return (
            <Input
                ref={ref}
                className="AliasInput"
                start={
                    <Icon
                        size="small"
                        muted
                    >
                        <Label />
                    </Icon>
                }
                end={
                    <Button
                        icon={<Cancel />}
                        variant="text"
                        size="small"
                        title="Очистить поле"
                        onClick={handleClick}
                    />
                }
                {...props}
            />
        );
    }
);

AliasInput.displayName = 'AliasInput';

export default AliasInput;
