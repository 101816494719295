let debugGlobals: Record<string, any> | undefined = undefined;

const famWindow = window as any;

export default (): Record<string, any> => {
  if (!debugGlobals) {
    const env = Object.assign({}, process.env ?? {}, famWindow.__FAMMANIFEST ?? {});
    if (env.REACT_APP_PROPAGATE_DEBUG_GLOBALS) {
      debugGlobals = (famWindow.__FAM_DEBUG_GLOBALS as Record<string, any> | undefined) ?? {};
      famWindow.__FAM_DEBUG_GLOBALS = debugGlobals;
    } else {
      debugGlobals = {};
    }
  }
  return debugGlobals;
};
