import { MutableRefObject, useMemo, useRef } from 'react';

import { AppState, EntityMeta } from '@famirytree/crdt-storage';
import { MediaDocumentDBView, MediaDocumentReference } from '@famirytree/treelib/interfaces';

import { useStore } from '@/hooks/store';

export default useDocuments;

export type Document = MediaDocumentDBView & EntityMeta;
export type Documents = AppState['documents'];

export function useDocuments(): Documents {
  return useStore(state => state.documents);
}

export function useDocumentsRef(): MutableRefObject<Documents> {
  const documents = useDocuments();
  const documentRefs = useRef<Documents>({} as Documents);

  documentRefs.current = documents;

  return documentRefs;
}

export function useDocumentsList(items?: (string | MediaDocumentReference)[]): Document[] {
  const documents = useDocuments();

  return useMemo(
    () =>
      items?.map(item => {
        return typeof item === 'string' ? documents[item] : documents[item.resource];
      }) ?? Object.values(documents),
    [items, documents],
  );
}

export function useDocument(id: string): Document {
  const documents = useDocuments();

  return documents[id];
}
