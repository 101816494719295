import cn from 'classnames';
import React from 'react';

import './Typography.css';

type TypographyFontSize = 'XXS' | 'XS' | 'S' | 'M' | 'L' | 'XL' | 'XXL';
type TypographyFontWeight = 'bold' | 'normal';
interface TypographyProps {
  as?: React.ElementType;
  fontSize?: TypographyFontSize;
  fontWeight?: TypographyFontWeight;
  className?: string;
  children?: React.ReactNode | React.ReactNode[];
}

const Typography: React.FC<TypographyProps> = ({
  as: Tag = 'span',
  fontWeight = 'normal',
  fontSize = 'S',
  className,
  children,
}) => {
  return (
    <Tag
      className={cn('typographyFont', className, {
        typographyBold: fontWeight === 'bold',
        typographyNormal: fontWeight === 'normal',
        typographyXXS: fontSize === 'XXS',
        typographyXS: fontSize === 'XS',
        typographyS: fontSize === 'S',
        typographyM: fontSize === 'M',
        typographyL: fontSize === 'L',
        typographyXL: fontSize === 'XL',
        typographyXXL: fontSize === 'XXL',
      })}
    >
      {children}
    </Tag>
  );
};

export default Typography;
