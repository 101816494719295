import { ReactNode, useEffect } from 'react';

export default function SearchResults({ children }: { children: ReactNode }) {
    useEffect(() => {
        document
            .querySelector('.SearchResults .fui-ListItem--selected')
            ?.scrollIntoView({ block: 'center' }); //  // TODO: Add proper class to selected item
    }, []);

    return <div className="SearchResults">{children}</div>;
}
