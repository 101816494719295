import { trimObject } from '@/utils';

export interface IHttpClient {
    get<T = any>(path: string, query?: Record<string, any>): Promise<T>;
    post<T = any>(path: string, data: Record<string, any>): Promise<T>;
    put<T = any>(path: string, data: Record<string, any>): Promise<T>;
    delete(path: string): Promise<void>;
}

export default (baseUrl: string): IHttpClient => ({
    get<T = any>(path: string, query?: Record<string, any>): Promise<T> {
        const querystring = new URLSearchParams(trimObject(query)).toString();
        const url = baseUrl + path + (querystring ? `?${querystring}` : '');
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 10000);

        return fetch(url, {
            signal: controller.signal
        })
            .then(res => {
                clearTimeout(timeoutId);
                return res.json();
            })
            .then(handleResponse);
    },
    post<T = any>(path: string, data: Record<string, any>): Promise<T> {
        return fetch(baseUrl + path, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(handleResponse);
    },
    put<T = any>(path: string, data: Record<string, any>): Promise<T> {
        return fetch(baseUrl + path, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(handleResponse);
    },
    delete(path: string) {
        return fetch(baseUrl + path, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(handleResponse);
    }
});

type ResponseData = {
    ok: boolean;
    data?: any;
    error?: any;
};

function handleResponse(res: ResponseData) {
    if (res.ok) {
        return res.data;
    } else {
        throw res.error;
    }
}
