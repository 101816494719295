import { AccessStateEntryState, EntryAccessMode, EntryAccessState, EntryElement } from './types';

export const accessEntryStates: AccessStateEntryState = {
  // TreeFront
  [EntryElement.TreeFront_Advices]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.Hidden,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.Available,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Available,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Available,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.NotAvailable,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.NotAvailable,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.NotAvailable,
  },
  [EntryElement.TreeFront_TreeBase]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.ToAuthorization,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.Available,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Available,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Available,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.NotAvailable,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.NotAvailable,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.NotAvailable,
  },
  [EntryElement.TreeFront_SubscriptionStatus]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.Hidden,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.Available,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Available,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Available,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.NotAvailable,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.NotAvailable,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.NotAvailable,
  },
  [EntryElement.TreeFront_ReferralButton]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.Hidden,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.Available,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Available,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Available,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.NotAvailable,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.NotAvailable,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.NotAvailable,
  },
  [EntryElement.TreeFront_RelationshipsTreeModeSelector]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.Available,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.Available,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Available,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Available,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.NotAvailable,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.NotAvailable,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.NotAvailable,
  },
  [EntryElement.TreeFront_PersonsBase]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.ToAuthorization,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.Available,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Available,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Available,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.NotAvailable,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.NotAvailable,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.NotAvailable,
  },
  [EntryElement.TreeFront_TreePlaces]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.Hidden,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.Hidden,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Available,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Available,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.NotAvailable,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.NotAvailable,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.NotAvailable,
  },
  [EntryElement.TreeFront_TreePersonsSearchAndNavigation]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.Available,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.Available,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Available,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Available,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.NotAvailable,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.NotAvailable,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.NotAvailable,
  },
  [EntryElement.TreeFront_ExportSection]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.Available,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.Available,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Available,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Available,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.NotAvailable,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.NotAvailable,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.NotAvailable,
  },
  [EntryElement.TreeFront_Export_PDF]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.ToAuthorization,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.Available,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Available,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Available,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.NotAvailable,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.NotAvailable,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.NotAvailable,
  },
  [EntryElement.TreeFront_Export_TreePrintOrder]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.Hidden,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.Available,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Available,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Available,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.NotAvailable,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.NotAvailable,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.NotAvailable,
  },
  [EntryElement.TreeFront_Export_GEDCOM]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.Innactive,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.NotAvailable,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.NotAvailable,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.NotAvailable,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.NotAvailable,
  },
  [EntryElement.TreeFront_Export_CloudBackup]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.Hidden,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.NotAvailable,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.NotAvailable,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.NotAvailable,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.NotAvailable,
  },
  [EntryElement.TreeFront_SharingSection]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.ToAuthorization,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.Innactive,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Innactive,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Innactive,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.NotAvailable,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.NotAvailable,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.NotAvailable,
  },
  [EntryElement.TreeFront_Account]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.ToAuthorization,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.Available,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Available,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Available,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.NotAvailable,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.NotAvailable,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.NotAvailable,
  },
  [EntryElement.TreeFront_AccessNotificationHead]: {
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Owner]: EntryAccessState.Hidden,
    [EntryAccessMode.Public_Guest]: EntryAccessState.Available,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.Available,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Available,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Available,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.NotAvailable,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.NotAvailable,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.NotAvailable,
  },
  [EntryElement.TreeFront_Help]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.Available,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.Available,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Available,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Available,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.NotAvailable,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.NotAvailable,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.NotAvailable,
  },
  [EntryElement.TreeFront_Chat]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.Hidden,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.Available,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Available,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Available,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.NotAvailable,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.NotAvailable,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.NotAvailable,
  },
  [EntryElement.TreeFront_TreeName]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.Readonly,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.Readonly,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Readonly,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Readonly,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.NotAvailable,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.NotAvailable,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.NotAvailable,
  },
  [EntryElement.TreeFront_PersonAddView]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.Innactive,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.Innactive,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Innactive,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Available,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.NotAvailable,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.NotAvailable,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.NotAvailable,
  },
  // TreeRenderer
  [EntryElement.TreeRenderer_ToBigPersonCard]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.ToAuthorization,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.Innactive,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Available,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Available,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.NotAvailable,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.NotAvailable,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.NotAvailable,
  },
  [EntryElement.TreeRenderer_AddPerson]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.ToAuthorization,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.Available,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Available,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Available,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.NotAvailable,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.NotAvailable,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.NotAvailable,
  },
  [EntryElement.TreeRenderer_ToSmallPersonCard]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.Available,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.Available,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Available,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Available,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.NotAvailable,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.NotAvailable,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.NotAvailable,
  },
  [EntryElement.TreeFront_AudioMemo]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Innactive,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.NotAvailable,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.NotAvailable,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.NotAvailable,
  },
  // -- TreeRenderer_MenuSection]: {},
  [EntryElement.TreeRenderer_Menu_InviteToTree]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.Innactive,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.Innactive,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Innactive,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Innactive,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.NotAvailable,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.NotAvailable,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.NotAvailable,
  },
  [EntryElement.TreeRenderer_Menu_DeletePerson]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.Innactive,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.Innactive,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Innactive,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Available,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.NotAvailable,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.NotAvailable,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.NotAvailable,
  },
  [EntryElement.TreeRenderer_Menu_SetAsMainPerson]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.Innactive,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.Innactive,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Innactive,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Innactive,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.NotAvailable,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.NotAvailable,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.NotAvailable,
  },
  [EntryElement.TreeRenderer_Menu_RearrangeFromThisPerson]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.Available,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.Available,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Available,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Available,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.NotAvailable,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.NotAvailable,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.NotAvailable,
  },
  [EntryElement.TreeRenderer_Menu_EditRelationships]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.Innactive,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.Innactive,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Available,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Available,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.NotAvailable,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.NotAvailable,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.NotAvailable,
  },
  [EntryElement.TreeRenderer_PersonProgress]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.Hidden,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.Hidden,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Hidden,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Available,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.NotAvailable,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.NotAvailable,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.NotAvailable,
  },
  // PersonsBase
  [EntryElement.PersonsBase_AddPerson]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.Hidden,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Innactive,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Available,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.NotAvailable,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.NotAvailable,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.NotAvailable,
  },
  [EntryElement.PersonsBase_TreePersonsSearch]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.Available,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Available,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Available,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.NotAvailable,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.NotAvailable,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.NotAvailable,
  },
  [EntryElement.PersonsBase_ShowPersonOnTree]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.Available,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Available,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Available,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.NotAvailable,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.NotAvailable,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.NotAvailable,
  },
  [EntryElement.PersonsBase_RearrangeFromThisPerson]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.Available,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Available,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Available,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.NotAvailable,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.NotAvailable,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.NotAvailable,
  },
  [EntryElement.PersonsBase_DeletePerson]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.Innactive,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Innactive,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Available,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.NotAvailable,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.NotAvailable,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.NotAvailable,
  },
  [EntryElement.PersonsBase_ToBigPersonCard]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.Innactive,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Available,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Available,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.NotAvailable,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.NotAvailable,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.NotAvailable,
  },
  [EntryElement.PersonsBase_EditRelationships]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.Innactive,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Available,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Available,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.NotAvailable,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.NotAvailable,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.NotAvailable,
  },
  // TreesBase
  [EntryElement.TreesBase_CreateTree]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.Available,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Available,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Available,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.NotAvailable,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.NotAvailable,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.NotAvailable,
  },
  [EntryElement.TreesBase_ImportTreeFromGedcom]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.Available,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Available,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Available,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.NotAvailable,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.NotAvailable,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.NotAvailable,
  },
  [EntryElement.TreesBase_DeleteTree]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.Available,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Available,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Available,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.NotAvailable,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.NotAvailable,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.NotAvailable,
  },
  // SmallPersonCard
  [EntryElement.SmallPersonCard_UploadAvatar]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.Hidden,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Hidden,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Available,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.Readonly,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.Available,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.NotAvailable,
  },
  [EntryElement.SmallPersonCard_InputForm]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.Readonly,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.Readonly,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Readonly,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Available,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.Readonly,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.Available,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.NotAvailable,
  },
  [EntryElement.SmallPersonCard_InvitationEmailData]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.Hidden,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.Hidden,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Hidden,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Hidden,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.Hidden,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.Hidden,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.NotAvailable,
  },
  [EntryElement.SmallPersonCard_InviteToTreeCheckbox]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.Hidden,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.Hidden,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Hidden,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Hidden,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.Hidden,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.Hidden,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.NotAvailable,
  },
  [EntryElement.SmallPersonCard_ExpandDatesAndPlaces]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.Available,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.Available,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Available,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Available,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.Available,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.Available,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.NotAvailable,
  },
  [EntryElement.SmallPersonCard_ToBigCard]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.Hidden,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Available,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Available,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.Available,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.Available,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.NotAvailable,
  },
  [EntryElement.SmallPersonCard_SaveButton]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.Hidden,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.Hidden,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Hidden,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Available,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.Hidden,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.Available,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.NotAvailable,
  },
  [EntryElement.SmallPersonCard_CancelButton]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.Available,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.Available,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Available,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Available,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.Available,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.Available,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.NotAvailable,
  },
  [EntryElement.SmallPersonCard_PersonProgress]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.Hidden,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.Hidden,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Hidden,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Available,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.Hidden,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.Available,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.NotAvailable,
  },
  [EntryElement.SmallPersonCard_AnnotationHead]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.Hidden,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.Hidden,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Hidden,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Available,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.Hidden,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.Available,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.Hidden,
  },
  [EntryElement.SmallPersonCard_AnnotationInput]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.Readonly,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.Readonly,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Readonly,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Available,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.Readonly,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.Available,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.Readonly,
  },
  // BigPersonCard
  [EntryElement.BigPersonCard_SharePerson]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Hidden,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Hidden,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.Hidden,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.Hidden,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.Hidden,
  },
  [EntryElement.BigPersonCard_UploadAvatar]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Hidden,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Available,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.Hidden,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.Available,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.Hidden,
  },
  [EntryElement.BigPersonCard_InviteToTreeCheckbox]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Hidden,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Hidden,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.Hidden,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.Hidden,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.Hidden,
  },
  [EntryElement.BigPersonCard_MainInfoEditTools]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Readonly,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Available,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.Readonly,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.Available,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.Readonly,
  },
  [EntryElement.BigPersonCard_AddFacts]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.Innactive,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Innactive,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Available,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.NotAvailable,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.Available,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.Hidden,
  },
  [EntryElement.BigPersonCard_MainInfoTab]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Available,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Available,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.Available,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.Available,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.Available,
  },
  [EntryElement.BigPersonCard_FactsTab]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Available,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Available,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.Hidden,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.Available,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.Available,
  },
  [EntryElement.BigPersonCard_InvitationEmailData]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Hidden,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Hidden,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.Hidden,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.Hidden,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.Hidden,
  },
  [EntryElement.BigPersonCard_InputForm]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.Readonly,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.Readonly,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Readonly,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Available,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.Readonly,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.Available,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.Readonly,
  },
  [EntryElement.BigPersonCard_FactsEditForm]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Hidden,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Available,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.NotAvailable,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.Available,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.NotAvailable,
  },
  [EntryElement.BigPersonCard_PlaceInput]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Readonly,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Available,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.Readonly,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.Available,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.Readonly,
  },
  [EntryElement.BigPersonCard_Map]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Available,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.NotAvailable,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.Available,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.NotAvailable,
  },
  [EntryElement.BigPersonCard_ExpandDatesAndPlaces]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Available,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Available,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.Available,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.Available,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.Available,
  },
  [EntryElement.BigPersonCard_FulfillTreeDataForMe]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Available,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Available,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.Available,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.Available,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.Available,
  },
  [EntryElement.BigPersonCard_PersonProgress]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.Hidden,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.Hidden,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Hidden,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Available,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.Available,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.Hidden,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.Hidden,
  },
  [EntryElement.BigPersonCard_AnnotationHead]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.Hidden,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.Hidden,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Hidden,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Available,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.Hidden,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.Available,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.Hidden,
  },
  [EntryElement.BigPersonCard_AnnotationInput]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.Readonly,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.Readonly,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Readonly,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Available,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.Readonly,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.Available,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.Readonly,
  },
  // RelationshipEditor
  [EntryElement.RelationshipEditor_DeleteRelationship]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Innactive,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Available,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.NotAvailable,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.NotAvailable,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.NotAvailable,
  },
  [EntryElement.RelationshipEditor_DeletePerson]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Innactive,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Available,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.NotAvailable,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.NotAvailable,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.NotAvailable,
  },
  [EntryElement.RelationshipEditor_RearrangeTreeFromPerson]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Innactive,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Available,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.NotAvailable,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.NotAvailable,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.NotAvailable,
  },
  [EntryElement.RelationshipEditor_AddPerson]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Guest]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.NotAvailable,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Innactive,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Available,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.NotAvailable,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.NotAvailable,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.NotAvailable,
  },
  [EntryElement.TreeConfig_PersonProgress]: {
    [EntryAccessMode.Owner]: EntryAccessState.Available,
    [EntryAccessMode.Unknown]: EntryAccessState.Hidden,
    [EntryAccessMode.Public_Guest]: EntryAccessState.Hidden,
    [EntryAccessMode.Public_Authorized]: EntryAccessState.Hidden,
    [EntryAccessMode.Collaborative_View]: EntryAccessState.Hidden,
    [EntryAccessMode.Collaborative_Edit]: EntryAccessState.Available,
    [EntryAccessMode.PublicPerson_Guest]: EntryAccessState.NotAvailable,
    [EntryAccessMode.CollaborativeCard_Edit]: EntryAccessState.NotAvailable,
    [EntryAccessMode.CollaborativeCard_View]: EntryAccessState.NotAvailable,
  },
};
