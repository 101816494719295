import './SuspenseLoader.css';

export const Spinner = () => <div className="reverse-spinner" />;

export const SuspenseLoader = () => (
  <div className="reverse-spinner_container">
    <Spinner />
  </div>
);

export default SuspenseLoader;
