import { useCallback } from 'react';

import { Button, Field, Text } from '@/fui';
import Clipboard from '@/fui/icons/paste-clipboard.svg';

export default function Coordinates({
    lat,
    lon
}: {
    lat: number;
    lon: number;
}) {
    const handleCoordsCopyClick = useCallback(
        event => {
            event.stopPropagation();

            navigator.clipboard.writeText(`${lat}, ${lon}`);
        },
        [lat, lon]
    );

    return (
        <Field
            label="Координаты:"
            size="small"
            labelProps={{ size: 'small' }}
            inline
        >
            <Text
                type="p4"
                content={`${Number(lat).toFixed(6)}, ${Number(lon).toFixed(6)}`}
                end={
                    <Button
                        icon={<Clipboard />}
                        size="small"
                        variant="text"
                        iconProps={{ size: 'smallest' }}
                        title="Скопировать координаты"
                        onClick={handleCoordsCopyClick}
                    />
                }
                muted
            />
        </Field>
    );
}
