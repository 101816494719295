import { useCallback, useContext, useEffect, useState } from 'react';

import {
  Arranger,
  ArrangerEventType,
  RendererContext,
  RendererContextEvents,
  Scene,
  SceneEventType,
} from '@famirytree/renderer';
import { Tree } from '@famirytree/treelib';

export function useScene() {
  const sceneContext = useContext(RendererContext);

  const [scene, setScene] = useState<Scene | null>(sceneContext.scene);

  useEffect(() => {
    function handleSceneCreate(scene: Scene) {
      setScene(scene);
    }

    sceneContext.ee.on(RendererContextEvents.SCENE_CREATED, handleSceneCreate);

    return () => {
      sceneContext.ee.off(RendererContextEvents.SCENE_CREATED, handleSceneCreate);
    };
  }, [sceneContext]);

  return scene;
}

export function useTreeModel(): Tree | null {
  const arranger = useArranger();
  if (!arranger) {
    return null;
  }
  return arranger.tree;
}

export function useArranger() {
  const scene = useScene();

  const [arranger, setArranger] = useState<Arranger | null>(scene?.arranger as Arranger);
  const [, rerender] = useState(false);

  useEffect(() => {
    if (!scene) {
      return;
    }

    function handleTreeSet() {
      if (scene?.arranger) {
        setArranger(scene.arranger);
      }
    }

    scene.on(SceneEventType.EXT_NEW_TREE_SET, handleTreeSet);

    return () => {
      scene.off(SceneEventType.EXT_NEW_TREE_SET, handleTreeSet);
    };
  }, [scene]);

  useEffect(() => {
    if (!arranger) {
      return;
    }

    function handleArrangerReset() {
      rerender(v => !v);
    }

    arranger.on(ArrangerEventType.RESET, handleArrangerReset);

    return () => {
      arranger.off(ArrangerEventType.RESET, handleArrangerReset);
    };
  }, [arranger]);

  return arranger;
}

export default function useRenderer() {
  const sceneContext = useContext(RendererContext);

  const [state, updateState] = useState<{
    scene: Scene | null;
    arranger: Arranger | null;
    isReady: boolean;
  }>({
    scene: null,
    arranger: null,
    isReady: false,
  });

  const [, setDummyTrigger] = useState<boolean>(false);

  const setScene = useCallback(() => {
    updateState(state => ({
      ...state,
      scene: sceneContext.scene,
      arranger: sceneContext.scene?.arranger ?? state.arranger,
    }));
  }, []);

  const setArranger = useCallback(() => {
    updateState(state => ({
      ...state,
      arranger: sceneContext.scene?.arranger ?? state.arranger,
    }));
  }, []);

  useEffect(() => {
    if (!sceneContext.scene) {
      sceneContext.ee.on(RendererContextEvents.SCENE_CREATED, setScene);
    } else {
      if (sceneContext.scene && !state.scene) {
        setScene();
      }
    }
    return () => {
      sceneContext.ee.off(RendererContextEvents.SCENE_CREATED, setScene);
    };
  }, [sceneContext.scene]);

  useEffect(() => {
    if (state.scene) {
      const { scene } = state;
      if (!state.arranger && sceneContext?.scene?.arranger) {
        setArranger();
      } else {
        scene.on(SceneEventType.EXT_NEW_TREE_SET, setArranger);
      }
      return () => {
        scene?.off(SceneEventType.EXT_NEW_TREE_SET, setArranger);
      };
    }
  }, [state.scene, sceneContext?.scene]);

  useEffect(() => {
    if (state.arranger) {
      const { arranger } = state;
      const cb = () => {
        setDummyTrigger(a => !a);
      };
      arranger.on(ArrangerEventType.RESET, cb);
      return () => {
        arranger.off(ArrangerEventType.RESET, cb);
      };
    }
  }, [state.arranger]);

  return state;
}
