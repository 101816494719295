import Modal from '@/components/common/Modal';
import { toBase64 } from '@/utils/data';
import { DOMAIN } from '@/vars/domain';

import useAuthorizationModal from './useAuthorizationModal';

import './AuthorizationModal.css';

export default function AuthorizationModal({
  action,
  payload,
  clientMode = '',
  onClose,
}: {
  action?: any;
  payload?: any;
  clientMode?: string;
  onClose: () => void;
}) {
  const { iframeRef, onIframeLoaded } = useAuthorizationModal();

  const iframeUrl = new URL(`${DOMAIN}/tree/v1/auth/`);

  iframeUrl.searchParams.set('isframe', '1');
  iframeUrl.searchParams.set('initialPath', `${document.location.origin}/tree`);

  if (action) {
    iframeUrl.searchParams.set('action', action);
  }

  if (payload) {
    iframeUrl.searchParams.set('actionPayload', toBase64(payload));
  }

  if (clientMode) {
    iframeUrl.searchParams.set('client_mode', clientMode);
  }

  return (
    <Modal
      add_class="nopadding"
      content={
        <iframe
          ref={iframeRef}
          src={iframeUrl.href}
          height="640"
          className="iframeNeedToAuth"
          scrolling="no"
          onLoad={onIframeLoaded}
        >
          Ваш браузер не поддерживает плавающие фреймы!
        </iframe>
      }
      onClose={onClose}
    />
  );
}
