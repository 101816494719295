import { ReactNode, useCallback, useEffect } from 'react';

import { Drawer, Provider as ThemeProvider } from '@famiryui/components';

import AliasForm from './components/AliasForm';
import Search from './components/Search';
import MapContext from './contexts/MapContext';
import useMap from './hooks/map';
import {
    Alias,
    AliasData,
    LatLon,
    Map,
    MapOptions,
    MapProvider,
    Marker,
    Place
} from './lib/map';

import './FamiryMap.scss';

export type FamiryMapRef = Map;

export type FamiryMapProps<
    TMapOptions = MapOptions,
    TDetailViewData = unknown
> = {
    apiUrl: string;
    provider?: MapProvider;
    query?: string;
    alias?: Alias;
    place?: Place;
    markers?: Marker[];
    center?: LatLon;
    zoom?: number;
    maxZoom?: number;
    minZoom?: number;
    options?: TMapOptions;
    userPassportId?: string;
    meta?: Record<string, any>;
    detailView?: (data: TDetailViewData) => ReactNode;
    onAliasSaved?: (alias: Alias) => void;
};

export default function FamiryMap<
    TMapOptions = MapOptions,
    TDetailViewData = unknown
>({
    apiUrl,
    provider = MapProvider.Mapbox,
    alias,
    place,
    markers,
    query,
    center,
    zoom,
    maxZoom,
    minZoom,
    options,
    userPassportId,
    meta,
    detailView,
    onAliasSaved,
    ...props
}: FamiryMapProps<TMapOptions, TDetailViewData>) {
    const map = useMap(
        apiUrl,
        provider,
        { center, zoom, maxZoom, minZoom, ...options },
        { alias, place }
    );

    useEffect(() => {
        if (!alias && query) {
            map.search({ q: query });
        }
    }, [alias, query]);

    useEffect(() => {
        if (!alias) return;

        if (typeof alias === 'string') {
            map.getAlias(alias).then(alias => {
                map.editAlias(alias);
            });
        } else if (typeof alias === 'object') {
            map.editAlias(alias);
        }
    }, [alias]);

    useEffect(() => {
        if (!markers) return;

        map.placeMarkers(markers);
    }, [markers]);

    const handleSearch = useCallback(
        (params: Record<string, any>) => {
            params.userPassportId = userPassportId;
            return map.search(params);
        },
        [map, userPassportId]
    );

    const handleEditAlias = useCallback(
        (alias: AliasData) => {
            map.editAlias(alias);
        },
        [map]
    );

    const handleSelectAlias = useCallback(
        (alias: Alias) => {
            map.selectAlias(alias);
        },
        [map]
    );

    const handleSelectAliasWithMarker = useCallback(
        (alias: Alias) => {
            map.selectAliasWithMarker(alias);
        },
        [map]
    );

    const handleSaveAlias = useCallback(
        (aliasData: AliasData) => {
            aliasData.userPassportId = userPassportId;
            aliasData.meta = meta;

            map.saveAlias(aliasData).then(alias => onAliasSaved?.(alias));
        },
        [map, userPassportId, meta, onAliasSaved]
    );

    const handleSelectPlace = useCallback(
        (place: Place) => {
            map.selectPlace(place);
        },
        [map]
    );

    const handleCancelAliasForm = useCallback(() => {
        map.unsetAlias();
    }, [map]);

    const handleObjectDrawerClose = useCallback(() => {
        map.resetMarkers();
    }, [map]);

    const handleObjectDrawerOpenedOrClosed = useCallback(() => {
        const { lat, lon } = map.state.marker || {};
        const latLon =
            lat && lon ? ([lat, lon] as [number, number]) : undefined;

        map.layout(latLon);
    }, [map]);

    const handleClear = useCallback(() => {
        map.reset();
    }, [map]);

    return (
        <div
            className="FamiryMap"
            {...props}
        >
            <MapContext.Provider value={map}>
                <ThemeProvider>
                    {markers ? (
                        <Drawer
                            open={!!map.state.marker}
                            onClose={handleObjectDrawerClose}
                            onOpened={handleObjectDrawerOpenedOrClosed}
                            onClosed={handleObjectDrawerOpenedOrClosed}
                        >
                            {map.state.marker &&
                                detailView?.(map.state.marker.data)}
                        </Drawer>
                    ) : (
                        <Drawer>
                            <Search
                                heading={
                                    map.state.alias
                                        ? 'Поиск по карте'
                                        : 'Определение географии'
                                }
                                query={query}
                                collapsible={!!map.state.alias}
                                onSearch={handleSearch}
                                onEditAlias={handleEditAlias}
                                onCreateAlias={handleSaveAlias}
                                onSelectAlias={handleSelectAlias}
                                onSelectAliasWithMarker={
                                    handleSelectAliasWithMarker
                                }
                                onSelectPlace={handleSelectPlace}
                                onClear={handleClear}
                            />

                            {map.state.alias && (
                                <AliasForm
                                    alias={map.state.alias}
                                    onSave={handleSaveAlias}
                                    onCancel={handleCancelAliasForm}
                                />
                            )}
                        </Drawer>
                    )}
                </ThemeProvider>
            </MapContext.Provider>

            <div
                className="FamiryMap__map"
                ref={map.rootRef}
            />
        </div>
    );
}
