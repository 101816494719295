import L from 'leaflet';

import type { LatLon, MapClient, MapOptions } from '../types';

import 'leaflet.markercluster';

declare module 'leaflet' {
    interface Marker {
        $setIcon(icon: L.Icon | L.IconOptions): this;
    }
}

export type LeafletOptions = L.MapOptions &
    MapOptions & {
        imagePath?: string;
    };

export type MarkerOptions = L.MarkerOptions & {
    [key: string]: any;
    label?: string;
    popup?: string;
    tooltip?: string;
    icon?: L.IconOptions;
    onClick?: (marker: L.Marker) => void;
    onDragEnd?: (marker: L.Marker, position: LatLon) => void;
};

L.Marker.include({
    $setIcon(icon: L.Icon | L.IconOptions) {
        if (icon instanceof L.Icon) {
            return L.Marker.prototype.setIcon.call(this, icon);
        } else {
            return L.Marker.prototype.setIcon.call(this, new L.Icon(icon));
        }
    }
});

export default abstract class LeafletClient implements MapClient {
    protected _root: HTMLElement;
    protected _container: HTMLElement;
    protected _map: L.Map = {} as L.Map;
    protected _options: LeafletOptions = {};
    protected _markers: Set<any> = new Set();

    constructor(root: HTMLElement, options: LeafletOptions = {}) {
        if (!root) throw new Error('Root element not found');

        this._root = root;
        this._options = options;
        this._container = document.createElement('div');

        if (options.imagePath) {
            L.Icon.Default.prototype.options.imagePath = options.imagePath;
        }
    }

    get element() {
        return this._container;
    }

    get markers() {
        return Array.from(this._markers);
    }

    async init() {
        this._container.className = 'map';
        this._root.appendChild(this._container);

        this._map = L.map(this._container, this._options);
        this._map.attributionControl.setPrefix(false);
        this._map.zoomControl.setPosition('bottomright');
    }

    destroy() {
        this._map.remove();
        this._container.remove();
    }

    getCenter(): LatLon {
        const { lat, lng } = this._map.getCenter();
        return [lat, lng];
    }

    setCenter(position: LatLon, zoom?: number) {
        this._map.setView(position, zoom);
    }

    getZoom() {
        return this._map.getZoom();
    }

    setZoom(zoom: number) {
        this._map.setZoom(zoom);
    }

    fitBounds(bounds: [LatLon, LatLon]) {
        this._map.fitBounds(bounds);
    }

    createMarker(position: LatLon, options: MarkerOptions = {}): L.Marker {
        const { icon, popup, tooltip, onClick, onDragEnd, ...rest } = options;

        if (icon !== undefined) {
            rest.icon = new L.Icon(icon);
        }

        const marker = new L.Marker(position, {
            draggable: true,
            riseOnHover: true,
            ...rest
        });

        if (popup) {
            marker.bindPopup(popup);
        }

        if (tooltip) {
            marker.bindTooltip(tooltip);
        }

        if (onClick) {
            marker.on('click', event => {
                onClick(event.target as L.Marker);
            });
        }

        if (onDragEnd) {
            marker.on('dragend', event => {
                const { lat, lng } = event.target.getLatLng();
                onDragEnd(event.target as L.Marker, [lat, lng]);
            });
        }

        return marker;
    }

    addMarker(position: LatLon, options: MarkerOptions = {}): L.Marker {
        const marker = this.createMarker(position, options);

        marker.addTo(this._map);
        this._markers.add(marker);

        return marker;
    }

    removeMarkers() {
        this._markers.forEach(marker => this._map.removeControl(marker));
        this._markers.clear();
    }

    addClusterGroup(markers: L.Marker[]) {
        const clusterGroup = L.markerClusterGroup({
            showCoverageOnHover: false
        });

        for (const marker of markers) clusterGroup.addLayer(marker);

        this._map.addLayer(clusterGroup);
        clusterGroup.refreshClusters();
    }

    clear() {
        this.removeMarkers();
    }

    layout() {
        this._map.invalidateSize({
            animate: true
        });
    }
}
