// @ts-ignore
const ENV = Object.assign({}, process.env ?? {}, window.__FAMMANIFEST ?? {});

export default ENV;

export const IS_DEV = window.location.host !== 'famiry.ru';

if (IS_DEV) console.info('dev ENV:', JSON.stringify(ENV, null, 2));

export const REACT_APP_NOAUTH = ENV.REACT_APP_NOAUTH;
export const REACT_APP_AUTH_API_PATH = ENV.REACT_APP_AUTH_API_PATH;
export const REACT_APP_MAIN_API_PATH = ENV.REACT_APP_MAIN_API_PATH;
export const REACT_APP_SENTRY_API_DSN = ENV?.REACT_APP_SENTRY_API_DSN || '';
export const REACT_APP_SENTRY_RELEASE = ENV?.REACT_APP_SENTRY_RELEASE || '';
export const REACT_APP_YA_METRICS_COUNTER_ID = ENV?.REACT_APP_YA_METRICS_COUNTER_ID || '';
export const REACT_APP_CLIENT_ID = ENV?.REACT_APP_CLIENT_ID;

export const REACT_APP_BLOCKED_TREE: string[] = (ENV?.REACT_APP_BLOCKED_TREE ?? '')
  .split(',')
  .map(s => s.trim())
  .filter(Boolean);

if (!REACT_APP_CLIENT_ID) {
  throw new Error(`No drevo service client id provied`);
}

export const MAPBOX_ACCESS_TOKEN = ENV.REACT_APP_MAPBOX_ACCESS_TOKEN;
export const MAPBOX_PROJECT = ENV.REACT_APP_MAPBOX_PROJECT;
export const MAPBOX_STYLE_ID = ENV.REACT_APP_MAPBOX_STYLE_ID;
export const MAPS_SERVICE_URL = ENV.REACT_APP_MAPS_SERVICE_URL;

const REACT_APP_SPRITE_STORAGE_VERSION = ENV.REACT_APP_SPRITE_STORAGE_VERSION ?? '0';

export const SPRITES_VERSION = REACT_APP_SPRITE_STORAGE_VERSION;
