import type { UUID } from '@famirytree/treelib/interfaces';

import { usePersonsOnTree } from '@/hooks/persons';

export function useIsPersonOnTree(personId: UUID) {
  const personsOnTree = usePersonsOnTree();

  return Boolean(personsOnTree[personId]);
}

export default useIsPersonOnTree;
