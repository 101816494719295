import { RefObject, useCallback, useEffect, useMemo, useRef } from 'react';

import HttpClient from '@/lib/http';
import MapClient, {
    Alias,
    AliasData,
    type LatLon,
    MapOptions,
    MapProvider,
    Marker,
    Place,
    PlaceData
} from '@/lib/map';

import useStore, { InitialState, State } from './store';

export type Map = {
    rootRef: RefObject<HTMLDivElement>;
    state: State;
    search: (params: Record<string, any>) => Promise<void>;
    getAlias: (aliasId: string) => Promise<Alias>;
    selectAlias: (alias: Alias) => void;
    selectAliasWithMarker: (alias: Alias) => void;
    editAlias: (aliasData: AliasData) => void;
    saveAlias: (alias: AliasData) => Promise<Alias>;
    unsetAlias: () => void;
    selectPlace: (place: Place) => void;
    placeMarkers: (markers: Marker[]) => void;
    resetMarkers: () => void;
    reset: () => void;
    layout: (latLon?: LatLon) => void;
};

export default function useMap(
    apiUrl: string,
    provider: MapProvider,
    options: MapOptions = {},
    initialState: InitialState
): Map {
    const rootRef = useRef<HTMLDivElement>(null);
    const httpRef = useRef(HttpClient(apiUrl));
    const mapRef = useRef<MapClient>();
    const optionsRef = useRef<MapOptions>(options);

    const { state, actions } = useStore(initialState);

    useEffect(() => {
        if (!rootRef.current) return;

        const map = new MapClient(
            rootRef.current,
            provider,
            optionsRef.current
        );

        map.init();

        mapRef.current = map;
    }, [provider]);

    const search = useCallback((params: Record<string, any>) => {
        actions.reset();
        actions.unsetError();

        mapRef.current?.removeMarkers();

        return Promise.allSettled<[Promise<Alias[]>, Promise<Place[]>]>([
            httpRef.current.get('/aliases/search', params),
            httpRef.current.get('/places/search', params)
        ])
            .then(([aliases, places]) => {
                if (places.status === 'rejected')
                    actions.setError(places.reason);
                actions.setAliases(
                    (aliases as PromiseFulfilledResult<Alias[]>).value
                );
                actions.setPlaces(
                    (places as PromiseFulfilledResult<Place[]>).value
                );
            })
            .catch(error => {
                actions.setError(error);
                console.error(error);
            });
    }, []);

    const getAlias = useCallback((aliasId: string) => {
        return httpRef.current.get<Alias>(`/aliases/${aliasId}`);
    }, []);

    const placeAliasMarker = useCallback(
        (
            alias: Alias = {} as Alias,
            options: { lat?: number; lon?: number; zoom?: number } = {}
        ) => {
            const map = mapRef.current;

            const lat = alias.lat || alias.place?.lat || options.lat || 0;
            const lon = alias.lon || alias.place?.lon || options.lon || 0;

            map?.removeMarkers();
            map?.setCenter([lat, lon], options.zoom);
            return map?.addMarker([lat, lon], {
                draggable: !state.error,
                onDragEnd: (marker, [lat, lon]) => {
                    marker.dragging?.disable();

                    map?.setCenter([lat, lon]);

                    httpRef.current
                        .get<PlaceData>('/places/search', { lat, lon })
                        .then(place => {
                            actions.setAlias({
                                ...(alias as Alias),
                                lat,
                                lon,
                                place: place as Place
                            });
                        })
                        .finally(() => {
                            marker.dragging?.enable();
                        });
                }
            });
        },
        [state.error]
    );

    const editAlias = useCallback(
        (alias: AliasData) => {
            actions.setAlias(alias as Alias);
            placeAliasMarker(alias as Alias, { zoom: 10 });
        },
        [placeAliasMarker]
    );

    const selectAlias = useCallback(
        (alias: Alias) => {
            placeAliasMarker(alias, { zoom: 10 });
        },
        [placeAliasMarker]
    );

    const selectAliasWithMarker = useCallback(
        (alias: Alias) => {
            const map = mapRef.current;
            const [lat, lon] = map?.getCenter() ?? [];

            actions.unsetError();
            actions.setAlias(alias);
            placeAliasMarker(alias, { lat, lon })
                ?.bindTooltip('Перетащите маркер чтобы выбрать место', {
                    direction: 'top',
                    offset: [-16, -16]
                })
                .openTooltip();
        },
        [state.error]
    );

    const saveAlias = useCallback((alias: AliasData) => {
        const http = httpRef.current;
        const place = alias.place;
        const placeNeedsToBeSaved = !place?.id && place?.placeId;

        return (
            placeNeedsToBeSaved
                ? http.post('/places', place)
                : Promise.resolve(place)
        )
            .then(place => {
                delete alias.place;

                if (place?.id) {
                    alias.placeId = place.id;
                } else {
                    alias.placeId = undefined;
                    alias.lat = place?.lat;
                    alias.lon = place?.lon;
                }

                return alias.id
                    ? http.put<Alias>(`/aliases/${alias.id}`, alias)
                    : http.post<Alias>('/aliases', alias);
            })
            .then(alias => {
                actions.reset();

                if (alias.placeId) alias.place = place;

                return alias;
            });
    }, []);

    const unsetAlias = useCallback(() => {
        actions.unsetAlias();
    }, []);

    const selectPlace = useCallback(
        (place: Place) => {
            const { lat, lon } = place;

            placeAliasMarker({ lat, lon, place } as Alias, { zoom: 10 });
        },
        [placeAliasMarker]
    );

    const placeMarkers = useCallback((markers: Marker[]) => {
        const map = mapRef.current;
        const mapMarkers: any[] = [];

        let minLat = Infinity,
            minLon = Infinity;
        let maxLat = -Infinity,
            maxLon = -Infinity;

        if (!map || !markers) return;

        for (const marker of markers) {
            const { lat, lon, hint, icon, activeIcon } = marker;

            if (!lat || !lon) continue;

            const mapMarker = map.createMarker([lat, lon], {
                icon,
                tooltip: hint,
                draggable: false,
                onClick: mapMarker => {
                    actions.setMarker({ ...marker, lat, lon });

                    for (const marker of mapMarkers) {
                        marker.$setIcon(icon);
                    }

                    if (activeIcon) {
                        mapMarker.$setIcon(activeIcon);
                    }

                    map?.setCenter([lat, lon]);
                }
            });

            mapMarkers.push(mapMarker);

            if (lat < minLat) minLat = lat;
            if (lon < minLon) minLon = lon;
            if (lat > maxLat) maxLat = lat;
            if (lon > maxLon) maxLon = lon;
        }

        if (mapMarkers.length === 0) return;

        map.removeMarkers();
        map.addClusterGroup(mapMarkers);
        map.fitBounds([
            [maxLat, maxLon],
            [minLat, minLon]
        ]);
        if (map.getZoom() > 12) {
            map.setZoom(12);
        }
    }, []);

    const resetMarkers = useCallback(() => {
        if (!state.marker) return;

        const initialIcon = state.marker.icon;

        mapRef.current?.markers?.forEach(marker => {
            marker.$setIcon(initialIcon);
        });

        setTimeout(() => {
            mapRef.current?.layout();
        }, 0);

        actions.unsetMarker();
    }, [state.marker]);

    const reset = useCallback(() => {
        actions.reset();
    }, []);

    const layout = useCallback(latLon => {
        mapRef.current?.layout();

        if (latLon) {
            mapRef.current?.setCenter(latLon);
        }
    }, []);

    return useMemo(
        () => ({
            rootRef,
            state,
            search,
            getAlias,
            editAlias,
            saveAlias,
            unsetAlias,
            selectAlias,
            selectAliasWithMarker,
            selectPlace,
            placeMarkers,
            resetMarkers,
            reset,
            layout
        }),
        [state]
    );
}
