import cn from 'classnames';
import type { HTMLAttributes, ReactNode } from 'react';

import { ReactComponent as AlertIcon } from '@famiryui/icons/svg/warning-circled-outline.svg';

import './Alert.scss';

export type AlertVariant = 'success' | 'warning' | 'info' | 'error';

export interface AlertProps extends HTMLAttributes<HTMLDivElement> {
  title?: string;
  variant?: AlertVariant;
  closeLabel?: string;
  showIcon?: boolean;
  children: string | ReactNode | ReactNode[];
  onClose?: () => void;
}

export default function Alert({
  children,
  title,
  variant = 'info',
  onClose,
  closeLabel = 'Скрыть',
  showIcon = false,
}: AlertProps) {
  return (
    <div className={cn('Alert', 'Alert_' + variant)}>
      {showIcon && <AlertIcon className="Alert__icon" />}

      <div className="Alert__content">
        {title && <div className="Alert__title">{title}</div>}

        {children}

        {onClose && (
          <button
            className="Alert__close"
            onClick={onClose}
          >
            {closeLabel}
          </button>
        )}
      </div>
    </div>
  );
}
