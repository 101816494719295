import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { AppState } from '@famirytree/crdt-storage';

import { reloadWindow } from '@/utils/window';

export enum ReleaseState {
  Updated = 'RELEASE_UPDATED',
  Maintenance = 'MAINTENANCE',
  OK = 'OK',
}

const isMaintenance = (name: string) => name[0] === '_';

export default function useAppRelease() {
  const releaseName = useSelector((state: AppState) => state.currentUser.releaseName) ?? '';

  const [releaseState, setReleaseState] = useState<string>(ReleaseState.OK);
  const [prevReleaseName, setPrevReleaseName] = useState(releaseName);

  useEffect(() => {
    if (releaseName !== prevReleaseName) {
      setPrevReleaseName(releaseName);

      if (!prevReleaseName) {
        setReleaseState(prevState =>
          isMaintenance(releaseName) ? ReleaseState.Maintenance : prevState,
        );
      } else {
        if (isMaintenance(releaseName)) {
          setReleaseState(ReleaseState.Maintenance);
        } else {
          if (releaseState !== ReleaseState.Maintenance) {
            setReleaseState(ReleaseState.Updated);
          } else {
            reloadWindow();
          }
        }
      }
    }
  }, [releaseName, prevReleaseName, releaseState]);

  return {
    releaseName,
    releaseState,
    prevReleaseName,
  };
}
