export function isNumber(arg: unknown): arg is number {
  return typeof arg === 'number' && !isNaN(arg);
}

export function isString(arg: unknown): arg is string {
  return typeof arg === 'string';
}

export function isObject(arg: unknown): arg is object {
  return typeof arg === 'object' && !Array.isArray(arg) && arg !== null;
}

export function isFunction(arg: unknown): arg is Function {
  return typeof arg === 'function';
}
