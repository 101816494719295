import { useCallback } from 'react';

import classnames from 'classnames';

import { Button, Card, Text } from '@/fui';
import type { AliasData } from '@/lib/map';

export default function SearchEmptyResult({
    query = '',
    error,
    onCreateAlias,
    onSelectAliasWithMarker
}: {
    query?: string;
    error?: any;
    onCreateAlias: (data: AliasData) => void;
    onSelectAliasWithMarker: (data: AliasData) => void;
}) {
    const handleCreateAlias = useCallback(() => {
        onCreateAlias({
            name: query
        });
    }, [query, onCreateAlias]);

    const handleChoosePlaceWithMarker = useCallback(() => {
        onSelectAliasWithMarker({
            name: query
        });
    }, [query, onSelectAliasWithMarker]);

    const classNames = classnames('SearchEmptyResult', {
        'SearchEmptyResult--error': error
    });

    return (
        <div className={classNames}>
            {error ? (
                <Card>
                    <Text
                        weight="bold"
                        paragraph
                    >
                        В данный момент мы не можем выполнить поиск по вашему
                        запросу.
                    </Text>

                    <Text>
                        Попробуйте позже или{' '}
                        <Button
                            variant="text"
                            onClick={handleChoosePlaceWithMarker}
                        >
                            добавьте объект{error ? '' : ' на карте'}
                        </Button>
                        .
                    </Text>
                </Card>
            ) : (
                <Card>
                    <Text
                        weight="bold"
                        paragraph
                    >
                        Упс, на карте ничего не найдено.
                    </Text>

                    <Text>
                        Попробуйте изменить запрос или{' '}
                        <Button
                            variant="text"
                            onClick={handleChoosePlaceWithMarker}
                        >
                            добавьте объект на карте
                        </Button>
                        .
                    </Text>
                </Card>
            )}

            <Button
                className="SearchEmptyResult__select-place-button"
                variant="filled"
                content="Выбрать местоположение"
                end={
                    <Text
                        type="p4"
                        weight="normal"
                        inline
                    >
                        {query}
                    </Text>
                }
                onClick={handleCreateAlias}
            />

            <Button
                className="SearchEmptyResult__set-marker-button"
                variant="tinted"
                onClick={handleChoosePlaceWithMarker}
            >
                Добавить объект{error ? '' : ' на карте'}
            </Button>
        </div>
    );
}
