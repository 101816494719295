import {
  GenderType,
  PersonAvatar,
  PersonBasicInfo,
  PersonRoleType,
  PersonStatusType,
  UUID,
} from '@famirytree/treelib/interfaces';

export type PersonData = Partial<
  PersonBasicInfo & {
    id?: UUID;
    gender?: GenderType;
    avatar?: PersonAvatar;
    role?: PersonRoleType;
  }
>;

export type PersonGenderValue = 'female' | 'male' | 'unknown';

export type PersonRoleValue = keyof typeof PersonRoleType;

export type PersonGenderName = keyof typeof GenderType;

export const genderValues: Record<GenderType, PersonGenderValue> = {
  [GenderType.Female]: 'female',
  [GenderType.Male]: 'male',
  [GenderType.Unknown]: 'unknown',
};

export const personGenderNames: Record<GenderType, PersonGenderName> = Object.entries(
  GenderType,
).reduce(
  (acc, [key, value]) => {
    acc[value as string] = key;
    return acc;
  },
  {} as Record<GenderType, PersonGenderName>,
);

export const personGenderLabels: Record<GenderType, string> = {
  [GenderType.Female]: 'Женщина',
  [GenderType.Male]: 'Мужчина',
  [GenderType.Unknown]: 'Неизвестно',
};

export type PersonRoleName = keyof typeof PersonRoleType;

export const personRoleNames: Record<PersonRoleName, PersonRoleType> = Object.entries(
  PersonRoleType,
).reduce(
  (acc, [key, value]) => {
    acc[value] = key;
    return acc;
  },
  {} as Record<PersonRoleName, PersonRoleType>,
);

const { Female: F, Male: M, Unknown: U } = GenderType;

export const personStatusLabelsByGender: Record<PersonStatusType, Record<GenderType, string>> = {
  [PersonStatusType.Alive]: {
    [F]: 'Жива',
    [M]: 'Жив',
    [U]: 'Жив(а)',
  },
  [PersonStatusType.Dead]: {
    [F]: 'Умерла',
    [M]: 'Умер',
    [U]: 'Умер(ла)',
  },
  [PersonStatusType.Unknown]: {
    [F]: 'Неизвестно',
    [M]: 'Неизвестно',
    [U]: 'Неизвестно',
  },
};

// @ts-ignore - неполный перечень ролей
// TODO - нужно отдельно думать, какое количество ролей должно присутствовать в этом списке
export const personRoleLabelsByGender: Record<PersonRoleType, Record<GenderType, string>> = {
  [PersonRoleType.Acquaintance]: {
    [F]: 'Знакомая',
    [M]: 'Знакомый',
    [U]: 'Знакомый',
  },
  [PersonRoleType.Assistant]: {
    [F]: 'Помощница',
    [M]: 'Помощник',
    [U]: 'Помощник',
  },
  [PersonRoleType.Aunt]: {
    [F]: 'Тетя',
    [M]: '',
    [U]: 'Тетя',
  },
  [PersonRoleType.Baby]: {
    [F]: 'Младенец',
    [M]: 'Младенец',
    [U]: 'Младенец',
  },
  [PersonRoleType.Boss]: {
    [F]: 'Начальница',
    [M]: 'Начальник',
    [U]: 'Начальник',
  },
  [PersonRoleType.Brother]: {
    [F]: '',
    [M]: 'Брат',
    [U]: 'Брат',
  },
  [PersonRoleType.BusinessPartner]: {
    [F]: 'Бизнес-партнер',
    [M]: 'Бизнес-партнер',
    [U]: 'Бизнес-партнер',
  },
  [PersonRoleType.Chief]: {
    [F]: 'Руководительница',
    [M]: 'Руководитель',
    [U]: 'Руководитель',
  },
  [PersonRoleType.Child]: {
    [F]: 'Ребенок',
    [M]: 'Ребенок',
    [U]: 'Ребенок',
  },
  [PersonRoleType.Classmate]: {
    [F]: 'Одноклассница',
    [M]: 'Одноклассник',
    [U]: 'Одноклассник',
  },
  [PersonRoleType.Commander]: {
    [F]: 'Командир',
    [M]: 'Командир',
    [U]: 'Командир',
  },
  [PersonRoleType.Coach]: {
    [F]: 'Тренер',
    [M]: 'Тренер',
    [U]: 'Тренер',
  },
  [PersonRoleType.Coauthor]: {
    [F]: 'Соавтор',
    [M]: 'Соавтор',
    [U]: 'Соавтор',
  },
  [PersonRoleType.Colleague]: {
    [F]: 'Коллега',
    [M]: 'Коллега',
    [U]: 'Коллега',
  },
  [PersonRoleType.Comrade]: {
    [F]: 'Товарищ',
    [M]: 'Товарищ',
    [U]: 'Товарищ',
  },
  [PersonRoleType.Coworker]: {
    [F]: 'Сослуживица',
    [M]: 'Сослуживец',
    [U]: 'Сослуживец',
  },
  [PersonRoleType.Daughter]: {
    [F]: 'Дочь',
    [M]: '',
    [U]: 'Дочь',
  },
  [PersonRoleType.Doctor]: {
    [F]: 'Доктор',
    [M]: 'Доктор',
    [U]: 'Доктор',
  },
  [PersonRoleType.ExHusband]: {
    [F]: '',
    [M]: 'Бывший муж',
    [U]: 'Бывший муж',
  },
  [PersonRoleType.ExSpouse]: {
    [F]: 'Бывший супруга',
    [M]: 'Бывший супруг',
    [U]: 'Бывший супруг',
  },
  [PersonRoleType.ExWife]: {
    [F]: 'Бывшая жена',
    [M]: '',
    [U]: 'Бывшая жена',
  },
  [PersonRoleType.Father]: {
    [F]: '',
    [M]: 'Отец',
    [U]: 'Отец',
  },
  [PersonRoleType.Fellow]: {
    [F]: 'Товарищ',
    [M]: 'Товарищ',
    [U]: 'Товарищ',
  },
  [PersonRoleType.FellowSoldier]: {
    [F]: 'Сослуживица',
    [M]: 'Сослуживец',
    [U]: 'Сослуживец',
  },
  [PersonRoleType.FellowStudent]: {
    [F]: 'Сокурсница',
    [M]: 'Сокурсник',
    [U]: 'Сокурсник',
  },
  [PersonRoleType.Friend]: {
    [F]: 'Подруга',
    [M]: 'Друг',
    [U]: 'Друг',
  },
  [PersonRoleType.GodChild]: {
    [F]: 'Крестная дочь',
    [M]: 'Крестный сын',
    [U]: 'Крестный ребёнок',
  },
  [PersonRoleType.GodDaughter]: {
    [F]: 'Крестная дочь',
    [M]: '',
    [U]: 'Крестный дочь',
  },
  [PersonRoleType.GodFather]: {
    [F]: '',
    [M]: 'Крестный отец',
    [U]: 'Крестный отец',
  },
  [PersonRoleType.GodMother]: {
    [F]: 'Крестная мать',
    [M]: '',
    [U]: 'Крестная мать',
  },
  [PersonRoleType.GodSon]: {
    [F]: 'Крестный сын',
    [M]: '',
    [U]: 'Крестный сын',
  },
  [PersonRoleType.GodParent]: {
    [F]: 'Крестная мать',
    [M]: 'Крестный отец',
    [U]: 'Крестный родитель',
  },
  [PersonRoleType.GrandChild]: {
    [F]: 'Внучка',
    [M]: 'Внук',
    [U]: 'Внук или внучка',
  },
  [PersonRoleType.GrandDaughter]: {
    [F]: 'Внучка',
    [M]: '',
    [U]: 'Внучка',
  },
  [PersonRoleType.GrandFather]: {
    [F]: '',
    [M]: 'Дедушка',
    [U]: 'Дедушка',
  },
  [PersonRoleType.GrandMother]: {
    [F]: 'Бабушка',
    [M]: '',
    [U]: 'Бабушка',
  },
  [PersonRoleType.GrandParent]: {
    [F]: 'Бабушка',
    [M]: 'Дедушка',
    [U]: 'Прародитель',
  },
  [PersonRoleType.GrandSon]: {
    [F]: '',
    [M]: 'Внук',
    [U]: 'Внук',
  },
  [PersonRoleType.Husband]: {
    [F]: '',
    [M]: 'Муж',
    [U]: 'Муж',
  },
  [PersonRoleType.Kid]: {
    [F]: 'Ребенок',
    [M]: 'Ребенок',
    [U]: 'Ребенок',
  },
  [PersonRoleType.Nanny]: {
    [F]: 'Няня',
    [M]: 'Няня',
    [U]: 'Няня',
  },
  [PersonRoleType.Neighbor]: {
    [F]: 'Соседка',
    [M]: 'Сосед',
    [U]: 'Сосед',
  },
  [PersonRoleType.Nephew]: {
    [F]: '',
    [M]: 'Племянник',
    [U]: 'Племянник',
  },
  [PersonRoleType.Niece]: {
    [F]: 'Племянница',
    [M]: '',
    [U]: 'Племянница',
  },
  [PersonRoleType.Master]: {
    [F]: 'Хозяйка',
    [M]: 'Хозяин',
    [U]: 'Хозяин',
  },
  [PersonRoleType.Mentor]: {
    [F]: 'Наставница',
    [M]: 'Наставник',
    [U]: 'Наставник',
  },
  [PersonRoleType.Mother]: {
    [F]: 'Мать',
    [M]: '',
    [U]: '',
  },
  [PersonRoleType.Parent]: {
    [F]: 'Родитель',
    [M]: 'Родитель',
    [U]: 'Родитель',
  },
  [PersonRoleType.Partner]: {
    [F]: 'Партнер',
    [M]: 'Партнер',
    [U]: 'Партнер',
  },
  [PersonRoleType.Patient]: {
    [F]: 'Пациентка',
    [M]: 'Пациент',
    [U]: 'Пациент',
  },
  [PersonRoleType.Person]: {
    [F]: 'Человек',
    [M]: 'Человек',
    [U]: 'Человек',
  },
  [PersonRoleType.Pet]: {
    [F]: 'Питомец',
    [M]: 'Питомец',
    [U]: 'Питомец',
  },
  [PersonRoleType.Pupil]: {
    [F]: 'Ученица',
    [M]: 'Ученик',
    [U]: 'Ученик',
  },
  [PersonRoleType.Relative]: {
    [F]: 'Родственница',
    [M]: 'Родственник',
    [U]: 'Родственник',
  },
  [PersonRoleType.Sibling]: {
    [F]: 'Сестра',
    [M]: 'Брат',
    [U]: 'Брат или сестра',
  },
  [PersonRoleType.Sister]: {
    [F]: 'Сестра',
    [M]: '',
    [U]: 'Сестра',
  },
  [PersonRoleType.Soldier]: {
    [F]: 'Солдат',
    [M]: 'Солдат',
    [U]: 'Солдат',
  },
  [PersonRoleType.Son]: {
    [F]: '',
    [M]: 'Сын',
    [U]: '',
  },
  [PersonRoleType.Spouse]: {
    [F]: 'Супруга',
    [M]: 'Супруг',
    [U]: 'Супруг',
  },
  [PersonRoleType.StepBrother]: {
    [F]: '',
    [M]: 'Приемный брат',
    [U]: 'Приемный брат',
  },
  [PersonRoleType.StepChild]: {
    [F]: 'Приемный ребенок',
    [M]: 'Приемный ребенок',
    [U]: 'Приемный ребенок',
  },
  [PersonRoleType.StepDaughter]: {
    [F]: 'Приемная дочь',
    [M]: '',
    [U]: '',
  },
  [PersonRoleType.StepFather]: {
    [F]: '',
    [M]: 'Отчим',
    [U]: '',
  },
  [PersonRoleType.StepMother]: {
    [F]: 'Мачеха',
    [M]: '',
    [U]: '',
  },
  [PersonRoleType.StepParent]: {
    [F]: 'Приемный родитель',
    [M]: 'Приемный родитель',
    [U]: 'Приемный родитель',
  },
  [PersonRoleType.StepSibling]: {
    [F]: 'Приемная сестра',
    [M]: 'Приемный брат',
    [U]: 'Приемный брат или сестра',
  },
  [PersonRoleType.StepSister]: {
    [F]: 'Приемная сестра',
    [M]: '',
    [U]: 'Приемная сестра',
  },
  [PersonRoleType.StepSon]: {
    [F]: '',
    [M]: 'Приемный сын',
    [U]: '',
  },
  [PersonRoleType.Student]: {
    [F]: 'Студентка',
    [M]: 'Студент',
    [U]: 'Студент',
  },
  [PersonRoleType.Subordinate]: {
    [F]: 'Подчиненная',
    [M]: 'Подчиненный',
    [U]: 'Подчиненный',
  },
  [PersonRoleType.Teacher]: {
    [F]: 'Учительница',
    [M]: 'Учитель',
    [U]: 'Учитель',
  },
  [PersonRoleType.ThisPerson]: {
    [F]: 'Эта персона',
    [M]: 'Эта персона',
    [U]: 'Эта персона',
  },
  [PersonRoleType.Uncle]: {
    [F]: '',
    [M]: 'Дядя',
    [U]: 'Дядя',
  },
  [PersonRoleType.Unknown]: {
    [F]: 'Неизвестно',
    [M]: 'Неизвестно',
    [U]: 'Неизвестно',
  },
  [PersonRoleType.Upbringer]: {
    [F]: 'Воспитательница',
    [M]: 'Воспитатель',
    [U]: 'Воспитатель',
  },
  [PersonRoleType.Wife]: {
    [F]: 'Жена',
    [M]: '',
    [U]: 'Жена',
  },
};
