import type { UUID } from '@famirytree/treelib/interfaces';

import { hasPersonsMainPageAccess } from '@/helpers/grant';
import { useIsOwnTree, useTreeGrant } from '@/hooks/tree';

import useIsSharedPerson from './useIsSharedPerson';
import { usePersonGrant } from './usePersonGrant';

export function usePersonMainPageAccessible(treeId: UUID, personId: UUID = '') {
  const isOwnTree = useIsOwnTree(treeId);
  const isSharedPerson = useIsSharedPerson(personId);
  const treeGrant = useTreeGrant(treeId);
  const personGrant = usePersonGrant(personId);

  return (
    isOwnTree || isSharedPerson || personGrant || (treeGrant && hasPersonsMainPageAccess(treeGrant))
  );
}
