import { Buffer } from 'buffer';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { v4 } from 'uuid';

const famWindow = window as any;

// for external React usage (for famiry external components)
famWindow.React = React;
famWindow.ReactDOM = ReactDOM;
famWindow.useContext = useContext;
famWindow.useCallback = useCallback;
famWindow.useState = useState;
famWindow.useRef = useRef;
famWindow.useMemo = useMemo;
famWindow.useEffect = useEffect;
famWindow.v4 = v4;
famWindow.Buffer = Buffer;

export default {};
