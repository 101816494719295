import { useMemo } from 'react';

import type { PersonDBView as Person, UUID } from '@famirytree/treelib/interfaces';

import { useStore } from '@/hooks/store';
import { useCurrentTree } from '@/hooks/tree';
import { personsStoreSelector } from '@/selectors';

export default usePersonRelatives;

export function usePersonRelatives(personId?: UUID): [Person[]] {
  const personsStore = useStore<Record<string, Person>>(personsStoreSelector);
  const currentTree = useCurrentTree();

  const personRelatives = useMemo(() => {
    if (currentTree?.persons && personsStore && Object.keys(personsStore).length > 0) {
      return currentTree.persons
        .filter(({ resource }) => !!personsStore[resource] && resource !== personId)
        .map(({ resource }) => personsStore[resource]);
    }

    return [];
  }, [currentTree?.id, currentTree?.persons, personsStore]);

  return [personRelatives];
}

export function usePersonRelativesMap(personId?: UUID): Map<UUID, Person> {
  const [personRelatives] = usePersonRelatives(personId);

  return new Map<UUID, Person>(personRelatives.map(person => [person.id, person]));
}
