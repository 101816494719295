import { useSelector } from 'react-redux';

import { AppState } from '@famirytree/crdt-storage';
import { GenderType, PersonShortView } from '@famirytree/treelib/interfaces';

import { getCropperUrlParameters } from '@/helpers/cropper';

export default usePersonAvatarSrc;

export function usePersonAvatarSrc(
  personId: string | PersonShortView,
  size = 156,
): string | undefined {
  if (typeof personId !== 'string') {
    personId = personId.id;
  }
  const treeId = useSelector((s: AppState) => s.currentTree.treeId);
  const person = useSelector((s: AppState) => s.persons[personId as string]);

  if (!person) return '';

  if (person.avatar.resource) {
    return `/tree/v1/media/resizer/${getCropperUrlParameters(person.avatar.squareFrame, size, size)}/doc/${treeId}/${person.avatar.resource}`;
  } else {
    if (person.gender.type === GenderType.Female) {
      return `/tree/svg/person-female.svg`;
    } else {
      return `/tree/svg/person-male.svg`;
    }
  }
}
