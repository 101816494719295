import { useCallback } from 'react';

import { Input, Select } from '@famiryui/components';

import { DAYS, MONTHS } from '@/constants/dates';
import cn from '@/utils/classnames';

const dayOptions = [
  { value: '', label: '—' },
  ...DAYS.map(({ value, label }) => ({
    value: value.toString(),
    label,
  })),
];

const monthOptions = [
  { value: '', label: '—' },
  ...MONTHS.map(({ value, label }) => ({
    value: value.toString(),
    label,
  })),
];

type DateValue = {
  day?: number | string;
  month?: number | string;
  year?: number | string;
};

const defaultValue: DateValue = {
  day: '',
  month: '',
  year: '',
};

export default function DateInput({
  name,
  value = defaultValue,
  disabled,
  onChange,
}: {
  name?: string;
  value: DateValue;
  disabled?: boolean;
  onChange?: (data: {
    name?: string;
    value: DateValue;
    details: { name?: string; value?: string };
  }) => void;
}) {
  const handleChange = useCallback(
    (segment: { name?: string; value?: string }) => {
      const newValue = {
        ...value,
        [segment.name || '']: segment.value,
      };

      onChange?.({
        name,
        value: newValue,
        details: segment,
      });
    },
    [name, value, onChange],
  );

  const handleYearChange = useCallback(
    ({ value }: { value: string }) => {
      if (value.startsWith(' ')) {
        value = value.trim();
      }

      if (isNaN(parseInt(value))) {
        value = '';
      }

      handleChange({ name: 'year', value });
    },
    [handleChange],
  );

  return (
    <div className="DateInput">
      <Select
        className="DateInput__day-select"
        label="День"
        name="day"
        value={value.day?.toString() || ''}
        options={dayOptions}
        disabled={disabled}
        onChange={handleChange}
      />

      <Select
        className="DateInput__month-select"
        label="Месяц"
        name="month"
        value={value.month?.toString() || ''}
        options={monthOptions}
        disabled={disabled}
        onChange={handleChange}
      />

      <Input
        className={cn('DateInput__year-input', {
          'DateInput__year-input--empty': !value.year || value.year === ' ',
        })}
        label="Год"
        name="year"
        value={value.year?.toString() || ' '}
        autoComplete="off"
        disabled={disabled}
        onChange={handleYearChange}
      />
    </div>
  );
}
