import type { FactDBView as Fact, UUID } from '@famirytree/treelib/interfaces';

import { usePersonsMap } from '@/hooks/persons';
import { useEnrichPersonsSimplified } from '@/hooks/useEnrichPersonsSimplified';

export default useFactRelatedPersons;

export function useFactRelatedPersons(fact: Fact, personId: UUID) {
  const personsMap = usePersonsMap();

  const relatedPersons = fact.relatedPersons
    ?.filter(({ resource }) => resource !== personId)
    .map(({ resource }) => personsMap[resource])
    .filter(Boolean);

  const enrichedRelatedPersons = useEnrichPersonsSimplified(relatedPersons);

  return enrichedRelatedPersons;
}
