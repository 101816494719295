import { UserDBView as User, UUID } from '@famirytree/treelib/interfaces';

import { useStore } from '@/hooks/store';
import { currentUserIdSelector, currentUserSelector, currentUserStoreSelector } from '@/selectors';

export function useUser(): User {
  return useStore(currentUserSelector) as User;
}

export function useUserId(): UUID {
  return useStore(currentUserIdSelector);
}

export function useUserStatus() {
  const currentUser = useStore(currentUserStoreSelector);

  return currentUser.status;
}

export function useUserAuthenticated() {
  const currentUser = useStore(currentUserStoreSelector);

  return !!currentUser.user?.passportUser;
}

export function usePassportUser() {
  const user = useUser();

  return user.passportUser;
}

export function useUserTreeCount() {
  const user = useUser();
  const projects = user?.projects || [];

  return projects.length;
}

export { useUser as default };
