export enum EntryPage {
  TreeFront,
  TreeRenderer,
  PersonsBase,
  TreesBase,
  SmallPersonCard,
  BigPersonCard,
  RelationshipEditor,
  Other,
}

export enum EntryElement {
  // TreeFront
  TreeFront_Advices = 'TreeFront_Advices',
  TreeFront_TreeBase = 'TreeFront_TreeBase',
  TreeFront_SubscriptionStatus = 'TreeFront_SubscriptionStatus',
  TreeFront_ReferralButton = 'TreeFront_ReferralButton',
  TreeFront_RelationshipsTreeModeSelector = 'TreeFront_RelationshipsTreeModeSelector',
  TreeFront_PersonsBase = 'TreeFront_PersonsBase',
  TreeFront_TreePlaces = 'TreeFront_TreePlaces',
  TreeFront_TreePersonsSearchAndNavigation = 'TreeFront_TreePersonsSearchAndNavigation',
  TreeFront_ExportSection = 'TreeFront_ExportSection',
  TreeFront_Export_PDF = 'TreeFront_Export_PDF',
  TreeFront_Export_TreePrintOrder = 'TreeFront_Export_TreePrintOrder',
  TreeFront_Export_GEDCOM = 'TreeFront_Export_GEDCOM',
  TreeFront_Export_CloudBackup = 'TreeFront_Export_CloudBackup',
  TreeFront_SharingSection = 'TreeFront_SharingSection',
  TreeFront_Account = 'TreeFront_Account',
  TreeFront_AccessNotificationHead = 'TreeFront_AccessNotificationHead',
  TreeFront_Help = 'TreeFront_Help',
  TreeFront_Chat = 'TreeFront_Chat',
  TreeFront_TreeName = 'TreeFront_TreeName',
  TreeFront_PersonAddView = 'TreeFront_PersonAddView',
  TreeFront_AudioMemo = 'TreeFront_AudioMemo',
  // TreeRenderer
  TreeRenderer_ToBigPersonCard = 'TreeRenderer_ToBigPersonCard',
  TreeRenderer_AddPerson = 'TreeRenderer_AddPerson',
  TreeRenderer_ToSmallPersonCard = 'TreeRenderer_ToSmallPersonCard',
  TreeRenderer_PersonProgress = 'TreeRenderer_PersonProgress',
  // -- TreeRenderer_MenuSection,
  TreeRenderer_Menu_InviteToTree = 'TreeRenderer_Menu_InviteToTree',
  TreeRenderer_Menu_DeletePerson = 'TreeRenderer_Menu_DeletePerson',
  TreeRenderer_Menu_SetAsMainPerson = 'TreeRenderer_Menu_SetAsMainPerson',
  TreeRenderer_Menu_RearrangeFromThisPerson = 'TreeRenderer_Menu_RearrangeFromThisPerson',
  TreeRenderer_Menu_EditRelationships = 'TreeRenderer_Menu_EditRelationships',
  // PersonsBase
  PersonsBase_AddPerson = 'PersonsBase_AddPerson',
  PersonsBase_TreePersonsSearch = 'PersonsBase_TreePersonsSearch',
  PersonsBase_ShowPersonOnTree = 'PersonsBase_ShowPersonOnTree',
  PersonsBase_RearrangeFromThisPerson = 'PersonsBase_RearrangeFromThisPerson',
  PersonsBase_DeletePerson = 'PersonsBase_DeletePerson',
  PersonsBase_ToBigPersonCard = 'PersonsBase_ToBigPersonCard',
  PersonsBase_EditRelationships = 'PersonsBase_EditRelationships',
  // TreesBase
  TreesBase_CreateTree = 'TreesBase_CreateTree',
  TreesBase_ImportTreeFromGedcom = 'TreesBase_ImportTreeFromGedcom',
  TreesBase_DeleteTree = 'TreesBase_DeleteTree',
  // SmallPersonCard
  SmallPersonCard_UploadAvatar = 'SmallPersonCard_UploadAvatar',
  SmallPersonCard_InputForm = 'SmallPersonCard_InputForm',
  SmallPersonCard_InvitationEmailData = 'SmallPersonCard_InvitationEmailData',
  SmallPersonCard_InviteToTreeCheckbox = 'SmallPersonCard_InviteToTreeCheckbox',
  SmallPersonCard_ExpandDatesAndPlaces = 'SmallPersonCard_ExpandDatesAndPlaces',
  SmallPersonCard_ToBigCard = 'SmallPersonCard_ToBigCard',
  SmallPersonCard_SaveButton = 'SmallPersonCard_SaveButton',
  SmallPersonCard_CancelButton = 'SmallPersonCard_CancelButton',
  SmallPersonCard_PersonProgress = 'SmallPersonCard_PersonProgress',
  SmallPersonCard_AnnotationHead = 'SmallPersonCard_AnnotationHead',
  SmallPersonCard_AnnotationInput = 'SmallPersonCard_AnnotationInput',
  // BigPersonCard
  BigPersonCard_SharePerson = 'BigPersonCard_SharePerson',
  BigPersonCard_UploadAvatar = 'BigPersonCard_UploadAvatar',
  BigPersonCard_InviteToTreeCheckbox = 'BigPersonCard_InviteToTreeCheckbox',
  BigPersonCard_MainInfoEditTools = 'BigPersonCard_MainInfoEditTools',
  BigPersonCard_AddFacts = 'BigPersonCard_AddFacts',
  BigPersonCard_MainInfoTab = 'BigPersonCard_MainInfoTab',
  BigPersonCard_FactsTab = 'BigPersonCard_FactsTab',
  BigPersonCard_InvitationEmailData = 'BigPersonCard_InvitationEmailData',
  BigPersonCard_InputForm = 'BigPersonCard_InputForm',
  BigPersonCard_FactsEditForm = 'BigPersonCard_FactsEditForm',
  BigPersonCard_PlaceInput = 'BigPersonCard_PlaceInput',
  BigPersonCard_Map = 'BigPersonCard_Map',
  BigPersonCard_ExpandDatesAndPlaces = 'BigPersonCard_ExpandDatesAndPlaces',
  BigPersonCard_FulfillTreeDataForMe = 'BigPersonCard_FulfillTreeDataForMe',
  BigPersonCard_PersonProgress = 'BigPersonCard_PersonProgress',
  BigPersonCard_AnnotationHead = 'BigPersonCard_AnnotationHead',
  BigPersonCard_AnnotationInput = 'BigPersonCard_AnnotationInput',
  // RelationshipEditor
  RelationshipEditor_DeleteRelationship = 'RelationshipEditor_DeleteRelationship',
  RelationshipEditor_DeletePerson = 'RelationshipEditor_DeletePerson',
  RelationshipEditor_RearrangeTreeFromPerson = 'RelationshipEditor_RearrangeTreeFromPerson',
  RelationshipEditor_AddPerson = 'RelationshipEditor_AddPerson',
  // TreeConfiguration
  TreeConfig_PersonProgress = 'TreeConfig_PersonProgress',
  // Other
}

export enum EntryAccessMode {
  Owner = 'o',
  Unknown = 'u',
  PublicPerson_Guest = 'p_pg',
  Public_Guest = 'pg',
  Public_Authorized = 'pa',
  Collaborative_View = 'cv',
  Collaborative_Edit = 'ce',
  CollaborativeCard_View = 'p_cv',
  CollaborativeCard_Edit = 'c_ce',
}

export enum EntryAccessState {
  Available = 'Available',
  Readonly = 'Readonly',
  Hidden = 'Hidden',
  Innactive = 'Innactive',
  NotAvailable = 'NotAvailable',
  ToAuthorization = 'ToAuthorization',
}

export type AccessStateStatePerMode = {
  [key in EntryAccessMode]: EntryAccessState;
};

export type AccessStateEntryState = Record<
  EntryElement,
  {
    [key in EntryAccessMode]: EntryAccessState;
  }
>;
