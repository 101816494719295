import { useMemo } from 'react';

import { PersonFamiry } from '@famirytree/treelib';
import type { UUID } from '@famirytree/treelib/interfaces';

import { useCurrentTree } from '@/hooks/tree';
import { useArrangerReady } from '@/hooks/useArrangerReady';
import useRenderer from '@/hooks/useRenderer';

export default usePersonsOnTree;

export function usePersonsOnTree(): Record<UUID, boolean> {
  const { arranger } = useRenderer();
  const { isArrangerPreiterated } = useArrangerReady();

  const tree = useCurrentTree();

  return useMemo(() => {
    const data = {};

    if (isArrangerPreiterated && arranger?.preProcessedPersons) {
      tree.persons.forEach(({ resource }) => {
        data[resource] = false;
      });

      arranger.preProcessedPersons.forEach((p: PersonFamiry) => {
        data[p.id] = true;
      });
    }

    return data;
  }, [isArrangerPreiterated, arranger?.preProcessedPersons, tree?.persons]);
}
