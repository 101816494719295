import type { Alias, Marker, Place } from '@/lib/map';

export type Action<D = any> = {
    type: string;
    payload: D;
};

// Aliases

export function setAliases(aliases: Alias[]) {
    return {
        type: 'SET_ALIASES',
        payload: aliases
    };
}

export function unsetAliases() {
    return {
        type: 'UNSET_ALIASES'
    };
}

export function setAlias(alias: Alias) {
    return {
        type: 'SET_ALIAS',
        payload: alias
    };
}

export function unsetAlias() {
    return {
        type: 'UNSET_ALIAS'
    };
}

// Error

export function setError(error: any) {
    return {
        type: 'SET_ERROR',
        payload: error
    };
}

export function unsetError() {
    return {
        type: 'UNSET_ERROR'
    };
}

// Markers

export function setMarkers(markers: Marker[]) {
    return {
        type: 'SET_MARKERS',
        payload: markers
    };
}

export function unsetMarkers() {
    return {
        type: 'UNSET_MARKERS'
    };
}

export function setMarker(object: Marker) {
    return {
        type: 'SET_MARKER',
        payload: object
    };
}

export function unsetMarker() {
    return {
        type: 'UNSET_MARKER'
    };
}

// Places

export function setPlaces(places: Place[]) {
    return {
        type: 'SET_PLACES',
        payload: places
    };
}

export function unsetPlaces() {
    return {
        type: 'UNSET_PLACES'
    };
}

export function setPlace(place: Place) {
    return {
        type: 'SET_PLACE',
        payload: place
    };
}

export function unsetPlace() {
    return {
        type: 'UNSET_PLACE'
    };
}

// Reset

export function reset() {
    return {
        type: 'RESET'
    };
}
