import { HTMLAttributes, useEffect, useRef, useState } from 'react';

import { GenderType } from '@famirytree/treelib/interfaces';
import { Spinner } from '@famiryui/components';

import { ReactComponent as WarningIcon } from '@/images/icons/warning.svg';
import { cn } from '@/utils';

import './Avatar.css';

export type AvatarProps = HTMLAttributes<HTMLElement> & {
  className?: string;
  src?: string;
  border?: boolean;
  circle?: boolean;
  gender?: GenderType;
  letter?: string;
  shadow?: boolean;
  isLoading?: boolean;
  hasError?: boolean;
};

export const Avatar = ({
  className,
  src,
  border = true,
  circle,
  gender = GenderType.Unknown,
  letter,
  shadow,
  isLoading,
  hasError,
  ...rest
}: AvatarProps) => {
  const imageRef = useRef<HTMLImageElement>(null);

  const [loaded, setLoaded] = useState(false);
  const [loadingError, setLoadingError] = useState(false);

  useEffect(() => {
    setLoaded(false);
  }, [src]);

  useEffect(() => {
    if (loaded) return;

    setLoadingError(false);
    setLoaded(false);

    if (imageRef.current && !loaded) {
      const image = imageRef.current;

      if (image.complete && image.naturalHeight !== 0) {
        setLoaded(true);
      } else {
        image.onload = () => {
          setLoaded(true);
        };

        image.onerror = () => {
          setLoaded(false);
          setLoadingError(true);
        };

        return () => {
          image.onload = () => {};
          image.onerror = () => {};
        };
      }
    }
  }, [src, loaded]);

  return (
    <div
      className={cn('avatar', className, {
        avatar_female: gender === GenderType.Female,
        avatar_male: gender === GenderType.Male,
        avatar_circle: circle,
        avatar_letter: letter !== undefined,
        avatar_border: border,
        avatar_shadow: shadow,
        avatar_loaded: loaded,
        avatar_loading: isLoading,
        avatar_error: hasError,
      })}
      {...rest}
    >
      {letter?.charAt(0)}

      {src && !loadingError && !isLoading && !hasError && (
        <img
          className="avatar__image"
          src={src}
          ref={imageRef}
          alt=""
        />
      )}

      {isLoading && <Spinner />}

      {hasError && <WarningIcon />}
    </div>
  );
};
