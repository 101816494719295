import { useMemo } from 'react';

import { AppState } from '@famirytree/crdt-storage';

import { usePersons } from '@/hooks/persons';
import { useStore } from '@/hooks/store';

export type Facts = AppState['facts'];

export function useFacts(): Facts {
  const facts = useStore(state => state.facts);
  const persons = usePersons();

  return useMemo(() => {
    return persons
      .flatMap(person => person.facts)
      .map(({ resource }) => facts[resource])
      .filter(Boolean)
      .reduce((facts, fact) => {
        facts[fact.id] = fact;
        return facts;
      }, {} as Facts);
  }, [persons, facts]);
}
