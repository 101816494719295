import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import AuthorizationModal from '@/components/AuthorizationModal';
import ErrorState from '@/components/common/ErrorState';
import { ACTIONS_AFTER_AUTHORIZATION } from '@/constants/actionsAfterAuthorization';
import routes from '@/constants/routes';
import { EntryAccessMode } from '@/hooks/access';
import { usePerson } from '@/hooks/person';
import { useStore } from '@/hooks/store';
import { useCurrentTreeId, useIsSharedTree, useTree } from '@/hooks/tree';
import useAppRelease, { ReleaseState } from '@/hooks/useAppRelease';
import useErrors from '@/hooks/useErrors';
import { useUserAuthenticated, useUserMode, useUserStatus } from '@/hooks/user';
import { reloadWindow } from '@/utils/window';

import {
  getPersonErrorMessage,
  getRedirectPayload,
  getTreeErrorMessage,
  isAuthError,
} from './helpers';

export default function ServerError({ children }: { children: ReactElement }) {
  const navigate = useNavigate();
  const params = useParams();

  const userAuthenticated = useUserAuthenticated();
  const userMode = useUserMode();
  const userStatus = useUserStatus();
  const currentTreeId = useCurrentTreeId();
  const currentTreeState = useStore(s => s.currentTree);
  const isSharedTree = useIsSharedTree(currentTreeId);
  const { releaseState } = useAppRelease();
  const { isError, treeError, personError } = useErrors();

  const treeId = treeError?.data?.id ?? params.treeId ?? currentTreeState.treeId;
  const personId = personError?.data?.id ?? params.personId ?? currentTreeState.personId;
  const erroredTree = useTree(treeId);
  const erroredPerson = usePerson(personId);

  const [message, setMessage] = useState('');
  const [isAuthModalOpen, setAuthModalOpen] = useState(false);

  const isDataLoaded = erroredTree?.id || erroredPerson?.id;
  const isSharedPerson = userMode === EntryAccessMode.PublicPerson_Guest;

  useEffect(() => {
    if (personError) {
      setMessage(getPersonErrorMessage(personError));
    } else if (treeError && !isSharedPerson) {
      setMessage(getTreeErrorMessage(treeError));
    }

    return () => {
      setMessage('');
    };
  }, [personError, treeError, isSharedPerson]);

  useEffect(() => {
    if (
      !isAuthError(treeError || personError) ||
      userAuthenticated ||
      isSharedTree ||
      isSharedPerson
    )
      return;

    setAuthModalOpen(true);

    return () => {
      setAuthModalOpen(false);
    };
  }, [treeError, personError, userAuthenticated, isSharedTree, isSharedPerson]);

  const handleResetError = useCallback(() => {
    window.location.assign(routes.BASE_TREES);
    // navigate(routes.ROOT_TREE, { replace: true });
  }, [navigate]);

  return (
    <>
      {message && isError && !isDataLoaded && (
        <ErrorState
          icon
          title={`Ошибка: ${personError?.code ?? treeError?.code}`}
          subtitle={message}
          buttonContent="Перейти к моим древам"
          onButtonClick={handleResetError}
        />
      )}

      {userStatus === 'STALE' && (
        <ErrorState
          title="Аккаунт изменился в другой вкладке"
          subtitle="Вы вышли из аккаунта или вошли в другой аккаунт в соседней вкладке браузера. Мы завершили вашу сессию в этой вкладке чтобы предотвратить потерю данных или работу с неактуальными данными."
          description="Чтобы продолжить работу, перезагрузите страницу."
          buttonContent="Перезагрузить страницу"
          onButtonClick={reloadWindow}
        />
      )}

      {releaseState === ReleaseState.Updated && (
        <ErrorState
          title="Версия приложения обновилась"
          subtitle="Чтобы продолжить работу с древом, перезагрузите страницу."
          buttonContent="Перезагрузить страницу"
          onButtonClick={reloadWindow}
        />
      )}

      {releaseState === ReleaseState.Maintenance && (
        <ErrorState
          title="Сервисное обслуживание древа"
          subtitle="В настоящее время мы проводим сервисное обслуживание древа"
          description="По завершении работ страница будет перезагружена автоматически"
          buttonContent="Перезагрузить страницу"
        />
      )}

      {releaseState === ReleaseState.OK && children}

      {isAuthModalOpen && (
        <AuthorizationModal
          action={ACTIONS_AFTER_AUTHORIZATION.REDIRECT}
          payload={getRedirectPayload(treeId, personId)}
          onClose={() => {
            setAuthModalOpen(false);
          }}
        />
      )}
    </>
  );
}
