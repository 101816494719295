export const permutations = <T>(arr: T[]): T[][] => {
  if (arr.length === 0) {
    return [];
  }
  if (arr.length === 1) {
    return [[...arr]];
  }
  const ret = [] as T[][];
  arr.forEach((el: T, i) => {
    const subwithout = [...arr];
    subwithout.splice(i, 1);
    const subperms = permutations(subwithout);
    subperms.forEach(variant => {
      ret.push([el, ...variant]);
    });
  });
  return ret;
};
