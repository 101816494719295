import { useEffect, useState } from 'react';

import { createTreeExportLink, useDispatch } from '@famirytree/crdt-storage';
import type { UUID } from '@famirytree/treelib/interfaces';

import { useStore } from '@/hooks/store';

import { useTree } from './useTree';
import { useTreeGrant } from './useTreeGrant';

export function useTreeShareToken(treeId: UUID): string | undefined {
  const tree = useTree(treeId);
  const grant = useTreeGrant(treeId);

  if (grant) return undefined;

  return (tree?.shareTokens ?? []).filter(t => typeof t === 'string')[0] as string | undefined;
}

export default (treeId: string) => {
  const dispatch = useDispatch();

  const tree = useStore(s => s.trees[treeId]);

  const [shareToken, setShareToken] = useState({
    token: ((tree?.shareTokens || [])[0] ?? null) as string | null,
    requestIsPending: false,
    requestIsComplete: false,
    error: '',
  });

  useEffect(() => {
    if (
      tree &&
      !shareToken.token &&
      !shareToken.requestIsPending &&
      !shareToken.requestIsComplete
    ) {
      dispatch(createTreeExportLink(treeId))
        .catch(() => {})
        .then(() => {
          setShareToken(s => ({
            ...s,
            requestIsPending: false,
            requestIsComplete: true,
          }));
        });
      setShareToken(s => ({
        ...s,
        requestIsPending: true,
      }));
    }
  }, [tree, shareToken, dispatch, treeId]);

  return shareToken.token;
};
