import { v4 as uuid } from 'uuid';

import { EntityMeta, store } from '@famirytree/crdt-storage';
import { calculatePersonAge, parsePersonEventDate } from '@famirytree/treelib';
import {
  DateSpecifiers,
  EntityRefs,
  FactDBView as Fact,
  Gender,
  GenderType,
  MainInfoBlock,
  MainInfoBlockType,
  MainInfoPageType,
  MainInfoTabContentType,
  MainInfoTabType,
  PersonAvatar,
  PersonBasicInfo,
  PersonDBView as Person,
  PersonDBView,
  PersonFullView,
  PersonRoleType,
  PersonShortView,
  PersonStatusType,
  UUID,
} from '@famirytree/treelib/interfaces';

import { PERSON_ROLES_NAMES_GENITIVE } from '@/constants/person';
import {
  genderValues,
  PersonData,
  personGenderLabels,
  personGenderNames,
  PersonGenderValue,
  personRoleLabelsByGender,
  PersonRoleValue,
  personStatusLabelsByGender,
} from '@/data/person';
import { getCropperUrlParameters } from '@/helpers/cropper';
import { plural } from '@/utils';

export function getPerson(personId: UUID) {
  return store.getState().persons[personId];
}

type PersonOrBasicInfo = Pick<Person, 'basicInfo'> | Partial<PersonBasicInfo>;

function getBasicInfo(arg: PersonOrBasicInfo) {
  return (arg as Person).basicInfo || (arg as Partial<PersonBasicInfo>);
}

export function getPersonFullName(arg: PersonOrBasicInfo) {
  const basicInfo = getBasicInfo(arg);

  return [
    basicInfo.lastName || basicInfo.lastNameAtBirth,
    basicInfo.firstName,
    basicInfo.middleName,
  ]
    .join(' ')
    .replace(/(^\s+)|(\s+$)/g, '');
}

export function getPersonGenderLabel(gender: GenderType = GenderType.Unknown) {
  return personGenderLabels[gender];
}

export function getPersonGenderName(type: GenderType = GenderType.Unknown) {
  return personGenderNames[type];
}

export function getPersonGenderValue(type: GenderType = GenderType.Unknown): PersonGenderValue {
  return type.split('/').at(-1)!.toLowerCase() as PersonGenderValue;
}

export function getPersonAge(arg: PersonOrBasicInfo): string {
  const basicInfo = getBasicInfo(arg);

  try {
    const {
      age: years,
      ageMonths: months,
      ageDays: days,
      accuracy,
    } = calculatePersonAge({ basicInfo } as PersonShortView);
    const prefix = accuracy !== DateSpecifiers.EXACT ? '~' : '';

    if (isNaN(years) || years < 0) return '';

    if (years === 0 && !isNaN(months)) {
      if (months === 0 && !isNaN(days)) {
        return `${prefix}${days} ${plural(['день', 'дня', 'дней'], days)}`;
      }

      return `${prefix}${months} мес`;
    }

    return `${prefix}${years} ${plural(['год', 'года', 'лет'], years)}`;
  } catch (error) {
    return '';
  }
}

export function getPersonBirthDate(arg: PersonOrBasicInfo): string {
  const basicInfo = getBasicInfo(arg);

  return parsePersonEventDate(basicInfo?.birthday);
}

export function getPersonDeathDate(arg: PersonOrBasicInfo): string {
  const basicInfo = getBasicInfo(arg);

  if (basicInfo.status !== PersonStatusType.Dead) return '';

  return parsePersonEventDate(basicInfo?.deathday);
}

export function getPersonDates(arg: PersonOrBasicInfo): [string, string] {
  return [getPersonBirthDate(arg), getPersonDeathDate(arg)];
}

export function getPersonStatusLabel(
  status: PersonStatusType,
  gender: GenderType = GenderType.Unknown,
) {
  return personStatusLabelsByGender[status][gender];
}

export function getPersonRoleLabel(
  role: PersonRoleType = PersonRoleType.Unknown,
  gender: GenderType = GenderType.Unknown,
) {
  return personRoleLabelsByGender[role][gender];
}

export function getPersonRoleValue(type: PersonRoleType = PersonRoleType.Unknown): PersonRoleValue {
  return type.split('/').at(-1)!.toLowerCase() as PersonRoleValue;
}

export function getPersonRoleGenitiveName(role: PersonRoleType) {
  const value = getPersonRoleValue(role);
  return PERSON_ROLES_NAMES_GENITIVE[value];
}

export function getPersonAvatarGender(personGender: Gender) {
  return genderValues[personGender.type];
}

export function getPersonAvatarUrl(
  personAvatar: PersonAvatar | undefined,
  treeId: UUID,
  size = 50,
) {
  if (!personAvatar) return '';

  const { resource, squareFrame } = personAvatar;

  return resource
    ? `/tree/v1/media/resizer/${getCropperUrlParameters(squareFrame, size, size)}/doc/${treeId}/${resource}`
    : undefined;
}

export function getPersonSpriteData(person: Person, treeId: UUID) {
  const { resource, squareFrame } = person.avatar;

  let avatarSrc, frameSrc;

  if (resource) {
    avatarSrc = `/tree/v1/media/resizer/${getCropperUrlParameters(squareFrame, 156, 156)}/doc/${treeId}/${resource}`;
  } else if (person.gender.type === GenderType.Female) {
    avatarSrc = `/tree/svg/person-female.svg`;
  } else {
    avatarSrc = `/tree/svg/person-male.svg`;
  }

  if (person.gender.type === GenderType.Female) {
    frameSrc = '/tree/svg/person-female-frame.svg';
  } else if (person.gender.type === GenderType.Male) {
    frameSrc = '/tree/svg/person-male-frame.svg';
  } else {
    frameSrc = '/tree/svg/person-unknown-frame.svg';
  }

  return {
    avatarSrc,
    frameSrc,
  };
}

export function getPersonFormattedData(person: Person) {
  const name = getPersonFullName(person);
  const gender = getPersonGenderLabel(person.gender.type);
  const age = getPersonAge(person);
  const status = getPersonStatusLabel(person.basicInfo.status);
  const birthDay = getPersonBirthDate(person);
  const deathDay = getPersonDeathDate(person);

  return {
    name,
    gender,
    age,
    status,
    birthDay,
    deathDay,
  };
}

export function getPersonFacts(person: Person, facts: EntityRefs<Fact & EntityMeta>): Fact[] {
  return (
    person?.facts
      ?.map(({ resource }) => facts[resource])
      .filter(Boolean)
      .filter(fact => !fact?.__meta__.empty) ?? []
  );
}

export function getPersonFillProgressData({
  person,
  personAvatar,
  personData,
}: {
  person: PersonFullView;
  personAvatar: PersonAvatar;
  personData: PersonData;
}): PersonDBView {
  return {
    ...(person ?? {}),
    id: person?.id,
    avatar: {
      resource: personAvatar?.resource ?? '',
    },
    basicInfo: {
      firstName: personData.firstName ?? '',
      lastName: personData.lastName ?? '',
      middleName: personData.middleName ?? '',
      lastNameAtBirth: personData.lastNameAtBirth ?? '',
      birthday: personData.birthday ?? '',
      deathday: personData.deathday ?? '',
      placeOfBirth: personData.placeOfBirth ?? '',
      placeOfBirthObject: personData.placeOfBirthObject ?? null,
      placeOfDeath: personData.placeOfDeath ?? '',
      placeOfDeathObject: personData.placeOfDeathObject ?? null,
      status: personData.status ?? PersonStatusType.Unknown,
      annotation: personData.annotation ?? '',
    },
    gender: {
      type: personData.gender ?? GenderType.Unknown,
    },
    blocks: {
      ...(person?.blocks ?? {}),
    },
    tabs: {
      ...(person?.tabs ?? {}),
    },
    pages: {
      ...(person?.pages ?? {}),
    },
    mainInfo: {
      ...(person?.mainInfo ?? []),
    },
    created: person?.created ?? new Date().toISOString(),
    lastEdit: person?.lastEdit ?? new Date().toISOString(),
    facts: [...(person?.facts ?? [])],
    documents: [...(person?.documents ?? [])],
    names: [...(person?.names ?? [])],
  };
}

export function assignPersonMainInfo(person: Person): Person {
  const pageId = uuid();
  const tabId = uuid();
  const blockId = uuid();
  const ts = new Date().toISOString();

  return {
    ...person,
    mainInfo: [
      {
        resource: pageId,
      },
    ],
    blocks: {
      [blockId]: {
        id: blockId,
        type: MainInfoBlockType.Paragraph,
        version: 1,
        children: [],
        created: ts,
        lastEdit: ts,
      },
    },
    tabs: {
      [tabId]: {
        id: tabId,
        type: MainInfoTabType.Main,
        name: 'Главная',
        content: [
          {
            resource: blockId,
            type: MainInfoTabContentType.Block,
          },
        ],
        created: ts,
        lastEdit: ts,
      },
    },
    pages: {
      [pageId]: {
        id: pageId,
        type: MainInfoPageType.Main,
        name: '',
        content: [
          {
            resource: tabId,
            type: MainInfoTabType.Main,
          },
        ],
        created: ts,
        lastEdit: ts,
      },
    },
  };
}

export function createContentBlock({
  type = MainInfoBlockType.Paragraph,
  children = [],
  props,
}: Partial<MainInfoBlock> = {}): MainInfoBlock {
  const id = uuid();
  const ts = new Date().toISOString();

  return {
    id,
    type,
    props,
    children,
    version: 1,
    created: ts,
    lastEdit: ts,
  };
}
