import { useCallback, useEffect, useState } from 'react';
import { NIL } from 'uuid';

import {
  createPersonInvitation,
  deletePersonInvitation,
  fetchAllGrants,
  updatePersonInvitation,
} from '@famirytree/crdt-storage';
import { AccessMode } from '@famirytree/treelib/famiry-path';
import {
  EntityTypeName,
  PersonInvitationReference,
  PersonInvitationRequest,
  TreeFullView,
  UUID,
} from '@famirytree/treelib/interfaces';

import { useDispatch, useStore } from '@/hooks/store';
import { useTree } from '@/hooks/tree';
import { REACT_APP_YA_METRICS_COUNTER_ID } from '@/vars/env';

export default usePersonInvitation;

export function usePersonInvitation({
  personId,
  treeId,
  isNewPerson = false,
  isModal = false,
  disabled = false,
}: {
  personId: UUID;
  treeId: UUID;
  isModal?: boolean;
  isNewPerson?: boolean;
  disabled?: boolean;
}) {
  const dispatch = useDispatch();
  const tree = useTree(treeId);
  const person = useStore(state => state.persons[personId]);
  const currentInvitation = useStore(state =>
    ((state.trees[treeId] as TreeFullView)?.invitations || []).find(i => i.resource === personId),
  );

  const [{ invitation, invitationDataStatus, pendingInvitationRequest }, setInvitationDataStatus] =
    useState({
      invitation: currentInvitation as PersonInvitationReference | undefined,
      pendingInvitationRequest: undefined as PersonInvitationRequest | undefined,
      invitationDataStatus: {
        email: currentInvitation?.email ?? '',
        loaded: false,
        changed: false,
        requestIsPending: false,
      },
    });

  useEffect(() => {
    dispatch(fetchAllGrants());
  }, []);

  useEffect(() => {
    if (person?.id && isNewPerson && pendingInvitationRequest) {
      sendInvitation(pendingInvitationRequest);
    }
  }, [isNewPerson, person?.id, personId, pendingInvitationRequest]);

  useEffect(() => {
    if (invitation?.email && !invitation?.grantReference?.resource) {
      updateInvitation({
        personId,
        email: invitation.email,
        grantOpts: {
          fullAccess: true,
          mode: AccessMode.ReadOnly,
          refType: EntityTypeName.u_person,
        },
      });
    }
  }, [invitation?.email]);

  const setInvitationRequestIsPending = useCallback(
    (requestIsPending: boolean) => {
      setInvitationDataStatus(s => ({
        ...s,
        invitationDataStatus: {
          ...s.invitationDataStatus,
          requestIsPending,
        },
      }));
    },
    [setInvitationDataStatus],
  );

  useEffect(() => {
    if (currentInvitation?.email !== invitationDataStatus.email) {
      setInvitationDataStatus(s => ({
        ...s,
        invitationDataStatus: {
          ...s.invitationDataStatus,
          email: currentInvitation?.email || s.invitationDataStatus.email,
        },
        invitation: currentInvitation,
      }));
    }
  }, [currentInvitation]);

  const handleInvitationDataStatusLoaded = useCallback(
    ({ invitation }: { invitation: PersonInvitationReference }) => {
      setInvitationDataStatus(s => ({
        ...s,
        invitationDataStatus: {
          ...s.invitationDataStatus,
          loaded: true,
          email: invitation.email,
          sendInvitation: invitation.sendInvitation,
          requestIsPending: false,
        },
        invitation,
      }));

      return invitation;
    },
    [disabled],
  );

  useEffect(() => {
    if (tree) {
      const currentInvitation = (tree.invitations ?? []).find(i => i.resource === personId);

      if (currentInvitation) {
        handleInvitationDataStatusLoaded({ invitation: currentInvitation });
      }
    }
  }, [tree, personId, handleInvitationDataStatusLoaded]);

  const sendInvitation = useCallback(
    async (request: PersonInvitationRequest): Promise<PersonInvitationReference | undefined> => {
      if (isNewPerson && !person) {
        setInvitationDataStatus(s => ({
          ...s,
          invitationDataStatus: {
            ...s.invitationDataStatus,
            email: request.email,
          },
          invitation: {
            email: request.email,
            invitationCode: '',
            invitationURL: '',
            invited: NIL,
            invitedBy: NIL,
            invitedUser: NIL,
            lastInvitationSent: new Date(0).toISOString(),
            resource: personId,
            sendInvitation: true,
            grantOpts: request.grantOpts,
            grantReference: { resource: NIL },
          },
          pendingInvitationRequest: request,
        }));
        return;
      }

      if (invitationDataStatus.requestIsPending) return;

      setInvitationRequestIsPending(true);

      return dispatch(
        createPersonInvitation({
          treeId,
          invitationRequest: request,
        }),
      )
        .unwrap()
        .then(handleInvitationDataStatusLoaded)
        .then(ref => {
          if (REACT_APP_YA_METRICS_COUNTER_ID) {
            if (isModal) {
              // @ts-ignore
              ym(
                REACT_APP_YA_METRICS_COUNTER_ID,
                'reachGoal',
                'invite_from_person_mini_card_on_tree',
              );
            } else {
              // @ts-ignore
              ym(REACT_APP_YA_METRICS_COUNTER_ID, 'reachGoal', 'invite_from_person_card_on_tree');
            }
            return ref;
          }
          return;
        });
    },
    [invitationDataStatus, currentInvitation, isNewPerson, person?.id, isModal],
  );

  const updateInvitation = useCallback(
    async (request: PersonInvitationRequest): Promise<PersonInvitationReference | undefined> => {
      if (invitationDataStatus.requestIsPending) return;

      setInvitationRequestIsPending(true);

      return dispatch(
        updatePersonInvitation({
          treeId,
          invitationRequest: request,
        }),
      )
        .unwrap()
        .then(handleInvitationDataStatusLoaded);
    },
    [invitationDataStatus, currentInvitation],
  );

  const deleteInvitation = useCallback(async () => {
    if (invitationDataStatus.requestIsPending) return;

    setInvitationRequestIsPending(true);

    return dispatch(
      deletePersonInvitation({
        treeId,
        personId,
      }),
    )
      .unwrap()
      .then(() => {
        setInvitationDataStatus({
          invitationDataStatus: {
            loaded: false,
            changed: false,
            email: '',
            requestIsPending: false,
          },
          invitation: undefined,
          pendingInvitationRequest: undefined,
        });
      });
  }, [invitationDataStatus, currentInvitation]);

  return {
    invitation,
    invitationRequestIsPending: invitationDataStatus.requestIsPending,
    sendInvitation,
    updateInvitation,
    deleteInvitation,
  };
}
