import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { AppState } from '@famirytree/crdt-storage';
import { ArrangerEventType, SceneEventType } from '@famirytree/renderer';
import { PersonFamiry } from '@famirytree/treelib';
import { TreeFullView } from '@famirytree/treelib/interfaces';

import useRenderer from '@/hooks/useRenderer';
import { currentTreeIdSelector } from '@/selectors';

export default usePersonDeletion;

export function usePersonDeletion() {
  const { scene, arranger } = useRenderer();

  const treeId = useSelector(currentTreeIdSelector);
  const tree = useSelector((state: AppState) => state.trees[treeId] as TreeFullView);

  const [personCanBeDeleted, setPersonCanBeDeleted] = useState<Record<string, boolean>>({});

  const recalc = useCallback(() => {
    if (arranger) {
      const newState = {} as Record<string, boolean>;
      const arrangedPersons = Array.from(arranger.preProcessedPersons) as PersonFamiry[];
      tree.persons.forEach(({ resource }) => {
        newState[resource] = true;
      });
      arrangedPersons.forEach(p => {
        newState[p.id] = arranger.canPersonBeDeleted(p.id);
      });
      setPersonCanBeDeleted(newState);
    }
  }, [tree, tree?.persons, arranger, arranger?.preProcessedPersons]);

  useEffect(() => {
    arranger?.on(ArrangerEventType.PREITERATED, recalc);
    return () => {
      arranger?.off(ArrangerEventType.PREITERATED, recalc);
    };
  }, [arranger]);

  // Arranger часто не содержит всех персон древа, т.к. некоторые персоны могут быть "отвязаны" от главной
  // т.е. находиться в отдельном несвязанном поддреве
  useEffect(recalc, [tree, tree?.persons, arranger, arranger?.preProcessedPersons]);

  const deletePerson = useCallback(
    (id: string) => {
      if (personCanBeDeleted[id]) {
        // TODO нужен рефакторинг, нужны хуки ддля манипулирования состоянием древа,
        // вместо использования god-сцены
        scene?.emit(SceneEventType.PERSON_BUTTON_DELETE, { id });
      }
    },
    [scene, personCanBeDeleted],
  );

  return {
    personCanBeDeleted,
    deletePerson,
  };
}
