import { useCallback } from 'react';

import { updateTreeRef, useDispatch } from '@famirytree/crdt-storage';
import type { TreeDBView, UUID } from '@famirytree/treelib/interfaces';

import type { TreeData } from '@/data/tree';

import { useTree } from './useTree';

export default useTreeActions;

export function useTreeActions(treeId: UUID) {
  const dispatch = useDispatch();
  const tree = useTree(treeId);

  const updateTree = useCallback(
    async (arg: TreeData | ((tree: TreeDBView) => TreeData)) => {
      if (!tree) return;

      const data = typeof arg === 'function' ? arg(tree) : arg;

      const treeRef = await dispatch(
        updateTreeRef({
          ...tree,
          ...data,
        }),
      ).unwrap();

      return treeRef.data;
    },
    [tree, dispatch],
  );

  return { updateTree };
}
