import cn from 'classnames';
import { forwardRef, InputHTMLAttributes, ReactNode, Ref } from 'react';

import './Input.css';

interface ClassNamesProps {
  wrapper: string;
  field: string;
  placeholder: string;
}

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  classNames?: Partial<ClassNamesProps>;
  icon?: ReactNode;
  isFilled?: boolean;
  withDash?: boolean;
}

export const Input = forwardRef(
  (
    { label, classNames, icon, isFilled, withDash, value, ...props }: InputProps,
    ref: Ref<HTMLInputElement>,
  ) => {
    const { onClick } = props;
    return (
      <label className={cn('custom-input', classNames?.wrapper)}>
        <input
          className={cn('custom-input__field', classNames?.field, {
            'custom-input__field_whit-icon': !!icon,
          })}
          data-filled={isFilled}
          data-value={value}
          value={value}
          ref={ref}
          {...props}
        />
        {label && (
          <span className={cn('custom-input__placeholder', classNames?.placeholder)}>{label}</span>
        )}
        {icon && (
          <span
            className="custom-input__icon"
            onClick={onClick}
          >
            {icon}
          </span>
        )}
        {withDash && <span className="custom-input__placeholder_empty" />}
      </label>
    );
  },
);

Input.displayName = 'Input';

export default Input;
