import { AppState } from '@famirytree/crdt-storage';

import { useStore } from '@/hooks/store';

export type ErrorState = AppState['errors'];

export function useErrors(): ErrorState {
  const errors = useStore(store => store.errors || {});

  return errors;
}

export { useErrors as default };
