import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { pick } from 'underscore';

import { AppState } from '@famirytree/crdt-storage';
import { parsePersonEventDate } from '@famirytree/treelib';
import {
  GenderType,
  MediaDocumentDBView,
  PersonDBView,
  UUID,
} from '@famirytree/treelib/interfaces';

import { getCropperUrlParameters } from '@/helpers/cropper';
import MaleAvatarPlaceholder from '@/images/photo/person_male.jpeg';
import FemaleAvatarPlaceholder from '@/images/photo/person_skeleton.png';
import { currentTreeIdSelector } from '@/selectors';

export const getFormattedPerson = (
  treeId: UUID,
  person: PersonDBView,
  documents: Record<string, MediaDocumentDBView>,
) => {
  const {
    firstName,
    lastName,
    middleName,
    lastNameAtBirth,
    birthday,
    deathday,
    placeOfBirth,
    // @ts-ignore
    placeOfBirthObject,
    placeOfDeath,
    // @ts-ignore
    placeOfDeathObject,
  } = person.basicInfo;
  const birthDayTS = parsePersonEventDate(birthday);
  const deathdayTS = parsePersonEventDate(deathday);
  const genderBasedAvatarPlaceholder =
    person?.gender?.type === GenderType.Female ? FemaleAvatarPlaceholder : MaleAvatarPlaceholder;

  const avatarDocument = documents[person?.avatar?.resource];
  const isLoading = avatarDocument?.importState?.complete === false;
  const hasError = avatarDocument?.importState?.error;

  const avatarUrl =
    avatarDocument && !isLoading && !hasError
      ? `/tree/v1/media/resizer/${getCropperUrlParameters(
          person.avatar.squareFrame,
          50,
          50,
        )}/doc/${treeId}/${person.avatar.resource}`
      : undefined;
  const fullName = [
    person.basicInfo.lastName || person.basicInfo.lastNameAtBirth,
    person.basicInfo.firstName,
    person.basicInfo.middleName,
  ]
    .join(' ')
    .trim();

  return {
    ...person,
    simplified__avatar: avatarUrl || genderBasedAvatarPlaceholder,
    simplified__avatarResource: avatarUrl ? person?.avatar?.resource : '',
    simplified__avatarDocument: avatarDocument,
    simplified__avatarInfo: person?.avatar,
    simplified__firstName: firstName || '',
    simplified__lastName: lastName || '',
    simplified__middleName: middleName || '',
    simplified__lastNameAtBirth: lastNameAtBirth || '',
    simplified__fullName: fullName,
    simplified__birthday: birthDayTS !== '' || placeOfBirth !== '' ? birthDayTS : '',
    // todo need to convert placeOfBirth to string since it is mixed type (string or reference)
    simplified__placeOfBirth: placeOfBirthObject?.name ?? placeOfBirth?.toString() ?? '',
    simplified__deathday: deathdayTS !== '' || placeOfDeath !== '' ? deathdayTS : '',
    // todo need to convert placeOfDeath to string since it is mixed type (string or reference)
    simplified__placeOfDeath: placeOfDeathObject?.name ?? placeOfDeath?.toString() ?? '',
    simplified__gender: person.gender?.type || '-',
  };
};

type FormattedPersonType = PersonDBView;
type EnrichSimplifiedDescriptorKeys = {
  simplified__avatar: string;
  simplified__avatarResource: string;
  simplified__firstName: string;
  simplified__lastName: string;
  simplified__middleName: string;
  simplified__lastNameAtBirth: string;
  simplified__fullName: string;
  simplified__birthday: string;
  simplified__placeOfBirth: string;
  simplified__deathday: string;
  simplified__placeOfDeath: string;
  simplified__gender: string;
};
export type EnrichSimplifiedDescriptor = FormattedPersonType & EnrichSimplifiedDescriptorKeys;

export const useEnrichPersonsSimplified = (
  persons: PersonDBView[],
): EnrichSimplifiedDescriptor[] => {
  const currentTreeId = useSelector(currentTreeIdSelector);
  const documents = useSelector((s: AppState) => pick(s.documents));

  return useMemo(() => {
    return persons.map(p => getFormattedPerson(currentTreeId, p, documents));
  }, [currentTreeId, persons, documents]);
};
