export const sortBy = (key: string) => (a: object, b: object) => {
  if (b[key] < a[key]) {
    return -1;
  }

  if (b[key] > a[key]) {
    return 1;
  }

  return 0;
};

export const personFilter = q => per =>
  Object.values(per || {})
    .join('')
    .includes(q);
