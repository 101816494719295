import { useCallback } from 'react';
import { v4 as uuid } from 'uuid';

import {
  createRelationshipRef,
  deleteRelationshipRef,
  updateRelationshipRef,
  useDispatch,
} from '@famirytree/crdt-storage';
import { SceneEventType } from '@famirytree/renderer';
import {
  PersonRoleType,
  RelationshipDBView,
  RelationshipSubType,
  RelationshipType,
  UUID,
} from '@famirytree/treelib/interfaces';

import type { RelationData, RelationshipData } from '@/data/relationship';

import useRelationships from './useRelationships';

export default function useRelationshipsActions() {
  const dispatch = useDispatch();

  const createRelationship = useCallback(
    async (data: RelationshipData) => {
      const {
        from: { personId: fromPersonId, role: fromRole = PersonRoleType.Unknown } = {},
        to: { personId: toPersonId, role: toRole = PersonRoleType.Unknown } = {},
        type = RelationshipType.Unknown,
        subType = RelationshipSubType.Unknown,
      } = data;

      if (!fromPersonId) throw new Error('`fromPersonId` is required');
      if (!toPersonId) throw new Error('`toPersonId` is required');

      const id = uuid();
      const ts = new Date().toISOString();
      const relationshipData: RelationshipDBView = {
        id,
        type,
        subtype: subType,
        person1: { resource: fromPersonId, role: fromRole },
        person2: { resource: toPersonId, role: toRole },
        facts: [],
        created: ts,
        lastEdit: ts,
      };

      const relationshipRef = await dispatch(createRelationshipRef(relationshipData)).unwrap();

      return relationshipRef.data;
    },
    [dispatch],
  );

  const createRelationshipThroughScene = useCallback(
    ({ from, to }: { from: RelationData; to: RelationData }) => {
      window.dispatchEvent(
        new CustomEvent(SceneEventType.PERSON_CREATE, {
          detail: {
            id: to.personId,
            fromId: from.personId,
            relType: to.role,
          },
        }),
      );
    },
    [],
  );

  const updateRelationship = useCallback(
    async (relationship: RelationshipDBView, data: RelationshipData) => {
      const relationshipData: RelationshipDBView = {
        ...relationship,
        ...data,
      };

      const relationshipRef = await dispatch(updateRelationshipRef(relationshipData)).unwrap();

      return relationshipRef.data;
    },
    [dispatch],
  );

  const deleteRelationship = useCallback(
    async (relationshipId: UUID) => {
      if (!relationshipId) return;

      await dispatch(deleteRelationshipRef({ id: relationshipId }));
    },
    [dispatch],
  );

  return {
    createRelationship,
    createRelationshipThroughScene,
    updateRelationship,
    deleteRelationship,
  };
}

export function useRelationshipsWithActions() {
  const relationships = useRelationships();
  const actions = useRelationshipsActions();

  return {
    relationships,
    actions,
    ...actions,
  };
}
