import { useCallback, useEffect, useMemo, useState } from 'react';

import { setTreeViewConfig, useDispatch } from '@famirytree/crdt-storage';
import {
  normalizeConfig,
  TreeViewConfig,
  TreeViewDefaultConfig,
  TreeViewProgressType,
} from '@famirytree/treelib/interfaces';

import { EntryAccessMode } from '@/hooks/access';
import { useStore } from '@/hooks/store';
import { paymentModalEventName, useFamiryProduct } from '@/hooks/usePassportOptions';
import { useUserMode } from '@/hooks/user';

export default useTreeViewConfiguration;

export function useTreeViewConfiguration() {
  const dispatch = useDispatch();
  const currentTreeConfig = useStore(s => s.currentTree.viewConfig);
  const products = useFamiryProduct();
  const options = products.getFamirytreeTreeConfigOption();
  const userMode = useUserMode();
  const [_config, _setConfig] = useState<TreeViewConfig>(TreeViewDefaultConfig);

  const config = useMemo(() => {
    const ret = normalizeConfig(_config);
    switch (userMode) {
      case EntryAccessMode.CollaborativeCard_View:
      case EntryAccessMode.Collaborative_View:
      case EntryAccessMode.Public_Authorized:
      case EntryAccessMode.Public_Guest:
      case EntryAccessMode.Unknown:
        ret.person.progress = TreeViewProgressType.None;
        break;
    }
    return ret;
  }, [_config, userMode]);

  useEffect(() => {
    if (currentTreeConfig) _setConfig(currentTreeConfig);
  }, [currentTreeConfig]);

  const setConfig = useCallback(
    (
      data: Partial<{
        person: Partial<TreeViewConfig['person']>;
        tree: Partial<TreeViewConfig['tree']>;
      }>,
    ) => {
      if (data.tree?.background && !options.custom?.background) {
        window.dispatchEvent(
          new CustomEvent(paymentModalEventName, {
            detail: {
              from: 'tree_settings',
            },
          }),
        );
        return;
      }

      _setConfig(config => ({
        person: {
          ...config.person,
          ...data.person,
        },
        tree: {
          ...config.tree,
          ...data.tree,
        },
      }));
    },
    [options],
  );

  const saveConfig = useCallback(() => {
    dispatch(setTreeViewConfig(config));
  }, [dispatch, config]);

  return {
    config,
    options,
    setConfig,
    saveConfig,
  };
}
