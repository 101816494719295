import { useCallback, useState } from 'react';

import { type FactDBView as Fact, FactType } from '@famirytree/treelib/interfaces';

import { FactTypeLabel } from '@/data/fact';

import { useFacts } from './useFacts';

export function useFactsTypes(): [
  { value: FactType; label: string }[],
  (factType: { value: FactType; label: string }) => void,
] {
  const facts = useFacts();
  const [factsTypes, setFactsTypes] = useState(getFactsTypes(facts));

  const addFactType = useCallback((factType: { value: FactType; label: string }) => {
    setFactsTypes(factTypes => factTypes.concat(factType));
  }, []);

  return [factsTypes, addFactType];
}

const builtInTypes: { value: FactType; label: string }[] = Object.entries(FactTypeLabel)
  .filter(([value]) => value !== FactType.Custom)
  .map(([value, label]) => ({ value: value as FactType, label }));

function getFactsTypes(facts: Record<string, Fact>): { value: FactType; label: string }[] {
  const customTypeValues = Object.values(facts)
    .filter(fact => fact.type === FactType.Custom)
    .map(fact => fact.value);

  const uniqueCustomTypeValues = Array.from(new Set(customTypeValues)).map(factValue => ({
    value: FactType.Custom,
    label: factValue,
  }));

  return builtInTypes.concat(uniqueCustomTypeValues);
}
