import { useCallback, useMemo, useState } from 'react';

import { FEventDate } from '@famirytree/treelib';
import {
  DateScope,
  PersonDateEvent,
  PersonDateState,
  PersonEventDatesFormat,
} from '@famirytree/treelib/interfaces';

import { createDateTemplate, dateMigrate } from '@/helpers/dates';

const DATE_INITIAL_STATE = {
  current_date_type: DateScope.simple,
  old_format: '',
};

interface PersonDateStateValues {
  changes: Partial<PersonDateState>;
  values: PersonDateState;
}

export function usePersonDates() {
  const [{ values: personDates }, setPersonDates] = useState<PersonDateStateValues>({
    changes: {},
    values: {
      birthday: {
        ...DATE_INITIAL_STATE,
        ...createDateTemplate(DATE_INITIAL_STATE, DateScope.simple),
      },
      deathday: {
        ...DATE_INITIAL_STATE,
        ...createDateTemplate(DATE_INITIAL_STATE, DateScope.simple),
      },
    },
  });

  const initPersonDates = (
    birthday: string | PersonEventDatesFormat,
    deathday?: string | PersonEventDatesFormat,
  ) => {
    if (typeof birthday === 'string') {
      const birthdayEvent = new FEventDate();
      birthdayEvent.parseObject(birthday);
      setPersonDates(prev => ({
        changes: prev.changes,
        values: {
          ...prev.values,
          birthday: {
            ...prev.values.birthday,
            old_format: birthday,
            [birthdayEvent.getScope()]: birthdayEvent.toJSON(),
          },
          ...prev.changes,
        },
      }));
    }

    if (typeof birthday === 'object') {
      setPersonDates(prev => ({
        changes: prev.changes,
        values: {
          ...prev.values,
          birthday: {
            ...prev.values.birthday,
            ...createDateTemplate(birthday, birthday.current_date_type),
            ...birthday,
          },
          ...prev.changes,
        },
      }));
    }

    if (typeof deathday === 'string') {
      const deathdayEvent = new FEventDate();
      deathdayEvent.parseObject(deathday);
      setPersonDates(prev => ({
        changes: prev.changes,
        values: {
          ...prev.values,
          deathday: {
            ...prev.values.deathday,
            old_format: deathday,
            [deathdayEvent.getScope()]: deathdayEvent.toJSON(),
          },
          ...prev.changes,
        },
      }));
    }

    if (typeof deathday === 'object') {
      setPersonDates(prev => ({
        changes: prev.changes,
        values: {
          ...prev.values,
          deathday: {
            ...prev.values.deathday,
            ...createDateTemplate(deathday, deathday.current_date_type),
            ...deathday,
          },
          ...prev.changes,
        },
      }));
    }

    if (typeof deathday === 'undefined') {
      setPersonDates(prev => ({
        changes: prev.changes,
        values: {
          ...prev.values,
          deathday: {
            ...prev.values.deathday,
            ...createDateTemplate(prev.values.deathday, prev.values.deathday.current_date_type),
          },
          ...prev.changes,
        },
      }));
    }
  };

  const resetPersonDates = useCallback(() => {
    setPersonDates(prev => ({
      changes: {},
      values: prev.values,
    }));
  }, []);

  const handleDateTypeChange = ({ value, dataset }) => {
    setPersonDates(addDateTemplate(dataset.date, value));
  };

  const handleDateValueChange = ({ value, name, dataset }) => {
    const { range, date } = dataset;
    const eventDate = new FEventDate();

    eventDate.parseObject(personDates[date][personDates[date].current_date_type]);

    // bypassing setDate value validation (may throw exceptions)
    try {
      if (name !== 'free') {
        eventDate.setDate({ [name]: value ? parseInt(String(value)) : null }, range);
      } else {
        eventDate.setDate(String(value));
      }

      setPersonDates(prev => ({
        changes: {
          ...prev.changes,
          [date]: {
            ...prev.values[date],
            [prev.values[date].current_date_type]: eventDate.toJSON(),
          },
        },
        values: {
          ...prev.values,
          [date]: {
            ...prev.values[date],
            [prev.values[date].current_date_type]: eventDate.toJSON(),
          },
        },
      }));
    } catch (e) {
      console.error(e);
    }
  };

  const handleChangeApproximate = ({ currentTarget, target }) => {
    const { value } = currentTarget;
    const { dataset } = target;
    const eventDate = new FEventDate();

    eventDate.parseObject(personDates[dataset.date][personDates[dataset.date].current_date_type]);
    eventDate.setApproximate(value, dataset.range);

    setPersonDates(prev => ({
      changes: {
        ...prev.changes,
        [dataset.date]: {
          ...(prev.changes[dataset.date] ?? {}),
          [personDates[dataset.date].current_date_type]: eventDate.toJSON(),
        },
      },
      values: {
        ...prev.values,
        [dataset.date]: {
          ...(prev.values[dataset.date] ?? {}),
          [personDates[dataset.date].current_date_type]: eventDate.toJSON(),
        },
      },
    }));
  };

  const birthdayValues = useMemo(() => {
    if (typeof personDates.birthday[personDates.birthday.current_date_type] !== 'undefined') {
      const date = new FEventDate();
      // @ts-ignore
      date.parseObject(personDates.birthday[personDates.birthday.current_date_type]);
      const formattedDate = date.getFormattedDate();

      return Array.isArray(personDates.birthday[personDates.birthday.current_date_type])
        ? {
            // @ts-ignore
            startDate: personDates.birthday[personDates.birthday.current_date_type][0],
            // @ts-ignore
            endDate: personDates.birthday[personDates.birthday.current_date_type][1],
            formattedDate,
          }
        : {
            startDate: personDates.birthday[personDates.birthday.current_date_type],
            endDate: {},
            formattedDate,
          };
    }

    return {
      startDate: {},
      endDate: {},
      formattedDate: '',
    };
  }, [personDates.birthday]);

  const deathdayValues = useMemo(() => {
    if (typeof personDates.deathday[personDates.deathday.current_date_type] !== 'undefined') {
      const date = new FEventDate();
      // @ts-ignore
      date.parseObject(personDates.deathday[personDates.deathday.current_date_type]);
      const formattedDate = date.getFormattedDate();
      return Array.isArray(personDates.deathday[personDates.deathday.current_date_type])
        ? {
            // @ts-ignore
            startDate: personDates.deathday[personDates.deathday.current_date_type][0],
            // @ts-ignore
            endDate: personDates.deathday[personDates.deathday.current_date_type][1],
            formattedDate,
          }
        : {
            startDate: personDates.deathday[personDates.deathday.current_date_type],
            endDate: {},
            formattedDate,
          };
    }

    return {
      startDate: {},
      endDate: {},
      formattedDate: '',
    };
  }, [personDates.deathday]);

  return {
    personDates,
    birthdayValues,
    deathdayValues,
    initPersonDates,
    resetPersonDates,
    handleDateTypeChange,
    handleDateValueChange,
    onChangeDateApproximate: handleChangeApproximate,
  };
}

const addDateTemplate =
  (dateType: PersonDateEvent, value: DateScope) => (prev: PersonDateStateValues) => {
    const template = createDateTemplate(prev.values[dateType], value);
    const migratedDate = dateMigrate({ ...prev.values[dateType], ...template }, value);

    return {
      changes: {
        ...prev.changes,
        [dateType]: {
          ...prev.values[dateType],
          ...migratedDate,
          current_date_type: value,
        },
      },
      values: {
        ...prev.values,
        [dateType]: {
          ...prev.values[dateType],
          ...migratedDate,
          current_date_type: value,
        },
      },
    };
  };
