import cn from 'classnames';
import { ChangeEventHandler, forwardRef } from 'react';

import './Checkbox.css';

export interface CheckboxProps {
  onChange: ChangeEventHandler;
  defaultChecked: boolean;
  disabled: boolean;
  children: any;
}

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ onChange, defaultChecked, children, disabled }, ref) => {
    return (
      <label className={cn('fam-custom-checkbox', { 'fam-custom-checkbox_disabled': disabled })}>
        <input
          disabled={disabled}
          ref={ref}
          type="checkbox"
          defaultChecked={defaultChecked}
          onChange={onChange}
        />
        {children}
      </label>
    );
  },
);

Checkbox.displayName = 'Checkbox';

export default Checkbox;
