import type { Dispatch } from 'react';

import type { Action } from './actions';

type BoundActionFn = (...args: any[]) => void;

export function bindActions<T extends object>(
    actions: T,
    dispatch: Dispatch<Action>
): T {
    const boundActions: Record<string, BoundActionFn> = {};

    for (const [key, action] of Object.entries(actions)) {
        // @ts-ignore
        boundActions[key] = (...args: any[]) => dispatch(action(...args));
    }

    return boundActions as T;
}
