window.GlobalFamiryProductProvider = ({ getUserRef, callDrevo, ExternalComponent, children }) => {
  const user = getUserRef();
  const { useState } = window;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [state, _setState] = useState({
    loading: false,
    options: null,
    tarifs: null,
    welcomeModalClosed: false,
    consultBannerClosed: false,
    isNewYearModalOpen: false,
  });

  window.GlobalFamiryProductContext = window.React.createContext({});

  const getState = () => state;

  const callbacks = [];
  const onStateChange = callback => {
    callbacks.push(callback);
  };

  const setState = newstate => {
    _setState(newstate);
    for (const i in callbacks) {
      if (typeof callbacks[i] === 'function') {
        callbacks[i](state);
      }
    }
  };

  window.famirytree_friend_invite_version = 1;
  if (user && user.passportUser && user.passportUser.active_options) {
    for (const product in user.passportUser.active_options) {
      for (const external_id in user.passportUser.active_options[product]) {
        for (const option in user.passportUser.active_options[product][external_id].options) {
          if (option === 'famirytree_friend_invite_version') {
            let value = user.passportUser.active_options[product][external_id].options[option];
            if (value !== null && typeof value === 'object') {
              value = value.value;
            }

            window.famirytree_friend_invite_version = value;
          }
        }
      }
    }
  }

  return (
    // eslint-disable-next-line react/jsx-no-undef
    <window.GlobalFamiryProductContext.Provider
      value={{
        get user() {
          return getUserRef();
        },
        callDrevo,
        ExternalComponent,
        setState,
        getState,
        onStateChange,
      }}
    >
      {children}
    </window.GlobalFamiryProductContext.Provider>
  );
};

window.GlobalFamiryProductProvider.displayName = 'GlobalFamiryProductProvider';
