export function plural(endings: string[] = [], count = 0): string {
  const countString = count.toString();
  const lastNumber = countString.at(-1);
  const penultimateNumber = countString.at(-2);

  if (lastNumber === '1' && penultimateNumber !== '1') {
    return endings[0];
  }

  if (lastNumber && ['2', '3', '4'].includes(lastNumber) && penultimateNumber !== '1') {
    return endings[1];
  }

  if (
    penultimateNumber === '1' ||
    (lastNumber && ['0', '5', '6', '7', '8', '9'].includes(lastNumber))
  ) {
    return endings[2];
  }

  return '';
}

export function formatTime(time: number): string {
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;

  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
}
