import { useCallback } from 'react';

import classnames from 'classnames';

import Coordinates from '@/components/Coordinates';
import { Button, Flex, ListItem, Text } from '@/fui';
import EditPencil from '@/fui/icons/edit-pencil.svg';
import type { Place } from '@/lib/map';

export default function PlaceItem({
    place,
    onSelect,
    onEdit,

    className,
    ...props
}: {
    place: Place;
    selected?: boolean;
    interactive?: boolean;
    onSelect?: (place: Place) => void;
    onEdit?: (place: Place) => void;
    className?: string;
}) {
    const handleRootClick = useCallback(() => {
        onSelect?.(place);
    }, [place, onSelect]);

    const handleEditButtonClick = useCallback(() => {
        onEdit?.(place);
    }, [place, onEdit]);

    return (
        <ListItem
            className={classnames('PlaceItem', className)}
            shape="rounded"
            content={
                <Flex
                    dir="column"
                    gap="smallest"
                >
                    {place.displayName && (
                        <Text
                            type="p2"
                            content={place.displayName}
                            color="primary"
                            weight="semibold"
                        />
                    )}

                    {place.address?.displayName && (
                        <Text
                            type="p3"
                            content={place.address.displayName}
                        />
                    )}

                    <Coordinates
                        lat={place.lat}
                        lon={place.lon}
                    />
                </Flex>
            }
            end={
                <Button
                    className="SearchResultsItem__end-action"
                    icon={<EditPencil />}
                    size="small"
                    variant="plain"
                    iconProps={{ size: 'smaller' }}
                    title="Изменить элемент"
                    onClick={handleEditButtonClick}
                />
            }
            onClick={handleRootClick}
            {...props}
        />
    );
}
