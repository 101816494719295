/* eslint-disable */
import { useEffect } from 'react';

import { useStore } from '@/hooks/store';
import { REACT_APP_CLIENT_ID, REACT_APP_YA_METRICS_COUNTER_ID } from '@/vars/env';

export const useYaMetricsInit = () => {
  const passportUserId = useStore(s => s.currentUser.user?.passportUser?.id ?? '');
  const userIsLoaded = useStore(s => !!s.currentUser.user);

  useEffect(() => {
    if (userIsLoaded) {
      const key = 'FAM';
      (window[key] =
        window[key] ||
        function () {
          // eslint-disable-next-line prefer-rest-params
          (window[key].s = window[key].s || []).push(arguments);
        })('init', {
        user_id: passportUserId || '',
        client_id: REACT_APP_CLIENT_ID,
      });

      const s = document.createElement('script');
      const t = document.head.getElementsByTagName('script')[0];
      s.async = true;
      s.src = '/id/global/t.js';
      document.head.insertBefore(s, t);
    }
  }, [userIsLoaded, passportUserId]);
};


export const useYaMetricsInstallationHook = () => {
  const metricsId = REACT_APP_YA_METRICS_COUNTER_ID;
  const metricsParams = {
    clickmap:true,
    trackLinks:true,
    accurateTrackBounce:true,
    webvisor:true
  };

  useEffect(() => {
    if (metricsId) {
      // @ts-ignore
      console.log(`Ya metrics tag: ${metricsId}`);
      // @ts-ignore
      (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
      // @ts-ignore
      m[i].l=1*new Date();
      // @ts-ignore
      for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
      // @ts-ignore
      k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
      // @ts-ignore
      (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

      // @ts-ignore
      ym(metricsId, "init", metricsParams);
    }
  }, []);
}