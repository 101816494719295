import {
    Alias as GeoAlias,
    Place as GeoPlace
} from '@famirytree/treelib/interfaces/geo';

export type LatLon = [number, number];
export type LatLonObject = { lat: number; lon: number };

export type Alias = GeoAlias & {
    lat?: number;
    lon?: number;
    place?: Place;
};

export type AliasData = Partial<Alias>;

export type Place = Omit<GeoPlace, 'address'> & {
    address?: GeoPlace['address'] & {
        displayName?: string;
    };
    placeId?: string;
};

export type PlaceData = Partial<Place>;

export interface MapClient {
    get element(): HTMLElement;
    get markers(): any[];
    init(): Promise<void>;
    destroy(): void;
    getCenter(): LatLon;
    setCenter(position: LatLon, zoom?: number): void;
    getZoom(): number;
    setZoom(...args: any[]): void;
    createMarker(position: LatLon, options?: MarkerOptions): L.Marker;
    addMarker(position: LatLon, options?: MarkerOptions): L.Marker;
    addClusterGroup(markers: L.Marker[]): void;
    removeMarkers(): void;
    fitBounds(bounds: any): void;
    clear(): void;
    layout(): void;
}

export enum MapProvider {
    Google = 'google',
    Mapbox = 'mapbox',
    OSM = 'osm',
    Yandex = 'yandex'
}

export type MapOptions = Record<string, any>;

export type MarkerOptions = {
    [key: string]: any;
    label?: string;
    onClick?: (marker: L.Marker) => void;
    onDragEnd?: (marker: L.Marker, position: LatLon) => void;
};

export type Marker<T = any> = {
    data?: T;
    lat?: number;
    lon?: number;
    hint?: string;
    icon?: {
        iconUrl: string;
        iconAnchor?: [number, number];
        tooltipAnchor?: [number, number];
    };
    activeIcon?: {
        iconUrl: string;
        iconAnchor?: [number, number];
        tooltipAnchor?: [number, number];
    };
};
