import { BroadcastChannel } from 'broadcast-channel';

const LOGOUT_TYPE = 'logout';
const LOGIN_TYPE = 'login';

function getBroadcastChannel() {
  if (window.BroadcastChannel) {
    return window.BroadcastChannel;
  }

  return BroadcastChannel;
}

const ChannelConstructor = getBroadcastChannel();
const broadcaseChannelSingleton = new ChannelConstructor('famiryGeneral');

export default broadcaseChannelSingleton;

export const broadcastLogout = () => {
  broadcaseChannelSingleton.postMessage({
    type: LOGOUT_TYPE,
  });
};

export const onLogout = callback => {
  broadcaseChannelSingleton.addEventListener('message', event => {
    const {
      data: { type, payload = null },
    } = event;
    if (type === LOGOUT_TYPE) {
      callback(payload, event);
    }
  });
};

export const broadcastLogin = ({ userId }) => {
  broadcaseChannelSingleton.postMessage({
    type: LOGIN_TYPE,
    payload: { userId },
  });
};

export const onLogin = callback => {
  broadcaseChannelSingleton.addEventListener('message', event => {
    const {
      data: { type, payload = null },
    } = event;
    if (payload && type === LOGIN_TYPE) {
      callback(payload.userId, event);
    }
  });
};
