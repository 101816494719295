import { useState } from 'react';

import type { PersonFullView as Person } from '@famirytree/treelib/interfaces';

import { extendTrim, FTSSearchKeys, useEnrichPersonsFTSName } from '@/hooks/useEnrichPersonFTSName';
import { useFullTextSearch } from '@/hooks/useFullTextSearch';

export default usePersonSearch;

export function usePersonSearch(persons: Person[]) {
  const [searchQuery, setSearchQuery] = useState('');

  const fusePersonNameEnrichedPersons = useEnrichPersonsFTSName(persons);

  const searchResults =
    useFullTextSearch(fusePersonNameEnrichedPersons, extendTrim(searchQuery), FTSSearchKeys) || [];

  return { searchQuery, searchResults, setSearchQuery };
}
