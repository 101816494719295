import { useCallback, useMemo } from 'react';
import { v4 } from 'uuid';

import {
  createFactRef,
  deleteFactRef,
  updateFactRef,
  updatePersonRef,
  useDispatch,
} from '@famirytree/crdt-storage';
import {
  FactDBView as Fact,
  PersonDBView as Person,
  PersonRoleType,
  UUID,
} from '@famirytree/treelib/interfaces';

import { useFacts } from '@/hooks/facts';
import { usePersonsMap } from '@/hooks/persons';

import { usePerson } from './usePerson';

export default usePersonFacts;

export function usePersonFacts(personId: UUID): [
  Fact[],
  {
    createFact: (data: Partial<Fact>) => void;
    updateFact: (fact: Fact) => void;
    deleteFact: (fact: Fact) => void;
    reorderFacts: (facts: Fact[]) => void;
  },
] {
  const dispatch = useDispatch();

  const facts = useFacts();
  const person = usePerson(personId);
  const personsMap = usePersonsMap();

  const personFacts = useMemo(() => {
    return (
      person?.facts
        ?.map(({ resource }) => facts[resource])
        .filter(Boolean)
        .filter(fact => !fact?.__meta__.empty) || []
    );
  }, [person, facts]);

  const createFact = useCallback(
    (data: Partial<Fact>) => {
      const newFact = {
        ...data,
        id: v4(),
        created: new Date().toISOString(),
        lastEdit: new Date().toISOString(),
      } as Fact;

      if (!newFact.relatedPersons?.find(({ resource }) => resource === personId)) {
        const thisPersonReference = {
          resource: personId,
          role: PersonRoleType.ThisPerson,
        };
        newFact.relatedPersons = newFact.relatedPersons.concat(thisPersonReference);
      }

      dispatch(createFactRef(newFact));

      const updatedPerson = {
        ...person,
        facts:
          person?.facts?.concat({
            resource: newFact.id,
          }) ?? [],
      };

      dispatch(updatePersonRef(updatedPerson));

      for (const { resource } of newFact.relatedPersons) {
        const personRef = personsMap[resource];

        if (personRef && resource !== person.id) {
          const updatedPerson = {
            ...personRef,
            facts: personRef.facts?.concat({
              resource: newFact.id,
            }),
          };

          dispatch(updatePersonRef(updatedPerson));
        }
      }
    },
    [dispatch, personId, person, personsMap],
  );

  const updateFact = useCallback(
    (updatedFact: Fact) => {
      const currentFact = facts[updatedFact.id];

      if (!currentFact) return;

      const personsToRemoveFactFrom = currentFact.relatedPersons.filter(
        ({ resource }) =>
          !updatedFact.relatedPersons.find(
            ({ resource: updatedResource }) => resource === updatedResource,
          ),
      );

      const personsToAddFactTo = updatedFact.relatedPersons.filter(
        ({ resource }) =>
          !currentFact.relatedPersons.find(
            ({ resource: currentResource }) => resource === currentResource,
          ),
      );

      for (const { resource } of personsToRemoveFactFrom) {
        const personRef = personsMap[resource];

        if (personRef) {
          const updatedPerson = {
            ...personRef,
            facts: personRef.facts?.filter(({ resource }) => resource !== updatedFact.id),
          };

          dispatch(updatePersonRef(updatedPerson));
        }
      }

      for (const { resource } of personsToAddFactTo) {
        const personRef = personsMap[resource];

        if (personRef) {
          const updatedPerson = {
            ...personRef,
            facts: personRef.facts?.concat({ resource: updatedFact.id }),
          };

          dispatch(updatePersonRef(updatedPerson));
        }
      }

      dispatch(
        updateFactRef({
          ...currentFact,
          ...updatedFact,
        }),
      );
    },
    [dispatch, facts, personsMap],
  );

  const deleteFact = useCallback(
    (fact: Fact) => {
      for (const { resource } of fact.relatedPersons ?? []) {
        const person = personsMap[resource];

        if (person) {
          const updatedPerson = {
            ...person,
            facts: person?.facts.filter(({ resource }) => resource !== fact.id),
          };

          dispatch(updatePersonRef(updatedPerson));
        }
      }

      dispatch(deleteFactRef(fact));
    },
    [dispatch, personsMap],
  );

  const reorderFacts = useCallback(
    (facts: Fact[]) => {
      const updatedPerson = {
        ...person,
        facts: facts.map(fact => ({ resource: fact.id })),
      };

      dispatch(updatePersonRef(updatedPerson));
    },
    [dispatch, person],
  );

  const actions = useMemo(
    () => ({
      createFact,
      updateFact,
      deleteFact,
      reorderFacts,
    }),
    [createFact, updateFact, deleteFact, reorderFacts],
  );

  return [personFacts, actions];
}

export function usePersonFactsCount(person: Person): number {
  const facts = useFacts();

  return useMemo(
    () => (person?.facts || []).map(({ resource }) => facts[resource]).filter(Boolean).length,
    [facts, person],
  );
}
