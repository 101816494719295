import { UUID } from '@famirytree/treelib/interfaces';

import routes from '@/constants/routes';
import { generateUri } from '@/utils';

type ServerError = {
  code: number;
  data: any;
};

const PERSON_ERROR_MESSAGES = {
  404: 'Такой персоны не существует',
  403: 'Это древо принадлежит другому пользователю и недоступно для просмотра',
};

const TREE_ERROR_MESSAGES = {
  404: 'Такого древа не существует',
  403: 'Это древо принадлежит другому пользователю и недоступно для просмотра',
};

export function isAuthError(error?: ServerError) {
  return error?.code === 403 || error?.code === 404;
}

export function getPersonErrorMessage(personError: ServerError) {
  return PERSON_ERROR_MESSAGES[personError.code];
}

export function getTreeErrorMessage(treeError: ServerError) {
  return TREE_ERROR_MESSAGES[treeError.code];
}

export function getRedirectPayload(treeId?: UUID, personId?: UUID) {
  const payload = personId
    ? generateUri(routes.TREE_BY_PERSON, {
        treeId,
        personId,
      })
    : generateUri(routes.CURRENT_TREE, { treeId });

  return payload;
}
