import cn from 'classnames';
import ReactSelect, {
  components,
  ControlProps,
  GroupBase,
  NoticeProps,
  Props as ReactSelectProps,
} from 'react-select';

import './Combobox.css';

export interface ComboboxOption {
  value: string | number | null;
  label: string;
  description?: string;
}

interface ClassNamesProps {
  wrapper: string;
  options: string;
  button: string;
}

export interface ComboboxProps {
  options: ReactSelectProps<ComboboxOption>['options'];
  onSelect: (_: {
    name?: ComboboxProps['name'];
    value: ComboboxOption['value'];
    dataset?: Record<string, string>;
  }) => void;
  selected: ComboboxOption['value'];
  defaultValue?: ComboboxOption['value'];
  name?: string;
  label?: string;
  disabled?: boolean;
  classNames?: Partial<ClassNamesProps>;
  withEmptyOptions?: boolean;
  extraData?: Record<string, string>;
}

const ControlComponent = (props: ControlProps<ComboboxOption, false>) => {
  const {
    // @ts-ignore It is official way to handle custom props
    selectProps: { label },
  } = props;

  return (
    <label className={cn('combobox_control')}>
      {label && <span className={cn('combobox_label')}>{label}</span>}
      <components.Control {...props} />
    </label>
  );
};

const NoOptionsMessage = (props: NoticeProps<ComboboxOption>) => {
  return (
    <components.NoOptionsMessage {...props}>
      <div className="combobox_no_options">Ничего не найдено</div>
    </components.NoOptionsMessage>
  );
};

function isGroup(
  optionOrGroup: ComboboxOption | GroupBase<ComboboxOption>,
): optionOrGroup is GroupBase<ComboboxOption> {
  return !!(optionOrGroup as GroupBase<ComboboxOption>).options;
}

export const Combobox = (props: ComboboxProps) => {
  const {
    options,
    label,
    onSelect,
    selected,
    name,
    defaultValue,
    disabled = false,
    withEmptyOptions = false,
    extraData = {},
  } = props;

  const extendedOptions = withEmptyOptions
    ? [{ label: '—', value: null }, ...(options || [])]
    : options;

  const selectedOption = extendedOptions
    ?.flatMap(optionOrGroup => (isGroup(optionOrGroup) ? optionOrGroup.options : optionOrGroup))
    .find(option => option.value === selected);
  const defaultValueOption = extendedOptions
    ?.flatMap(optionOrGroup => (isGroup(optionOrGroup) ? optionOrGroup.options : optionOrGroup))
    .find(option => option.value === defaultValue);

  return (
    <ReactSelect<ComboboxOption>
      // @ts-ignore It is official way to handle custom props
      label={label}
      isDisabled={disabled}
      options={extendedOptions}
      onChange={option => onSelect({ name, value: option?.value || null, dataset: extraData })}
      value={selectedOption}
      defaultValue={defaultValueOption}
      unstyled={true}
      openMenuOnClick={true}
      maxMenuHeight={200}
      // @ts-ignore
      components={{ Control: ControlComponent, NoOptionsMessage }}
      className="combobox_root"
      classNames={{
        container: () =>
          `combobox_rs_container ${disabled ? 'combobox_rs_container_disabled' : ''}`,
        control: () => 'combobox_rs_control',
        menu: () => 'combobox_rs_menu',
        menuList: () => 'combobox_rs_menu_list',
        option: state => {
          if (state.isFocused) {
            return 'combobox_rs_option combobox_rs_option_focused';
          }
          return 'combobox_rs_option';
        },
      }}
    />
  );
};

export default Combobox;
