import { UUID } from '@famirytree/treelib/interfaces';

import { useStore } from '@/hooks/store';
import { useCurrentTreeId, useIsOwnTree } from '@/hooks/tree';

export default useIsSharedPerson;

export function useIsSharedPerson(personId: UUID): boolean {
  const currentTreeId = useCurrentTreeId();
  const isOwnTree = useIsOwnTree(currentTreeId);

  const { shareToken } = useStore(s => s.persons[personId]?.__meta__ ?? {});
  const grants = useStore(s => s.currentTree.grants ?? []).filter(
    g => g.refId === personId || g.refId === currentTreeId,
  );

  return !isOwnTree && !!shareToken && Object.keys(grants).length === 0;
}
