export function isValidNumber(value: never): boolean {
  return !isNaN(parseFloat(value)) && isFinite(value);
}

export function percentage(partialNumber: number, totalNumber: number): number {
  if (totalNumber === 0) {
    return 0;
  }

  return (100 * partialNumber) / totalNumber;
}

export function average(numbers: number[]) {
  if (numbers.length === 0) {
    return 0;
  }

  return numbers.reduce((acc, number) => acc + number, 0) / numbers.length;
}
