import { GenderType, PersonRoleType, PersonStatusType } from '@famirytree/treelib/interfaces';

export const PERSON_STATUSES = PersonStatusType;

export const PERSON_ROLES = PersonRoleType;

export const PERSON_ROLES_NAMES = {
  [PersonRoleType.Wife]: 'жена',
  [PersonRoleType.Husband]: 'муж',
  [PersonRoleType.Spouse]: 'супруг',
  [PersonRoleType.Partner]: 'партнёр',
  [PersonRoleType.Parent]: 'родитель',
  [PersonRoleType.Father]: 'отец',
  [PersonRoleType.Mother]: 'мать',
  [PersonRoleType.Child]: 'ребёнок',
  [PersonRoleType.Son]: 'сын',
  [PersonRoleType.Brother]: 'брат',
  [PersonRoleType.Sister]: 'сестра',
  [PersonRoleType.Daughter]: 'дочь',
  [PersonRoleType.GrandSon]: 'внук',
  [PersonRoleType.GrandDaughter]: 'внучка',
};

export const PERSON_ROLES_NAMES_GENITIVE: Partial<Record<PersonRoleType, string>> = {
  [PersonRoleType.Wife]: 'жену',
  [PersonRoleType.Husband]: 'мужа',
  [PersonRoleType.Spouse]: 'супруга',
  [PersonRoleType.Partner]: 'партнёра',
  [PersonRoleType.Parent]: 'родителя',
  [PersonRoleType.Father]: 'отца',
  [PersonRoleType.Mother]: 'мать',
  [PersonRoleType.Child]: 'ребёнка',
  [PersonRoleType.Son]: 'сына',
  [PersonRoleType.Brother]: 'брата',
  [PersonRoleType.Sister]: 'сестру',
  [PersonRoleType.Daughter]: 'дочь',
  [PersonRoleType.GrandSon]: 'внука',
  [PersonRoleType.GrandDaughter]: 'внучку',
};

export const PERSON_GENDER_TYPES = GenderType;
