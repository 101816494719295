import cn from 'classnames';
import { SyntheticEvent, useEffect, useRef, useState } from 'react';

import { useClickOutside } from '@/hooks/useClickOutside';

import './CustomSelect.css';

export interface SelectOptions {
  value: string | number | null;
  label: string;
  description?: string;
}

interface ClassNamesProps {
  wrapper: string;
  options: string;
  button: string;
}
export interface SelectProps {
  options: SelectOptions[];
  onSelect: (arg: { name: string; value: any; dataset?: Record<string, string> }) => void;
  onClick?: () => void;
  selected: string;
  name?: string;
  label?: string;
  disabled?: boolean;
  classNames?: Partial<ClassNamesProps>;
  withEmptyOptions?: boolean;
  forceSelectOnChange?: boolean;
  isTransparent?: boolean;
  extraData: Record<string, string>;
}

const MAX_MENU_HEIGHT = 250;
const AVG_OPTION_HEIGHT = 50;

const CustomSelect = ({
  options = [],
  onSelect,
  selected,
  name,
  extraData,
  withEmptyOptions = false,
  forceSelectOnChange = true,
  classNames,
  disabled = false,
  onClick = () => {},
  label,
}: SelectProps) => {
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<SelectOptions>({ value: null, label: '' });
  const listRef = useRef<HTMLUListElement>(null);
  const containerRef = useRef<HTMLLabelElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const emptyOption = { value: null, label: '' };
  useClickOutside([containerRef], () => {
    setIsOptionsOpen(false);
  });

  const updateSelectBounds = () => {
    if (containerRef.current) {
      const bound = containerRef.current.getBoundingClientRect().toJSON();
      const windowHeight = window.innerHeight;
      const menuHeight = Math.min(MAX_MENU_HEIGHT, options.length * AVG_OPTION_HEIGHT);
      const instOffsetWithMenu = bound?.bottom + menuHeight;

      if (instOffsetWithMenu >= windowHeight && menuRef.current) {
        menuRef.current?.style.removeProperty('top');
        menuRef.current?.style.setProperty('bottom', '100%');
      } else {
        menuRef.current?.style.removeProperty('bottom');
        menuRef.current?.style.setProperty('top', '100%');
      }
      if (instOffsetWithMenu - windowHeight - menuHeight - bound.height > 0) {
        setIsOptionsOpen(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('resize', updateSelectBounds);
    window.addEventListener('scroll', updateSelectBounds);

    return () => {
      window.removeEventListener('resize', updateSelectBounds);
      window.removeEventListener('scroll', updateSelectBounds);
    };
  }, []);

  const selectOption = (opt: SelectOptions) => {
    const currentSelected = listRef.current!.querySelector(
      "li[aria-selected='true']",
    ) as HTMLElement;
    onSelect({
      name: name || '',
      value: opt.value,
      dataset: Object.assign({}, currentSelected.dataset as Record<string, string>),
    });
  };

  const getElementOption = (el: HTMLElement | null) => {
    if (el) {
      const isEmpty = !!el.getAttribute('data-empty');
      if (isEmpty) {
        return emptyOption;
      } else {
        const optValue = el.getAttribute('data-value');
        return options.find(opt => opt.value + '' === optValue)!;
      }
    } else {
      return emptyOption;
    }
  };

  const selectNextElement = (nextEl: HTMLElement | null) => {
    if (nextEl) {
      const opt = getElementOption(nextEl);
      if (forceSelectOnChange) {
        selectOption(opt);
      }
      setSelectedOption(opt);
      nextEl.focus();
    }
  };

  const keyHandler = (ev: KeyboardEvent) => {
    const currentSelected = listRef.current!.querySelector(
      "li[aria-selected='true']",
    ) as HTMLElement;
    ev.stopPropagation();
    ev.preventDefault();
    switch (ev.key) {
      case ' ':
      case 'Enter': {
        const opt = getElementOption(currentSelected);
        setIsOptionsOpen(false);
        selectOption(opt);
        return;
      }
      case 'ArrowUp': {
        if (currentSelected) {
          selectNextElement(currentSelected.previousElementSibling as HTMLElement);
        } else {
          selectNextElement(listRef.current!.firstElementChild as HTMLElement);
        }
        break;
      }
      case 'ArrowDown': {
        if (currentSelected) {
          selectNextElement(currentSelected.nextElementSibling as HTMLElement);
        } else {
          selectNextElement(listRef.current!.firstElementChild as HTMLElement);
        }
        break;
      }
    }
  };

  useEffect(() => {
    if (isOptionsOpen && listRef.current) {
      document.addEventListener('keydown', keyHandler);
      return () => {
        document.removeEventListener('keydown', keyHandler);
      };
    }
  }, [isOptionsOpen, listRef.current]);

  useEffect(() => {
    const selectedValue = options.find(item => selected === item.value);
    setSelectedOption(selectedValue ?? emptyOption);
  }, [selected, options]);

  const toggleOptions = () => {
    setIsOptionsOpen(prev => !prev);
  };

  const setSelectedThenCloseDropdown =
    (opt: SelectOptions) => (e: SyntheticEvent<HTMLLIElement, MouseEvent>) => {
      e.stopPropagation();
      e.preventDefault();
      setSelectedOption(opt);
      setIsOptionsOpen(false);
      selectOption(opt);
    };

  return (
    <label
      ref={containerRef}
      className={cn('custom-select__wrapper', classNames?.wrapper, {
        'custom-select__wrapper_with-label': !!label,
        'custom-select__disabled': disabled,
      })}
      data-active={isOptionsOpen}
      onClick={() => {
        onClick();
        toggleOptions();
        updateSelectBounds();
      }}
    >
      <a
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        className={cn('custom-select__button', classNames?.button, { expanded: isOptionsOpen })}
      >
        {selectedOption.value !== null ? (
          selectedOption.label
        ) : (
          <div className="custom-select__empty" />
        )}
      </a>
      <span
        className={cn('custom-select__arrow-icon', classNames?.button, { expanded: isOptionsOpen })}
      />
      <div
        data-scrollable="true"
        className={cn('custom-select__options_wrapper', {
          select__options_show: isOptionsOpen,
        })}
        ref={menuRef}
      >
        <ul
          className={cn('custom-select__options', classNames?.options)}
          role="listbox"
          tabIndex={-1}
          ref={listRef}
        >
          {withEmptyOptions && (
            <li
              role="option"
              aria-selected={selectedOption.value === null}
              tabIndex={0}
              data-empty="true"
              {...(extraData || {})}
              onClick={setSelectedThenCloseDropdown({ value: null, label: '' })}
            >
              <div className="custom-select__empty" />
            </li>
          )}
          {options.map(option => {
            if (typeof option.value === 'boolean') {
              console.log('!!!!! CustomSelect option is not string', option.value);
            }
            return (
              <li
                key={option.value}
                id={option.value + ''}
                role="option"
                aria-selected={selectedOption.value === option.value}
                tabIndex={0}
                // @ts-ignore
                value={option.value}
                {...(extraData || {})}
                data-value={option.value}
                onClick={setSelectedThenCloseDropdown(option)}
              >
                {option.label}
                {option?.description && (
                  <span className="custom-select__option_description">{option?.description}</span>
                )}
              </li>
            );
          })}
        </ul>
      </div>
      {label && (
        <span
          aria-placeholder={label}
          data-active={true}
          className={cn('custom-select__placeholder')}
        >
          {label}
        </span>
      )}
    </label>
  );
};

export default CustomSelect;
