import { useCallback, useContext, useEffect, useState } from 'react';

import classnames from 'classnames';

import AliasSearchResults from '@/components/AliasSearchResults';
import PlaceSearchResults from '@/components/PlaceSearchResults';
import SearchEmptyResult from '@/components/SearchEmptyResult';
import SearchForm from '@/components/SearchForm';
import SearchHeader from '@/components/SearchHeader';
import SearchResults from '@/components/SearchResults';
import MapContext from '@/contexts/MapContext';
import { Button } from '@/fui';
import NavArrowDown from '@/fui/icons/nav-arrow-down.svg';
import NavArrowRight from '@/fui/icons/nav-arrow-right.svg';
import type { Alias, AliasData, Place } from '@/lib/map';

export default function Search({
    heading,
    query,
    collapsible = false,
    onSearch,
    onEditAlias,
    onCreateAlias,
    onSelectAlias,
    onSelectAliasWithMarker,
    onSelectPlace,
    onClear
}: {
    heading: string;
    query?: string;
    collapsible?: boolean;
    onSearch: (data: { q: string }) => Promise<void>;
    onEditAlias: (item: AliasData) => void;
    onCreateAlias: (item: AliasData) => void;
    onSelectAlias: (alias: Alias) => void;
    onSelectAliasWithMarker: (alias: Alias) => void;
    onSelectPlace: (place: Place) => void;
    onClear: () => void;
}) {
    const { state } = useContext(MapContext);

    const [searchQuery, setSearchQuery] = useState(query);
    const [selectedAlias, setSelectedAlias] = useState<Alias>();
    const [selectedPlace, setSelectedPlace] = useState<Place>();
    const [isCollapsed, setCollapsed] = useState(false);

    useEffect(() => {
        setCollapsed(collapsible);
    }, [collapsible]);

    const handleSubmit = useCallback(
        (data: { q: string }) => {
            setSearchQuery(data.q);
            setSelectedAlias(undefined);
            setSelectedPlace(undefined);
            return onSearch(data);
        },
        [onSearch]
    );

    const handleSelectAlias = useCallback(
        (alias: Alias) => {
            setSelectedAlias(alias);
            setSelectedPlace(undefined);
            return onSelectAlias(alias);
        },
        [onSelectAlias]
    );

    const handleSelectPlace = useCallback(
        (place: Place) => {
            setSelectedPlace(place);
            setSelectedAlias(undefined);
            return onSelectPlace(place);
        },
        [onSelectPlace]
    );

    const handleSelectAliasWithMarker = useCallback(
        (aliasData: AliasData) => {
            setSearchQuery('');
            return onSelectAliasWithMarker(aliasData as Alias);
        },
        [onSelectAliasWithMarker]
    );

    const handleEditAlias = useCallback(
        (alias: Alias) => {
            setSelectedAlias(alias);
            setSelectedPlace(undefined);
            return onEditAlias(alias);
        },
        [onEditAlias]
    );

    const handleEditPlace = useCallback(
        (place: Place) => {
            setSelectedPlace(place);
            setSelectedAlias(undefined);
            return onEditAlias({
                name: place.address?.locality,
                place
            });
        },
        [onEditAlias]
    );

    const handleSelectButtonClick = useCallback(() => {
        if (selectedAlias) {
            onCreateAlias(selectedAlias);
        } else if (selectedPlace) {
            onCreateAlias({
                name: selectedPlace.displayName || '',
                aliases: [],
                comment: '',
                place: selectedPlace
            });
        }
    }, [selectedAlias, selectedPlace, onCreateAlias]);

    const handleHeaderClick = useCallback(() => {
        setCollapsed(value => !value);
    }, []);

    const handleReset = useCallback(() => {
        return onClear();
    }, [onClear]);

    const hasAliasResults = (state.aliases?.length ?? 0) > 0;
    const hasPlaceResults = (state.places?.length ?? 0) > 0;
    const hasEmptyResults =
        state.error ||
        (searchQuery &&
            Array.isArray(state.aliases) &&
            state.aliases.length === 0 &&
            Array.isArray(state.places) &&
            state.places.length === 0);

    const className = classnames('Search', {
        'Search--collapsible': collapsible,
        'Search--collapsed': isCollapsed
    });

    return (
        <div className={className}>
            <SearchHeader
                heading={heading}
                icon={
                    collapsible &&
                    (isCollapsed ? <NavArrowRight /> : <NavArrowDown />)
                }
                onClick={collapsible ? handleHeaderClick : undefined}
            />

            {!isCollapsed && (
                <>
                    <SearchForm
                        query={query}
                        label="Введите название или координаты"
                        onSubmit={handleSubmit}
                        onReset={handleReset}
                    />

                    <SearchResults>
                        {hasAliasResults && (
                            <AliasSearchResults
                                aliases={state.aliases || []}
                                selectedAlias={selectedAlias}
                                onSelectAlias={handleSelectAlias}
                                onEditAlias={handleEditAlias}
                            />
                        )}

                        {hasPlaceResults && (
                            <PlaceSearchResults
                                places={state.places || []}
                                selectedPlace={selectedPlace}
                                onSelectPlace={handleSelectPlace}
                                onEditPlace={handleEditPlace}
                            />
                        )}

                        {hasEmptyResults && (
                            <SearchEmptyResult
                                query={searchQuery}
                                error={state.error}
                                onCreateAlias={onCreateAlias}
                                onSelectAliasWithMarker={
                                    handleSelectAliasWithMarker
                                }
                            />
                        )}
                    </SearchResults>

                    {!hasEmptyResults && (
                        <Button
                            className="Search__select-button"
                            content="Выбрать местоположение"
                            color="primary"
                            variant="filled"
                            fluid
                            disabled={!(selectedAlias || selectedPlace)}
                            onClick={handleSelectButtonClick}
                        />
                    )}
                </>
            )}
        </div>
    );
}
