import { useMemo } from 'react';

import { PersonFullView as Person } from '@famirytree/treelib/interfaces';

import { useStore } from '@/hooks/store';
import { useCurrentTree } from '@/hooks/tree';
import { personsStoreSelector } from '@/selectors';

export default usePersons;

export const FILTER_PERSONS_WITH_SHARE_TOKEN = '$[?(@.shareTokens?.length > 0)]';

export function usePersons() {
  const currentTree = useCurrentTree();
  const personsStore = useStore(personsStoreSelector);

  return useMemo<Person[]>(() => {
    if (currentTree?.persons && personsStore && Object.keys(personsStore).length > 0) {
      return currentTree.persons
        .filter(({ resource }) => !!personsStore[resource])
        .map(({ resource }) => personsStore[resource]);
    }

    return [];
  }, [currentTree?.persons, personsStore]);
}

export function usePersonsMap() {
  return useStore(personsStoreSelector);
}
