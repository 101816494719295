import type { ReactNode } from 'react';

import { cn } from '@/utils';

import styles from './Scroller.module.scss';

export default function Scroller({
  height,
  scrollingDisabled,
  children,
}: {
  height?: string | number;
  scrollingDisabled?: boolean;
  children: ReactNode | ReactNode[];
}) {
  return (
    <div
      className={cn(styles.root, scrollingDisabled && styles.scrollingDisabled)}
      style={height ? { height: `${height}px` } : undefined}
      data-scrollable="true"
    >
      {children}
    </div>
  );
}
