import { useMemo } from 'react';

import type { RelationshipDBView, UUID } from '@famirytree/treelib/interfaces';

import { useRelationships } from '@/hooks/relationships';

export type RelationshipData = Partial<RelationshipDBView>;

export function usePersonRelationships(personId: UUID) {
  const relationships = useRelationships();

  const personRelationships = useMemo(() => {
    return Object.values(relationships)
      .filter(Boolean)
      .filter(({ person1, person2 }) => {
        return person1.resource === personId || person2.resource === personId;
      });
  }, [personId, relationships]);

  return personRelationships;
}
