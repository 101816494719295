export const ACTIONS_AFTER_AUTHORIZATION = {
  DOWNLOAD_PDF: 'DOWNLOAD_PDF',
  IMPORT_TREE: 'IMPORT_TREE',
  RENDERER_ACTION: 'RENDERER_ACTION',
  CREATE_TREE: 'CREATE_TREE',
  COPY_LINK_TREE: 'COPY_LINK_TREE',
  OPEN_BASE_TREE: 'OPEN_BASE_TREE',
  OPEN_BASE_PERSON: 'OPEN_BASE_PERSON',
  OPEN_PROFILE_MENU: 'OPEN_PROFILE_MENU',
  REDIRECT: 'REDIRECT',
};
