import { useCallback } from 'react';

import { useUserMode } from '@/hooks/user';

import { useCurrentTreeId } from '../tree';
import { accessEntryStates } from './constants';
import { EntryAccessMode, EntryAccessState, EntryElement } from './types';

export const DATA_ENTRY_ACCESS_PROP = 'data-entry-access-mode';
export const DATA_ENTRY_ACCESS_ELEMENT_PROP = 'data-entry-access-name';

export default useElementAccessCallback;

export function useElementAccessCallback(): (elementName: EntryElement) => EntryAccessState {
  const currentTreeId = useCurrentTreeId();
  const mode = useUserMode();

  return useCallback(
    (elementName: EntryElement) => {
      const elementDescriptor = accessEntryStates[elementName];
      if (mode === EntryAccessMode.Owner) {
        return elementDescriptor[mode] ?? EntryAccessState.Available;
      }
      if (mode === EntryAccessMode.Unknown) {
        return elementDescriptor[mode] ?? EntryAccessState.NotAvailable;
      }
      if (!elementDescriptor) {
        const entryName = Object.keys(EntryElement).filter(
          name => EntryElement[name as keyof EntryElement] === elementName,
        );
        throw new Error(`Requested element has no access mode descriptor: ${entryName}`);
      }
      return elementDescriptor[mode] ?? EntryAccessState.NotAvailable;
    },
    [mode, currentTreeId],
  );
}

export function useElementAccessPropCallback(): (elementName: EntryElement) => {
  [DATA_ENTRY_ACCESS_PROP]: EntryAccessState;
  [DATA_ENTRY_ACCESS_ELEMENT_PROP]: string;
} {
  const mode = useUserMode();
  return useCallback(
    (elementName: EntryElement) => {
      const elementDescriptor = accessEntryStates[elementName];
      if (mode === EntryAccessMode.Owner) {
        return {
          [DATA_ENTRY_ACCESS_PROP]: elementDescriptor[mode] ?? EntryAccessState.Available,
          [DATA_ENTRY_ACCESS_ELEMENT_PROP]: EntryElement[elementName],
        };
      }
      if (mode === EntryAccessMode.Unknown) {
        return {
          [DATA_ENTRY_ACCESS_PROP]: elementDescriptor[mode] ?? EntryAccessState.NotAvailable,
          [DATA_ENTRY_ACCESS_ELEMENT_PROP]: EntryElement[elementName],
        };
      }
      if (!elementDescriptor) {
        const entryName = Object.keys(EntryElement).filter(
          name => EntryElement[name as keyof EntryElement] === elementName,
        );
        throw new Error(`Requested element has no access mode descriptor: ${entryName}`);
      }
      return {
        [DATA_ENTRY_ACCESS_PROP]: elementDescriptor[mode] ?? EntryAccessState.NotAvailable,
        [DATA_ENTRY_ACCESS_ELEMENT_PROP]: EntryElement[elementName],
      };
    },
    [mode],
  );
}
