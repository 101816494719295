import { useSelector } from 'react-redux';

import { FamiryProduct } from '@famirytree/productlib';

import { currentUserSelector } from '@/selectors';

export const paymentModalEventName = 'OPEN_PAYMENTS_MODAL';

interface IPassportOption {
  value: any;
  isYes: boolean;
}

export const useFamiryProduct = (extraProducts: string | string[] = []) => {
  const user = useSelector(currentUserSelector);
  if (typeof extraProducts === 'string') {
    extraProducts = [extraProducts];
  }
  return new FamiryProduct(
    [...extraProducts, `user.${user?.passportUser?.id}`],
    // @ts-ignore Property 'active_options' does not exist on type 'PassportUser'.
    user?.passportUser?.active_options ?? {},
  );
};

export const useUserProduct = () => {};

export default function usePassportOptions() {
  const user = useSelector(currentUserSelector);
  const options =
    // @ts-ignore Property 'active_options' does not exist on type 'PassportUser'.
    user?.passportUser?.active_options?.famirytree?.['user.' + user.passportUser?.id]?.options ||
    {};

  const isYes = value => {
    if (value !== null && typeof value === 'object') {
      value = value.value;
    }
    return (
      value !== null &&
      value !== 'no' &&
      value !== '0' &&
      value !== 0 &&
      value !== 'false' &&
      value !== false &&
      value !== undefined
    );
  };

  const getOption = (name: string, defaultValue: any = undefined): IPassportOption | undefined => {
    const value = options[name]?.value || defaultValue;
    if (!value) {
      return undefined;
    }
    return <IPassportOption>{
      name,
      value,
      isYes: isYes(value),
    };
  };

  const getAll = () => {
    return Object.keys(options).map(key => getOption(key));
  };

  return {
    getOption,
    getAll,
    isYes,
  };
}
