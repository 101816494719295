export const ROOT_TREE = '/tree';
export const PERSON = '/person';
export const CURRENT_TREE = `${ROOT_TREE}/:treeId`;
export const BASE_TREES = `${ROOT_TREE}/projects`;
export const PERSONS = `${ROOT_TREE}/:treeId/persons`;
export const DNA = `${ROOT_TREE}/:treeId/dna`;
export const MAP = `${ROOT_TREE}/:treeId/map`;
export const TREE_BY_PERSON = `${ROOT_TREE}/:treeId${PERSON}/:personId`;
export const TREE_BY_PERSON_SHARED = `${ROOT_TREE}/:treeId${PERSON}/:personId/shared`;
export const TREE_SHARED = `${ROOT_TREE}/:treeId/shared`;
export const ERROR = `${ROOT_TREE}/error`;

export default {
  ROOT_TREE,
  CURRENT_TREE,
  PERSON,
  PERSONS,
  DNA,
  MAP,
  TREE_BY_PERSON,
  TREE_BY_PERSON_SHARED,
  TREE_SHARED,
  BASE_TREES,
  ERROR,
};
