import { AccessMode } from '@famirytree/treelib/famiry-path';
import { EntityTypeName, Grant } from '@famirytree/treelib/interfaces';

export const hasPersonsMainPageAccess = (grant: Grant) => {
  switch (grant.refType) {
    case EntityTypeName.u_tree: {
      const treeAccess = grant.rules[0].defaultMode !== AccessMode.NotAvailable;
      const forbiddenPersonPageAccess = !!grant.rules[0].filters.find(
        entry =>
          entry.selector === `${EntityTypeName.u_person_page}` &&
          entry.mode === AccessMode.NotAvailable,
      );
      return treeAccess && !forbiddenPersonPageAccess;
    }
    case EntityTypeName.u_person:
      return grant.rules[0].defaultMode !== AccessMode.NotAvailable;
  }
  return false;
};
