import cn from 'classnames';
import { SyntheticEvent, useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { FamiryMapProps } from '@famirymaps/map';
import { AppState } from '@famirytree/crdt-storage';

import { Input } from '@/components/common';
import MapModal from '@/components/MapModal';

interface PlaceInputProps {
  placeFormLabel: string;
  placeInputName: string;
  extendedPlaceInputName: string;
  disabled: boolean;
  placeValue: any;
  extendedPlaceValue: FamiryMapProps;
  onChangeValue: (any) => void;
}

const PlaceInput = ({
  placeFormLabel,
  placeInputName,
  extendedPlaceInputName,
  disabled,
  placeValue,
  extendedPlaceValue,
  onChangeValue,
}: PlaceInputProps) => {
  const currentUserId = useSelector((state: AppState) => state.currentUser.user?.id);
  const inputRef = useRef<HTMLInputElement>(null);
  const [extendedPlaceModalOpened, setExtendedPlaceModalOpened] = useState(false);

  const preventDefaultCallbacks = useCallback((ev: SyntheticEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
  }, []);

  const emulateInputBlur = useCallback(() => {
    inputRef?.current?.focus();
    setImmediate(() => {
      inputRef?.current?.blur();
    });
  }, []);

  return (
    <>
      <Input
        ref={inputRef}
        label={placeFormLabel}
        type="text"
        value={placeValue || ''}
        disabled={disabled}
        readOnly={true}
        autoComplete="off"
        name={placeInputName}
        onChange={ev => {
          preventDefaultCallbacks(ev);
          onChangeValue({
            name: placeInputName,
            value: ev.target.value,
          });
        }}
        onClick={ev => {
          if (disabled) {
            return;
          }
          setExtendedPlaceModalOpened(true);
          preventDefaultCallbacks(ev);
        }}
        classNames={{
          wrapper: cn('fam-form__date_and_place__location_input', {
            'fam-form__date_and_place__location_input_disabled': disabled,
          }),
        }}
        icon={
          extendedPlaceValue || placeValue ? (
            <button
              type="button"
              className="form__button form__button_type_clear-location"
              onClick={e => {
                preventDefaultCallbacks(e);
                onChangeValue({
                  name: extendedPlaceInputName,
                  value: null,
                });
                onChangeValue({
                  name: placeInputName,
                  value: null,
                });
                setImmediate(() => {
                  emulateInputBlur();
                });
              }}
              disabled={disabled}
            />
          ) : (
            <button
              type="button"
              className="form__button form__button_type_location"
              disabled={disabled}
            />
          )
        }
      />
      {/*{disabled && extendedPlaceValue?.place && (*/}
      {/*  <FamiryMap*/}
      {/*    provider="osm"*/}
      {/*    apiUrl={document.location.hostname === "famiry.ru" ? "https://map.famiry.dev/api/v1" : "https://maptest.famiry.dev/api/v1"}*/}
      {/*    userPassportId={currentUserId}*/}
      {/*    providerOptions={{*/}
      {/*      imagePath: 'https://drevodrive.famiry.ru/map/images/'*/}
      {/*    }}*/}
      {/*    center={[0, 0]}*/}
      {/*    objects={[{*/}
      {/*      type: 'point',*/}
      {/*      lat: extendedPlaceValue.place.lat,*/}
      {/*      lon: extendedPlaceValue.place.lon,*/}
      {/*    }]}*/}
      {/*    className="form__place-map"*/}
      {/*  />*/}
      {/*)}*/}
      {extendedPlaceModalOpened && (
        <MapModal
          // @ts-ignore
          alias={extendedPlaceValue || null}
          query={placeValue}
          userPassportId={currentUserId}
          onClose={() => {
            setExtendedPlaceModalOpened(false);
          }}
          onFocus={preventDefaultCallbacks}
          onBlur={preventDefaultCallbacks}
          onAliasSaved={alias => {
            onChangeValue({
              name: extendedPlaceInputName,
              value: alias,
            });
            emulateInputBlur();
            setExtendedPlaceModalOpened(false);
          }}
        />
      )}
    </>
  );
};

PlaceInput.display = 'PlaceInput';

export default PlaceInput;
