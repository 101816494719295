import { useMemo } from 'react';

import { PersonDBView } from '@famirytree/treelib/interfaces';

import { permutations } from '@/utils';

const ftsSearchKey = (i: number) => `fuseNameSearchTerm_${i + 1}`;

type FormattedPersonType = PersonDBView;
type EnrchiFTSKeys = {
  [key: string]: string | unknown;
};

export type EnrichPersonFTSName = FormattedPersonType & EnrchiFTSKeys;

export const FTSSearchKeys = permutations([1, 2, 3, 4]).map((_, i) => ftsSearchKey(i));

export const resortSearchTermParts = (term: string) => {
  const termParts = term.split(/\s+/);
  termParts.sort();
  return extendTrim(termParts.join(' '));
};

export const extendTrim = (value: string) => {
  return value.trim().replace(/\s+/, ' ').toLocaleLowerCase();
};

export const useEnrichPersonsFTSName = (persons: FormattedPersonType[]): EnrichPersonFTSName[] => {
  return useMemo(
    () =>
      persons.map(person => {
        const {
          firstName = '',
          lastNameAtBirth = '',
          lastName = lastNameAtBirth,
          middleName = '',
        } = person.basicInfo;

        const ret = { ...person };
        const keys = [] as string[];

        firstName && keys.push(firstName);
        lastName && keys.push(lastName);
        middleName && keys.push(middleName);
        lastNameAtBirth && lastNameAtBirth !== lastName && keys.push(lastNameAtBirth);

        permutations(keys).forEach((variant, i) => {
          ret[ftsSearchKey(i)] = extendTrim(variant.join(' '));
        });

        FTSSearchKeys.forEach(key => {
          if (!ret[key]) {
            ret[key] = '';
          }
        });

        return ret;
      }),
    [persons],
  );
};
