import type { ReactNode } from 'react';

import { Heading, Icon } from '@/fui';

export default function SearchHeader({
    heading,
    icon,
    ...props
}: {
    heading: string;
    icon?: ReactNode;
    onClick?: () => void;
}) {
    return (
        <header
            className="SearchHeader"
            {...props}
        >
            {icon && <Icon size="small">{icon}</Icon>}

            <Heading
                type="h3"
                content={heading}
                bold
            />
        </header>
    );
}
