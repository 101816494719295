import { FactType } from '@famirytree/treelib/interfaces';

export const FactTypeValues = Object.keys(FactType);

export const FactTypeLabel: Record<FactType, string> = {
  [FactType.Adoption]: 'Усыновление/Удочерение',
  [FactType.AdultChristening]: 'Крещение (взрослый)',
  [FactType.Birth]: 'Рождение',
  [FactType.Burial]: 'Похороны',
  [FactType.Christening]: 'Крещение',
  [FactType.Custom]: 'Пользовательское',
  [FactType.Death]: 'Смерть',
  [FactType.Divorce]: 'Развод',
  [FactType.DivorceFiling]: 'Подача на развод',
  [FactType.Marriage]: 'Брак',
};

export const FactTypeValueByLabel: Record<string, FactType> = {
  [FactTypeLabel[FactType.Adoption]]: FactType.Adoption,
  [FactTypeLabel[FactType.AdultChristening]]: FactType.AdultChristening,
  [FactTypeLabel[FactType.Birth]]: FactType.Birth,
  [FactTypeLabel[FactType.Burial]]: FactType.Burial,
  [FactTypeLabel[FactType.Christening]]: FactType.Christening,
  [FactTypeLabel[FactType.Custom]]: FactType.Custom,
  [FactTypeLabel[FactType.Death]]: FactType.Death,
  [FactTypeLabel[FactType.Divorce]]: FactType.Divorce,
  [FactTypeLabel[FactType.DivorceFiling]]: FactType.DivorceFiling,
  [FactTypeLabel[FactType.Marriage]]: FactType.Marriage,
};
