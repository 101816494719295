import { EntityMeta } from '@famirytree/crdt-storage';
import { PersonDBView, UUID } from '@famirytree/treelib/interfaces';

import { useStore } from '@/hooks/store';

export type Person = PersonDBView & EntityMeta;

export default usePerson;

export function usePerson(personId: UUID): Person {
  const person = useStore(
    state => state.persons[personId],
    (a, b) => !!a?.__meta__?.hash && a?.__meta__?.hash === b?.__meta__?.hash,
  );

  if (person?.__meta__.empty) {
    return {} as Person;
  }

  return person;
}
