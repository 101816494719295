import cn from 'classnames';
import React, { Component, JSX } from 'react';

import './Tooltip.css';

class Tooltip extends Component<{
  children: string | JSX.Element | JSX.Element[] | (() => JSX.Element);
  content: string | JSX.Element | JSX.Element[] | (() => JSX.Element);
  style: React.CSSProperties;
  position: 'bottom' | 'top' | 'right' | 'left';
  classes: {
    content?: string;
    wrapper?: string;
  };
  'data-entry-access-mode': string;
}> {
  static defaultProps = {
    content: 'Tooltip content',
    style: {},
    position: 'top',
    classes: {
      content: '',
      wrapper: '',
    },
  };

  state = {
    visible: false,
  };

  show = () => {
    this.setVisibility(true);
  };

  hide = () => {
    this.setVisibility(false);
  };

  setVisibility = visible => {
    this.setState({ visible });
  };

  render() {
    const { visible } = this.state;

    const { children, content, style, position, classes, ...otherProps } = this.props;

    const classesContent = cn('tooltip', position, classes.content ?? '');

    return (
      <span
        {...otherProps}
        className={cn('tooltipWrapper', classes.wrapper ?? '')}
      >
        {visible && (
          <span
            style={style}
            className={classesContent}
          >
            {/* @ts-ignore */}
            {content}
          </span>
        )}
        <span
          className="targetElement"
          onMouseEnter={this.show}
          onMouseLeave={this.hide}
        >
          {/* @ts-ignore */}
          {children}
        </span>
      </span>
    );
  }
}

export default Tooltip;
