import AliasItem from '@/components/AliasItem';
import { Heading, Icon, List } from '@/fui';
import Bookmark from '@/fui/icons/bookmark-empty.svg';
import type { Alias } from '@/lib/map';

export default function AliasSearchResults({
    aliases,
    selectedAlias,
    onSelectAlias,
    onEditAlias
}: {
    aliases: Alias[];
    selectedAlias?: Alias;
    onSelectAlias: (alias: Alias) => void;
    onEditAlias: (alias: Alias) => void;
}) {
    return (
        <section className="AliasSearchResults">
            <Heading
                className="SearchResults__heading"
                type="h4"
                start={
                    <Icon
                        size="small"
                        muted
                    >
                        <Bookmark />
                    </Icon>
                }
                content="Мои места"
            />

            <List className="SearchResults__list">
                {aliases?.map(alias => (
                    <AliasItem
                        key={alias.id}
                        className="SearchResultsItem"
                        alias={alias}
                        selected={alias.id === selectedAlias?.id}
                        interactive
                        onSelect={onSelectAlias}
                        onEdit={onEditAlias}
                    />
                ))}
            </List>
        </section>
    );
}
