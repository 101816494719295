import { useEffect } from 'react';

import { fetchTreeRef } from '@famirytree/crdt-storage';
import { UUID } from '@famirytree/treelib/build/interfaces';

import { useDispatch, useStore } from '@/hooks/store';
import { currentTreeStoreSelector } from '@/selectors';

export const useFetchTree = (treeId?: UUID) => {
  const dispatch = useDispatch();
  const {
    treeId: currentTreeId,
    treeFetchRequestPending,
    treesFetched,
  } = useStore(currentTreeStoreSelector);
  const tree = useStore(state => state?.trees[currentTreeId]);

  useEffect(() => {
    if (treeId && !treesFetched && !treeFetchRequestPending) {
      dispatch(fetchTreeRef({ id: treeId }));
    }
  }, [dispatch, treeId, treeFetchRequestPending, treesFetched]);

  return {
    tree,
    loading: treeFetchRequestPending,
  };
};

export default useFetchTree;
