function emitEvent(name: string, options?: Record<string, string>) {
  (window['FAM'] || function () {})('goal', { event: name, opt: options });
}

function reachGoal(name: string, options?: Record<string, string>) {
  (window['FAM'] || function () {})('goal', { goal: name, opt: options });
}

export function useMetrics() {
  return { emitEvent, reachGoal };
}
