import { v4 as uuid } from 'uuid';

import {
  GenderType,
  MainInfoPageType,
  MainInfoTabType,
  type PersonFullView,
  PersonStatusType,
} from '@famirytree/treelib/interfaces';

import type { PersonData } from '@/data/person';

export function getCreatePersonData(data: PersonData): PersonFullView {
  const id = uuid();
  const pageId = uuid();
  const tabId = uuid();
  const ts = new Date().toISOString();

  const {
    firstName = '',
    lastName = '',
    lastNameAtBirth = '',
    middleName = '',
    birthday = '',
    deathday = '',
    placeOfBirth = '',
    placeOfBirthObject,
    placeOfDeath = '',
    placeOfDeathObject,
    status = PersonStatusType.Unknown,
    gender = GenderType.Unknown,
  } = data;

  return {
    id,
    basicInfo: {
      firstName,
      lastName,
      lastNameAtBirth,
      middleName,
      placeOfBirth,
      placeOfBirthObject,
      birthday,
      deathday,
      placeOfDeath,
      placeOfDeathObject,
      status,
    },
    gender: {
      type: gender,
    },
    avatar: { resource: '' },
    mainInfo: [{ resource: pageId }],
    mainPersonRelationship: null,
    blocks: {},
    documents: [],
    facts: [],
    names: [],
    pages: {
      [pageId]: {
        id: pageId,
        type: MainInfoPageType.Main,
        name: '',
        content: [
          {
            resource: tabId,
            type: MainInfoTabType.Main,
          },
        ],
        created: ts,
        lastEdit: ts,
      },
    },
    tabs: {
      [tabId]: {
        id: tabId,
        type: MainInfoTabType.Main,
        name: 'Главная',
        content: [],
        created: ts,
        lastEdit: ts,
      },
    },
    created: ts,
    lastEdit: ts,
  };
}

export function getUpdatePersonData(person: PersonFullView, data: PersonData): PersonFullView {
  const {
    firstName = person.basicInfo.firstName ?? '',
    lastName = person.basicInfo.lastName ?? '',
    lastNameAtBirth = person.basicInfo.lastNameAtBirth ?? '',
    middleName = person.basicInfo.middleName ?? '',
    gender = person.gender?.type ?? GenderType.Unknown,
    birthday = person.basicInfo.birthday ?? '',
    deathday = person.basicInfo.deathday ?? '',
    status = person.basicInfo?.status ?? PersonStatusType.Unknown,
    placeOfBirth = person.basicInfo.placeOfBirth ?? '',
    placeOfBirthObject = person.basicInfo.placeOfBirthObject ?? null,
    placeOfDeath = person.basicInfo.placeOfDeath ?? '',
    placeOfDeathObject = person.basicInfo.placeOfDeathObject ?? null,
    annotation = person.basicInfo?.annotation ?? '',
    avatar = person.avatar ?? { resource: '' },
  } = data;

  return {
    ...person,
    basicInfo: {
      firstName,
      lastName,
      lastNameAtBirth,
      middleName,
      birthday,
      deathday,
      status,
      placeOfBirth,
      placeOfBirthObject,
      placeOfDeath,
      placeOfDeathObject,
      annotation,
    },
    gender: {
      type: gender,
    },
    avatar,
  };
}
