import './SupportLink.css';

export default function SupportLink({ children }) {
  return (
    <a
      className="fam-support-link"
      target="_blank"
      href="https://t.me/Nataliia_Buchneva"
      rel="noreferrer"
    >
      {children}
    </a>
  );
}
