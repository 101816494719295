import type { Action } from './actions';
import type { State } from './state';

export type Reducer = (state: State, action: Action) => State;

export default function reducer(state: State, action: Action): State {
    switch (action.type) {
        case 'SET_ALIASES':
            return {
                ...state,
                aliases: action.payload
            };
        case 'UNSET_ALIASES':
            return {
                ...state,
                aliases: null
            };
        case 'SET_ALIAS':
            return {
                ...state,
                alias: action.payload
            };
        case 'UNSET_ALIAS':
            return {
                ...state,
                alias: null
            };

        case 'SET_MARKER':
            return {
                ...state,
                marker: action.payload
            };
        case 'UNSET_MARKER':
            return {
                ...state,
                marker: null
            };

        case 'SET_PLACES':
            return {
                ...state,
                places: action.payload
            };
        case 'UNSET_PLACES':
            return {
                ...state,
                places: null
            };
        case 'SET_PLACE':
            return {
                ...state,
                place: action.payload
            };
        case 'UNSET_PLACE':
            return {
                ...state,
                place: null
            };

        case 'SET_ERROR':
            return {
                ...state,
                error: action.payload
            };

        case 'UNSET_ERROR':
            return {
                ...state,
                error: null
            };

        case 'RESET':
            return {
                error: null,
                aliases: null,
                alias: null,
                markers: null,
                marker: null,
                places: null,
                place: null
            };

        default:
            throw new Error(`Store: Unknown action: ${action?.type}`);
    }
}
