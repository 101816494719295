import * as Sentry from '@sentry/react';
import { useEffect } from 'react';

import { useStore } from '@/hooks/store';
import { currentTreeIdSelector, currentUserSelector } from '@/selectors';

export function useSentry() {
  const user = useStore(currentUserSelector);
  const currentTreeId = useStore(currentTreeIdSelector);

  useEffect(() => {
    Sentry.configureScope(scope => {
      if (user?.id) {
        scope.setUser({
          id: user.id,
          passport_id: user.passportUser?.id,
          username: user?.passportUser?.username,
          email: user?.passportUser?.email,
        });
      } else {
        scope.setUser(null);
      }
      return scope;
    });
  }, [user]);

  useEffect(() => {
    if (currentTreeId) {
      Sentry.setTag('tree.id', currentTreeId);
    }
  }, [currentTreeId]);
}
