import { lazy, Suspense, useCallback, useEffect } from 'react';
import { Navigate, Route } from 'react-router-dom';

import { fetchCurrentUser, staleUser, store, useDispatch } from '@famirytree/crdt-storage';

import { SuspenseLoader } from '@/components/common';
import ExternalComponent from '@/components/ExternalComponent';
import { AuthError } from '@/components/ServerError';
import routes from '@/constants/routes';
import { useYaMetricsInit, useYaMetricsInstallationHook } from '@/hooks/metrics';
import { usePerson } from '@/hooks/person';
import { usePersons } from '@/hooks/persons';
import useCreatePromoPerson from '@/hooks/promo/useCreatePromoPerson';
import { useSentry } from '@/hooks/sentry';
import { useStore } from '@/hooks/store';
import { broadcastLogin, onLogin, onLogout } from '@/services/BroadcastChannel';
import { SentryRoutes } from '@/services/sentry';
import { FEATOGGLE_DARKTHEME, FEATOGGLE_INVERT_COLORS } from '@/vars/featoggles';

const BaseTrees = lazy(() => import('./BaseTrees/BaseTrees'));
const TreePersons = lazy(() => import('./TreePersons/TreePersons'));
const TreeMap = lazy(() => import('./TreeMap/TreeMap'));
const CurrentTree = lazy(() => import('./CurrentTree/CurrentTree'));
const PersonPage = lazy(() => import('./PersonPage/PersonPage'));
const DnaList = lazy(() => import('./DnaList/DnaList'));
const Root = lazy(() => import('./Root'));

function GlobalFamiryProductProviderFallback({ children }) {
  return <>{children}</>;
}

const Router = () => {
  useSentry();
  const dispatch = useDispatch();
  const userId = useStore(s => s.currentUser.user?.id);
  // @ts-ignore
  const userIsAuthorized = useStore(s => s.currentUser.user?.authorized);
  const userRequestIsPending = useStore(state => state.currentUser.requestIsPending);
  const userStatus = useStore(state => state.currentUser.status);

  useEffect(() => {
    if (!userId && !userRequestIsPending) {
      dispatch(fetchCurrentUser());
    }
  }, [userId, userRequestIsPending, dispatch]);

  useEffect(() => {
    onLogout(() => {
      console.warn('User was deauthorized because of logout had happened in another tab');
      dispatch(staleUser());
    });
  }, []);

  useEffect(() => {
    if (userStatus === 'READY' && userIsAuthorized) {
      broadcastLogin({ userId });
    }
  }, [userStatus, userIsAuthorized, userId]);

  useEffect(() => {
    onLogin(loginedUserId => {
      if (userStatus === 'READY' && loginedUserId !== userId) {
        console.warn(
          'User was deauthorized because of some other user.id had been detected in another tab',
          {
            loginedUserId,
            userId,
          },
        );
        dispatch(staleUser());
      }
    });
  }, [userStatus, userId, dispatch]);

  useYaMetricsInstallationHook();
  useYaMetricsInit();

  useEffect(() => {
    if (FEATOGGLE_INVERT_COLORS) {
      document.body.className += ' fam-dark-invert';
    }
    if (FEATOGGLE_DARKTHEME) {
      document.body.className += ' fam-dark-theme';
    }
  }, []);

  if (userId) {
    return (
      <SentryRoutes>
        <Route
          path={routes.BASE_TREES}
          element={
            <Suspense fallback={<SuspenseLoader />}>
              <BaseTrees />
            </Suspense>
          }
        />
        <Route
          path={routes.PERSONS}
          element={
            <Suspense fallback={<SuspenseLoader />}>
              <TreePersons />
            </Suspense>
          }
        />
        <Route
          path={routes.DNA}
          element={
            <Suspense fallback={<SuspenseLoader />}>
              <DnaList />
            </Suspense>
          }
        />
        <Route
          path={routes.MAP}
          element={
            <Suspense fallback={<SuspenseLoader />}>
              <TreeMap />
            </Suspense>
          }
        />
        <Route
          path={routes.TREE_BY_PERSON_SHARED}
          element={
            <Suspense fallback={<SuspenseLoader />}>
              <PersonPage />
            </Suspense>
          }
        />
        <Route
          path={routes.TREE_BY_PERSON}
          element={
            <Suspense fallback={<SuspenseLoader />}>
              <PersonPage />
            </Suspense>
          }
        />
        <Route
          path={routes.TREE_SHARED}
          element={
            <Suspense fallback={<SuspenseLoader />}>
              <CurrentTree />
            </Suspense>
          }
        />
        <Route
          key={routes.CURRENT_TREE}
          path={routes.CURRENT_TREE}
          element={
            <Suspense fallback={<SuspenseLoader />}>
              <CurrentTree />
            </Suspense>
          }
        />
        <Route
          path={routes.ROOT_TREE}
          element={
            <Suspense fallback={<SuspenseLoader />}>
              <Root />
            </Suspense>
          }
        />
        <Route
          path={routes.ERROR}
          element={
            <Suspense fallback={<SuspenseLoader />}>
              <AuthError />
            </Suspense>
          }
        />
        <Route
          path="*"
          element={
            <Navigate
              to={routes.ROOT_TREE}
              replace
            />
          }
        />
      </SentryRoutes>
    );
  } else {
    return <SuspenseLoader />;
  }
};

const RouterProductWrapper = () => {
  // used for triggering user update (only in case of userId change)
  useStore(s => s.currentUser.user?.id);

  const addPromoPerson = useCreatePromoPerson();

  const getUserRef = useCallback(() => store.getState().currentUser.user, []);

  const callDrevo = useCallback(
    msg => {
      if (msg === 'usePersons') {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return usePersons();
      }
      if (msg === 'usePerson') {
        return personId => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const person = usePerson(personId);
          return person;
        };
      }
      if (msg === 'addPerson') {
        return async personData => {
          return addPromoPerson(personData);
        };
      }
    },
    [usePersons, usePerson, addPromoPerson],
  );

  const w = window;
  // @ts-ignore
  let GlobalFamiryProductProvider = w.GlobalFamiryProductProvider;
  if (!GlobalFamiryProductProvider) {
    console.warn('Fallback GlobalFamiryProductProvider');
    GlobalFamiryProductProvider = GlobalFamiryProductProviderFallback;
  }
  return (
    <GlobalFamiryProductProvider
      getUserRef={getUserRef}
      callDrevo={callDrevo}
      ExternalComponent={ExternalComponent}
    >
      <Router />
    </GlobalFamiryProductProvider>
  );
};

RouterProductWrapper.displayName = RouterProductWrapper;

export default RouterProductWrapper;
