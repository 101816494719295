import L from 'leaflet';

import LeafletClient, { LeafletOptions } from './LeafletClient';

export type OsmOptions = LeafletOptions;

export default class OsmClient extends LeafletClient {
    async init() {
        await super.init();

        this._container.classList.add('osm');

        this._map.addLayer(
            L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution: '© OpenStreetMap',
                maxZoom: this._options.maxZoom || 18
            })
        );
    }
}
