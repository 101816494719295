import type { AppState } from '@famirytree/crdt-storage';
import { AccessMode } from '@famirytree/treelib/famiry-path';
import type { UUID } from '@famirytree/treelib/interfaces';

import { useStore } from '@/hooks/store';
import { currentTreeIdSelector } from '@/selectors';

import { useIsOwnTree } from './useIsOwnTree';
import { useIsSharedTree } from './useIsSharedTree';
import { Tree, useTree } from './useTree';

export type CurrentTreeState = AppState['currentTree'];

export function useCurrentTreeId(): UUID {
  return useStore(currentTreeIdSelector);
}

export function useCurrentTree(): Tree {
  const treeId = useCurrentTreeId();
  return useTree(treeId) || ({} as Tree);
}

export function useCurrentTreeState(): CurrentTreeState {
  return useStore(state => state.currentTree);
}

export function useCurrentTreeAccessible(): boolean {
  const currentTreeId = useCurrentTreeId();
  const isOwnTree = useIsOwnTree(currentTreeId);
  const grants = useStore(s => s.currentTree.grants);
  const { grant: treeGrantRef } = useStore(
    s =>
      s.trees[currentTreeId]?.__meta__ ?? {
        grant: undefined,
      },
  );
  const treeGrant = (grants ?? []).find(g => g.id === treeGrantRef?.resource);
  const sharedTree = useIsSharedTree(currentTreeId);

  return !!(
    isOwnTree ||
    sharedTree ||
    (treeGrant && treeGrant.rules[0].defaultMode !== AccessMode.NotAvailable)
  );
}

export function useCurrentTreeGrants() {
  return useStore(s => s.currentTree.grants);
}
