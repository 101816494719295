import * as crdt_storage from '@famirytree/crdt-storage';

import ENV from '@/vars/env';

import getGlobals from './getGlobals';

import './setupReact';
import './setupPolyfills';

const glboalObj = getGlobals();
glboalObj.crdt_storage = crdt_storage;
glboalObj.env = ENV;
