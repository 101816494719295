import Map, { MapProps } from 'components/Map';
import { SyntheticEvent } from 'react';

import Modal from '@/components/common/Modal';

import './MapModal.scss';

type MapModalProps = {
  onFocus: (event: SyntheticEvent) => void;
  onBlur: (event: SyntheticEvent) => void;
  onClose: () => void;
} & MapProps;

export const MapModal = ({ onClose, ...props }: MapModalProps) => {
  return (
    <Modal
      isCloseButtonInside={false}
      add_class={'maps-component-modal'}
      content={
        <div className="maps-component-wrapper">
          <Map {...props} />
        </div>
      }
      onClose={() => onClose()}
      disableClickCatching={true}
    />
  );
};
