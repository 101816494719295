import React from 'react';
import ReactDOM from 'react-dom';

import './Modal.css';

class Modal extends React.Component {
  constructor(props) {
    super(props);

    this.modalElement = null;
    this.API = null;

    this.body_scroll = null;
    this.callback = e => {
      e.stopPropagation();
    };
  }

  componentDidMount() {
    this.body_scroll = document.querySelector('body').scrollTop;
    document.querySelector('body').style.position = 'fixed';
    document.querySelector('body').style.width = '100%';
    document.querySelector('body').style.overflow = 'auto';
    document.querySelector('body').style.top = '-' + this.body_scroll;

    this.modalElement = document.createElement('div');
    document.querySelector('body').appendChild(this.modalElement);

    ReactDOM.render(
      React.createElement(
        ModalView,
        {
          registerAPI: API => {
            this.API = API;
          },
          ...this.props,
        },
        null,
      ),
      this.modalElement,
      () => {
        if (typeof this.props.onShow === 'function') {
          this.props.onShow();
        }
      },
    );

    if (!this.props.disableClickCatching) {
      window.addEventListener('mousedown', this.callback, true);
      window.addEventListener('touchstart', this.callback, true);
    }

    // @todo add esc
  }

  componentWillUnmount() {
    this.modalElement.remove();
    document.querySelector('body').style.removeProperty('position');
    document.querySelector('body').style.removeProperty('overflow');
    document.querySelector('body').style.removeProperty('width');
    document.querySelector('body').style.removeProperty('top');
    if (this.body_scroll !== null) {
      document.querySelector('body').scrollTop = this.body_scroll;
    }

    if (!this.props.disableClickCatching) {
      window.removeEventListener('mousedown', this.callback, true);
      window.removeEventListener('touchstart', this.callback, true);
    }
  }

  render() {
    if (this.API) {
      this.API.refresh(this.props);
    }

    return null;
  }
}

class ModalView extends React.Component {
  componentDidMount() {
    this.props.registerAPI({
      refresh: props => {
        this.setState(props);
      },
    });
  }

  componentWillUnmount() {}

  render() {
    const props = this.props;
    let content = (
      <>
        {props.title ? <div className="subheading text-center mb-5">{props.title}</div> : null}

        {props.content}
        {props.children}
      </>
    );
    let style = {
      display: 'none',
    };
    let style_content = {};

    if (props.scroll) {
      style = {
        flexDirection: 'column',
        alignItems: 'center',
      };
      style_content = {
        maxHeight: 'calc(100% - 40px)',
        overflow: 'hidden',
      };
      content = <div style={{ maxHeight: '100%', overflowY: 'scroll' }}>{content}</div>;
    }

    return (
      <div
        className="modal"
        style={style}
        onClick={e => {
          e.stopPropagation();
          props.onClose();
        }}
        data-scrollable="true"
      >
        <div className="modal__content-wrapper">
          {!this.props.isCloseButtonInside && (
            <div
              className="modal__close"
              onClick={() => {
                props.onClose();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1"
                viewBox="0 0 24 24"
              >
                <path
                  style={{
                    fill: 'currentColor',
                    strokeWidth: 0,
                  }}
                  d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z"
                ></path>
              </svg>
            </div>
          )}
          <div
            className={
              (props.custom_content_class ? props.custom_content_class : 'modal__content') +
              (props.add_class ? ` ${props.add_class}` : '')
            }
            style={style_content}
            onClick={e => {
              e.stopPropagation();
            }}
          >
            {this.props.isCloseButtonInside && (
              <button
                className="modal__close-button"
                onClick={e => {
                  e.preventDefault();
                  props.onClose();
                }}
              />
            )}
            {content}
          </div>
        </div>
      </div>
    );
  }
}
ModalView.defaultProps = {
  isCloseButtonInside: true,
};
export default Modal;
