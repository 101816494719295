import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  Routes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import { IS_DEV, REACT_APP_SENTRY_API_DSN, REACT_APP_SENTRY_RELEASE } from '@/vars/env';

Sentry.init({
  release: REACT_APP_SENTRY_RELEASE,
  dsn: REACT_APP_SENTRY_API_DSN,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
  ],
  tracesSampleRate: 1.0,
  environment: IS_DEV ? 'development' : 'production',
});

export { Sentry };

export const captureException = Sentry.captureException;

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
