import { useCallback, useEffect, useRef, useState } from 'react';

import { Button, Icon, Input, Spinner, Text } from '@/fui';
import Cancel from '@/fui/icons/cancel.svg';
import Pin from '@/fui/icons/pin-alt.svg';
import Search from '@/fui/icons/search.svg';
import useSearchHistory from '@/hooks/search-history';

import SearchHistory from '../SearchHistory';

export default function SearchForm({
    query = '',
    label,
    onSubmit,
    onReset,
    ...props
}: {
    query?: string;
    label: string;
    onSubmit: ({ q }: { q: string }) => Promise<void>;
    onReset: () => void;
}) {
    const searchHistory = useSearchHistory();

    const inputRef = useRef<HTMLInputElement>(null);

    const [value, setValue] = useState(query);
    const [isClean, setClean] = useState(true);
    const [isLoading, setLoading] = useState(false);
    const [showHistory, setShowHistory] = useState(false);

    useEffect(() => {
        if (inputRef.current?.value === '') {
            inputRef.current.focus();
        }
    }, []);

    const handleSubmit = useCallback(
        event => {
            event.preventDefault();

            const value = inputRef.current?.value;

            if (!value) return;

            setLoading(true);
            setShowHistory(false);

            onSubmit({ q: value }).finally(() => {
                searchHistory.add(value);
                setLoading(false);
            });
        },
        [searchHistory, onSubmit]
    );

    const handleSearchHistorySelect = useCallback(
        item => {
            setValue(item);
            setClean(false);
            setShowHistory(false);
            setLoading(true);

            onSubmit({ q: item }).finally(() => {
                setLoading(false);
            });
        },
        [searchHistory, onSubmit]
    );

    const handleChange = useCallback(({ value }: { value: string }) => {
        setValue(value);
        setClean(false);
        setShowHistory(true);
    }, []);

    const handleInputClick = useCallback(() => {
        if (inputRef.current?.value === '') {
            setShowHistory(true);
        }
    }, []);

    const handleKeyDown = useCallback(event => {
        if (event.key === 'Escape') {
            setShowHistory(false);
        }
    }, []);

    const handleReset = useCallback(() => {
        setValue('');
        setClean(true);
        setShowHistory(false);
        inputRef.current?.focus();
        onReset();
    }, [onReset]);

    const handleRootClick = useCallback(() => {
        if (inputRef.current?.value === '') {
            inputRef.current.focus();
        }
    }, []);

    return (
        <form
            className="SearchForm"
            onSubmit={handleSubmit}
            onReset={handleReset}
            onClick={handleRootClick}
            {...props}
        >
            <Input
                ref={inputRef}
                className="SearchForm__input"
                type="text"
                value={value}
                placeholder="Введите поисковый запрос"
                size="large"
                start={
                    <Icon
                        color="danger"
                        size="small"
                    >
                        <Pin />
                    </Icon>
                }
                end={
                    <>
                        {value && (
                            <Button
                                type="reset"
                                icon={<Cancel />}
                                size="small"
                                variant="text"
                                disabled={isLoading}
                            />
                        )}

                        {isLoading ? (
                            <Spinner size="smaller" />
                        ) : (
                            <Button
                                type="submit"
                                icon={<Search />}
                                size="small"
                                variant="text"
                                disabled={!value}
                            />
                        )}
                    </>
                }
                data-ms-editor="false"
                onClick={handleInputClick}
                onKeyDown={handleKeyDown}
                onChange={handleChange}
            />

            {label && isClean && (
                <Text
                    type="p3"
                    muted
                >
                    {label}
                </Text>
            )}

            {showHistory && (
                <SearchHistory
                    query={value}
                    items={searchHistory.items}
                    onSelect={handleSearchHistorySelect}
                    onClear={searchHistory.clear}
                />
            )}
        </form>
    );
}
