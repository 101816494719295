export const FEATOGGLE_DARKTHEME = !!JSON.parse(
  localStorage.getItem('FEATOGGLE_DARKTHEME') ?? 'false',
);
export const FEATOGGLE_INVERT_COLORS = !!JSON.parse(
  localStorage.getItem('FEATOGGLE_INVERT_COLORS') ?? 'false',
);

export const FEATOGGLE_ENABLE_EXTRACT_TAB = !!JSON.parse(
  localStorage.getItem('FEATOGGLE_ENABLE_EXTRACT_TAB') ?? 'false',
);
export const FEATOGGLE_ALLOW_FULL_PERSON_SPRITE = !!JSON.parse(
  localStorage.getItem('FEATOGGLE_ALLOW_FULL_PERSON_SPRITE') ?? 'false',
);

export const FEATOGGLE_PERSON_PAGE_DOWNLOAD = !!JSON.parse(
  localStorage.getItem('FEATOGGLE_PERSON_PAGE_DOWNLOAD') ?? 'false',
);
