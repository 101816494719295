import React, { FC, ReactNode, useEffect, useState } from 'react';

import SentryErrorBoundary from '@/components/SentryErrorBoundary';

let componentCounter = 0;
const ComponentsCache = {};

export const ExternalComponent: FC<{
  src: string;
  props?: Record<string, unknown>;
  loaderFallback?: ReactNode | ReactNode[];
  errorFallback?: ReactNode | ReactNode[];
  children?: ReactNode | ReactNode[];
  component?: string;
}> = ({ src, component, props, loaderFallback, errorFallback, children }) => {
  const [componentName] = useState(`___component_${Date.now()}_${componentCounter++}`);
  const [componentLoaded, setComponentLoaded] = useState(!!ComponentsCache[src]);
  const [hasError, setHasError] = useState(false);
  useEffect(() => {
    if (!componentLoaded) {
      const namedSrc = `${src}?component=${componentName}`;
      if (component) {
        ComponentsCache[src] = component;
        setComponentLoaded(true);
        return () => {};
      }
      let script: HTMLScriptElement | null = document.querySelector(
        `head script[src="${namedSrc}"]`,
      );
      if (!script) {
        script = document.createElement('script');
        script.async = true;
        script.src = namedSrc;
        document.head.appendChild(script);
      }
      const onLoad = () => {
        if (window[componentName]) {
          ComponentsCache[src] = window[componentName];
          delete window[componentName];
          setComponentLoaded(true);
        } else {
          onError();
        }
      };
      const onError = () => {
        document.head.removeChild(script!);
        setHasError(true);
      };
      script.addEventListener('load', onLoad);
      script.addEventListener('error', onError);
      return () => {
        script!.removeEventListener('load', onLoad);
        script!.removeEventListener('error', onError);
      };
    } else {
      return () => {};
    }
  }, []);
  if (componentLoaded) {
    return (
      <SentryErrorBoundary>
        {React.createElement(
          ComponentsCache[src],
          {
            ...(props ?? {}),
          },
          children,
        )}
      </SentryErrorBoundary>
    );
  } else {
    if (hasError) {
      return <>{errorFallback ? errorFallback : children}</>;
    } else {
      return <>{loaderFallback ? loaderFallback : children}</>;
    }
  }
};

export default ExternalComponent;
