import { FDate, FEventDate } from '@famirytree/treelib';
import {
  DateObjectFormat,
  DateScope,
  EventDateType,
  PersonEventDatesFormat,
  RangeLocation,
} from '@famirytree/treelib/interfaces';

export function ageFormatter(age: number) {
  const a = age.toString();
  const lastNumber = a.at(-1);
  const penultimateNumber = a.at(-2);
  if (lastNumber === '1' && penultimateNumber !== '1') {
    return `${age} год`;
  }
  if (lastNumber && ['2', '3', '4'].includes(lastNumber) && penultimateNumber !== '1') {
    return `${age} года`;
  }
  if (
    penultimateNumber === '1' ||
    (lastNumber && ['0', '5', '6', '7', '8', '9'].includes(lastNumber))
  ) {
    return `${age} лет`;
  }
  return '';
}

export function dateMigrate(
  state: PersonEventDatesFormat,
  currScope: DateScope,
): Omit<PersonEventDatesFormat, 'old_format' | 'current_date_type'> {
  const prevDate = state[state.current_date_type];
  const currDate = state[currScope];
  const prevScope = state.current_date_type;
  const evDate = new FEventDate();

  if (
    prevScope !== currScope &&
    prevDate &&
    currDate &&
    ![currScope, prevScope].includes(DateScope.free_format)
  ) {
    evDate.parseObject(currDate);
    if (Array.isArray(currDate)) {
      switch (prevScope) {
        case DateScope.simple:
          if (!Array.isArray(prevDate)) {
            evDate.setDate(prevDate.date, RangeLocation.start);
            evDate.setDate(prevDate.date, RangeLocation.end);
          }
          break;
        case DateScope.simple_after:
          if (!Array.isArray(prevDate)) {
            evDate.setDate(prevDate.date, RangeLocation.start);
          }
          break;
        case DateScope.simple_before:
          if (!Array.isArray(prevDate)) {
            evDate.setDate(prevDate.date, RangeLocation.end);
          }
      }
    }

    if (!Array.isArray(currDate)) {
      switch (prevScope) {
        case DateScope.simple:
        case DateScope.simple_after:
        case DateScope.simple_before:
          if (!Array.isArray(prevDate)) {
            evDate.setDate(prevDate.date);
          }
          break;
      }
    }
    return { [evDate.getScope()]: evDate.toJSON() };
  } else {
    return { [currScope]: currDate };
  }
}

export function getFormattedDateValue(dateValue: PersonEventDatesFormat): {
  startDate?: DateObjectFormat;
  endDate?: DateObjectFormat;
  formatted: string;
} {
  const value = dateValue[dateValue.current_date_type];

  if (value) {
    const date = new FEventDate();
    date.parseObject(value);
    const formatted = date.getFormattedDate();

    return Array.isArray(value)
      ? {
          startDate: value[0],
          endDate: value[1],
          formatted,
        }
      : {
          startDate: value,
          endDate: undefined,
          formatted,
        };
  }

  return {
    startDate: undefined,
    endDate: undefined,
    formatted: '',
  };
}

export function createDateTemplate(dates: PersonEventDatesFormat, value: DateScope) {
  const template = {} as Omit<PersonEventDatesFormat, 'old_format' | 'current_date_type'>;
  if (!Object.keys(dates).includes(value)) {
    switch (value) {
      case DateScope.simple: {
        const simpleDate = new FEventDate(new FDate({}), {});
        // @ts-ignore
        template[DateScope.simple] = simpleDate.toJSON();
        break;
      }

      case DateScope.approximate_simple: {
        const approximateDate = new FEventDate(new FDate({}, { isApproximate: true }), {});
        // @ts-ignore
        template[DateScope.approximate_simple] = approximateDate.toJSON();
        break;
      }

      case DateScope.free_format: {
        const freeFormat = new FEventDate(new FDate(''), {});
        // @ts-ignore
        template[DateScope.free_format] = freeFormat.toJSON();
        break;
      }

      case DateScope.range: {
        const range = new FEventDate([new FDate({}), new FDate({})], {});
        // @ts-ignore
        template[DateScope.range] = range.toJSON();
        break;
      }

      case DateScope.interval: {
        const interval = new FEventDate([new FDate({}), new FDate({})], {
          type: EventDateType.single,
        });
        // @ts-ignore
        template[DateScope.interval] = interval.toJSON();
        break;
      }

      case DateScope.simple_after: {
        const simple_after = new FEventDate(new FDate({}), { type: EventDateType.after });
        // @ts-ignore
        template[DateScope.simple_after] = simple_after.toJSON();
        break;
      }

      case DateScope.simple_before: {
        const simple_before = new FEventDate(new FDate({}), { type: EventDateType.before });
        // @ts-ignore
        template[DateScope.simple_before] = simple_before.toJSON();
        break;
      }

      default:
        break;
    }
  }
  return template;
}
