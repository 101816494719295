import Fuse from 'fuse.js';
import { useMemo } from 'react';

export type FullTextSearchResult<T> = Fuse.FuseResult<T>;
export type FullTestSearchResultMatch = Fuse.FuseResultMatch;

export const useFullTextSearch = <T>(
  items: T[],
  searchTerm: string,
  searchKeys: string[],
  minChars = 2,
): FullTextSearchResult<T>[] | null => {
  return useMemo(() => {
    if (searchTerm.length <= minChars) return null;

    return items
      .filter(entry => searchKeys.find(key => entry[key].indexOf(searchTerm) !== -1))
      .map(item => ({
        item,
        refIndex: 0,
        matches: [],
      }));
  }, [searchTerm, minChars, items]);
};
