import { useCallback } from 'react';

import { createPersonRef, updatePersonRef } from '@famirytree/crdt-storage';
import { SceneEventType } from '@famirytree/renderer';
import type { PersonFamiry } from '@famirytree/treelib';
import { PersonDBView as Person, UUID } from '@famirytree/treelib/interfaces';

import type { PersonData } from '@/data/person';
import type { RelationshipData } from '@/data/relationship';
import { getPerson } from '@/helpers/person';
import { useDispatch } from '@/hooks/store';
import { useCurrentTree, useTreeActions } from '@/hooks/tree';
import useRenderer from '@/hooks/useRenderer';
import { isFunction, isString } from '@/utils';

import { useRelationshipsActions } from '../relationships';
import { getCreatePersonData } from './helpers';

export function usePersonsActions() {
  const { arranger, scene } = useRenderer();

  const dispatch = useDispatch();
  const tree = useCurrentTree();
  const { createRelationship } = useRelationshipsActions();
  const { updateTree } = useTreeActions(tree?.id);

  const createPerson = useCallback(
    async (data: PersonData, relationshipData?: RelationshipData) => {
      const personData = getCreatePersonData(data);

      const personRef = await dispatch(createPersonRef(personData)).unwrap();
      const person = personRef.data;

      if (relationshipData) {
        relationshipData.to!.personId = person.id;
        const relationship = await createRelationship(relationshipData);

        await updateTree(tree => ({
          persons: [...tree.persons, { resource: person.id, role: data.role! }],
          relationships: [...tree.relationships, { resource: relationship.id }],
        }));
      }

      return personRef.data;
    },
    [dispatch, createRelationship, updateTree],
  );

  const createPersonThroughScene = useCallback(
    (personData: PersonData, relationshipData?: RelationshipData) => {
      window.dispatchEvent(
        new CustomEvent(SceneEventType.PERSON_CREATE, {
          detail: {
            id: personData.id,
            fromId: relationshipData?.from?.personId,
            relType: relationshipData?.to?.role,
            avatar: personData.avatar,
            gender: personData.gender,
            serialised: {
              firstName: personData.firstName,
              lastName: personData.lastName,
              lastNameAtBirth: personData.lastNameAtBirth,
              middleName: personData.middleName,
              placeOfBirth: personData.placeOfBirth,
              placeOfBirthObject: personData.placeOfBirthObject,
              birthday: personData.birthday,
              deathday: personData.deathday,
              placeOfDeath: personData.placeOfDeath,
              placeOfDeathObject: personData.placeOfDeathObject,
              status: personData.status,
              annotation: (personData.annotation ?? '').substr(0, 140),
            },
          },
        }),
      );
    },
    [],
  );

  const updatePerson = useCallback(
    async (
      personArg: UUID | Person,
      updatePersonArg: Partial<Person> | ((person: Person) => Partial<Person>) = person => person,
    ) => {
      const person = isString(personArg) ? getPerson(personArg) : personArg;
      const updatePerson = isFunction(updatePersonArg) ? updatePersonArg(person) : updatePersonArg;

      if (!person || !updatePerson) return;

      const personRef = await dispatch(
        updatePersonRef({
          ...person,
          ...updatePerson,
        }),
      ).unwrap();

      return personRef.data;
    },
    [dispatch],
  );

  const canDeletePerson = useCallback(
    (personId: UUID) => {
      if (!arranger) return;

      const data = {} as Record<string, boolean>;
      const arrangedPersons = Array.from(arranger.preProcessedPersons) as PersonFamiry[];

      tree.persons.forEach(({ resource }) => {
        data[resource] = true;
      });
      arrangedPersons.forEach(p => {
        data[p.id] = arranger.canPersonBeDeleted(p.id);
      });

      return data[personId];
    },
    [arranger, tree.persons],
  );

  const deletePerson = useCallback(
    async (personId: UUID) => {
      if (!personId || !canDeletePerson(personId)) return;

      // TODO нужен рефакторинг, нужны хуки для манипулирования состоянием древа,
      // вместо использования god-сцены
      scene?.emit(SceneEventType.PERSON_BUTTON_DELETE, { id: personId });

      // TODO: Finish refactoring
      // const state = store.getState();

      // tree.relationships
      //   .map(({ resource }) => state.relationships[resource])
      //   .filter(Boolean)
      //   .filter(({ person1, person2 }) => {
      //     return person1.resource === personId || person2.resource === personId;
      //   })
      //   .forEach(relationship => {
      //     dispatch(deleteRelationshipRef(relationship));
      //   });

      // await dispatch(deletePersonRef({ id: personId }));

      // scene?.emit(SceneEventType.PERSON_DELETED);
    },
    [scene, canDeletePerson],
  );

  return {
    createPerson,
    createPersonThroughScene,
    updatePerson,
    canDeletePerson,
    deletePerson,
  };
}
