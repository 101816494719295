import { useCallback, useEffect, useMemo, useRef } from 'react';
import { v4 as uuid, validate as isUUID } from 'uuid';

import { updatePersonRef, useDispatch } from '@famirytree/crdt-storage';
import {
  MainInfoPage,
  MainInfoTab,
  MainInfoTabReference,
  MainInfoTabType,
  UUID,
} from '@famirytree/treelib/interfaces';

import { usePerson } from '@/hooks/person';

export function usePersonTabs(personId: UUID, pageId?: UUID): Record<UUID, MainInfoTab> {
  const person = usePerson(personId);

  const personTabs = useMemo(() => {
    return person?.tabs || {};
  }, [person]);

  return personTabs;
}

export function usePersonTabsRef(personId: UUID) {
  const personTabs = usePersonTabs(personId);

  const personTabsRef = useRef<Record<UUID, MainInfoTab>>(personTabs);

  useEffect(() => {
    personTabsRef.current = personTabs;
  }, [personTabs]);

  return personTabsRef;
}

export function usePersonTabsList(personId: UUID, pageId: UUID): MainInfoTab[] {
  const person = usePerson(personId);
  const personTabs = usePersonTabs(personId);

  return useMemo(() => {
    return person.pages[pageId]?.content?.map(ref => personTabs[ref.resource]) ?? [];
  }, [person, pageId, personTabs]);
}

export function usePersonTabsByTypes(
  personId: UUID,
  pageId?: UUID,
): Record<MainInfoTabType, MainInfoTab> {
  const personTabs = usePersonTabs(personId, pageId);

  return useMemo(() => {
    return [...Object.values(personTabs)].reduce(
      (result, tab) => {
        result[tab.type] = tab;
        return result;
      },
      {} as Record<MainInfoTabType, MainInfoTab>,
    );
  }, [personTabs]);
}

export function usePersonTabsWithActions(personId: UUID, pageId: UUID) {
  const dispatch = useDispatch();
  const person = usePerson(personId);
  const personTabs = usePersonTabs(personId, pageId);

  const createTab = useCallback(
    (data: Partial<MainInfoTab>) => {
      const newTabId = uuid();
      const ts = new Date().toISOString();

      const tab: MainInfoTab = {
        id: newTabId,
        type: MainInfoTabType.Custom,
        name: '',
        content: [],
        created: ts,
        lastEdit: ts,
        ...data,
      };

      const content: MainInfoTabReference[] = [
        ...person.pages[pageId].content,
        {
          resource: newTabId,
          type: data.type ?? MainInfoTabType.Custom,
        },
      ];

      const updatedPerson = {
        ...person,
        pages: {
          ...person.pages,
          [pageId]: {
            ...person.pages[pageId],
            content,
          },
        },
        tabs: {
          ...person.tabs,
          [newTabId]: tab,
        },
      };

      dispatch(updatePersonRef(updatedPerson));

      return tab;
    },
    [person, pageId, dispatch],
  );

  const deleteTab = useCallback(
    (tabId: UUID) => {
      const deletedTab = person.tabs[tabId];

      if (!deletedTab) return;

      const content = person.pages[pageId].content.filter(ref => ref.resource !== tabId);

      const updatedPerson = {
        ...person,
        pages: {
          ...person.pages,
          [pageId]: {
            ...person.pages[pageId],
            content,
          },
        } as Record<string, MainInfoPage>,
      };

      delete person.tabs[tabId];

      dispatch(updatePersonRef(updatedPerson));

      return deletedTab;
    },
    [person, pageId, dispatch],
  );

  return {
    personTabs,
    createTab,
    deleteTab,
  };
}

export function usePersonTab(personId: UUID, arg: UUID | MainInfoTabType): MainInfoTab | undefined {
  const personTabs = usePersonTabs(personId);
  const personTabsByType = usePersonTabsByTypes(personId);
  const tab = isUUID(arg) ? personTabs[arg] : personTabsByType[arg as MainInfoTabType];

  return tab;
}
