import { EntityMeta } from '@famirytree/crdt-storage';
import { TreeFullView, UUID } from '@famirytree/treelib/interfaces';

import { useStore } from '@/hooks/store';

export type Tree = TreeFullView & EntityMeta;

export function useTree(treeId: UUID = ''): Tree | undefined {
  const tree = useStore(
    state => state.trees[treeId],
    (a, b) =>
      a.__meta__.hash === b.__meta__.hash &&
      a.__meta__.empty === b.__meta__.empty &&
      Object.keys(a) === Object.keys(b),
  );

  return tree?.__meta__.empty ? undefined : tree;
}

export { useTree as default };
