export const MONTHS = [
  { value: 1, label: 'Январь' },
  { value: 2, label: 'Февраль' },
  { value: 3, label: 'Март' },
  { value: 4, label: 'Апрель' },
  { value: 5, label: 'Май' },
  { value: 6, label: 'Июнь' },
  { value: 7, label: 'Июль' },
  { value: 8, label: 'Август' },
  { value: 9, label: 'Сентябрь' },
  { value: 10, label: 'Октябрь' },
  { value: 11, label: 'Ноябрь' },
  { value: 12, label: 'Декабрь' },
];
export const DAYS = Array(31)
  .fill(1)
  .map((item, index) => ({ value: item + index, label: item + index }));

export const DATE_OPTIONS = [
  { value: 'simple', label: 'Дата', description: 'Простая дата события' },
  {
    value: 'simple_before',
    label: 'До даты',
    description: 'Событие произошло до обозначенной даты',
  },
  {
    value: 'simple_after',
    label: 'После даты',
    description: 'Событие произошло после обозначенной даты',
  },
  {
    value: 'free_format',
    label: 'Произвольная дата',
    description:
      'Опишите в какой период произошло событие, привязав к общеизвестным историческим событиям ',
  },
  {
    value: 'interval',
    label: 'Между',
    description:
      'Событие произошло между обозначенными датами, показывает примерный временной отрезок',
  },
];

export const DATE_ACCURACY = [
  { value: false, label: 'Точно' },
  { value: true, label: 'Неточно' },
];

export const DATE_SCOPE = {
  simple: 'simple',
  approximateSimple: 'approximate_simple',
  range: 'range',
  interval: 'interval',
  freeFormat: 'free_format',
};

export const DATE_MOCK = {
  free_format: {
    date: 'Накануне Рождества',
    type: 'date',
    dateType: 'single',
    isApproximate: false,
    isFreeFormat: true,
    scope: 'free_format',
  },
  simple: {
    date: {
      day: 5,
      day_IsApproximate: false,
      hour: null,
      hour_IsApproximate: false,
      minute: null,
      minute_IsApproximate: false,
      month: 7,
      month_IsApproximate: false,
      second: null,
      second_IsApproximate: false,
      year: 1994,
      year_IsApproximate: false,
    },
    type: 'date',
    dateType: 'single',
    isApproximate: false,
    isFreeFormat: false,
    scope: 'simple',
  },
  interval: [
    {
      date: {
        day: 4,
        day_IsApproximate: false,
        hour: null,
        hour_IsApproximate: false,
        minute: null,
        minute_IsApproximate: false,
        month: 2,
        month_IsApproximate: false,
        second: null,
        second_IsApproximate: false,
        year: 1974,
        year_IsApproximate: false,
      },
      type: 'date',
      dateType: 'single',
      isApproximate: true,
      isFreeFormat: false,
      scope: 'interval',
    },
    {
      date: {
        day: 5,
        day_IsApproximate: false,
        hour: null,
        hour_IsApproximate: false,
        minute: null,
        minute_IsApproximate: false,
        month: 9,
        month_IsApproximate: false,
        second: null,
        second_IsApproximate: false,
        year: 1994,
        year_IsApproximate: false,
      },
      type: 'date',
      dateType: 'single',
      isApproximate: false,
      isFreeFormat: false,
      scope: 'interval',
    },
  ],
  currentDateType: 'interval',
};

export const DATE_TYPE = {
  birthday: 'birthday',
  deathday: 'deathday',
};
