import {
  type ChangeEvent,
  type SyntheticEvent,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import type { FlexPlace } from '@famirytree/treelib/interfaces';
import type { Alias } from '@famirytree/treelib/interfaces/geo';
import { Button, Input, InputProps } from '@famiryui/components';

import MapModal from '@/components/MapModal';
import { useUser } from '@/hooks/user';

type PlaceInputProps = Omit<InputProps, 'value' | 'onChange'> & {
  name?: string;
  value?: FlexPlace;
  onChange: (
    data: { name?: string; value?: FlexPlace },
    event?: ChangeEvent<HTMLInputElement>,
  ) => void;
};

export default function PlaceInput({ name, value, disabled, onChange, ...props }: PlaceInputProps) {
  const user = useUser();

  const inputRef = useRef<HTMLInputElement>(null);
  const pageScrollY = useRef<number>();

  const [isPlaceModalOpen, setPlaceModalOpen] = useState(false);

  useLayoutEffect(() => {
    if (isPlaceModalOpen === false && pageScrollY.current) {
      window.scrollTo(0, pageScrollY.current || 0);
      pageScrollY.current = undefined;
    }
  }, [isPlaceModalOpen]);

  const emulateInputBlur = useCallback(() => {
    inputRef?.current?.focus();
    setTimeout(() => {
      inputRef?.current?.blur();
    }, 0);
  }, []);

  const handleInputClick = useCallback((event: SyntheticEvent) => {
    preventDefault(event);
    setPlaceModalOpen(true);
    pageScrollY.current = window.scrollY;
  }, []);

  const handleClearIconClick = useCallback(
    (event: SyntheticEvent) => {
      preventDefault(event);
      onChange({
        name,
        value: undefined,
      });
      emulateInputBlur();
    },
    [name, onChange],
  );

  const handleAliasSaved = useCallback(
    (alias: Alias) => {
      emulateInputBlur();
      setPlaceModalOpen(false);
      onChange({
        name,
        value: alias,
      });
    },
    [name, onChange],
  );

  const handleModalClose = useCallback(() => {
    setPlaceModalOpen(false);
  }, []);

  const placeValue = (typeof value === 'object' ? value?.name : value) || '';

  return (
    <div className="PlaceInput">
      <Input
        ref={inputRef}
        value={placeValue}
        end={
          value && (
            <Button
              icon="clear"
              size="small"
              disabled={disabled}
              onClick={handleClearIconClick}
            />
          )
        }
        disabled={disabled}
        autoComplete="off"
        readOnly
        onClick={handleInputClick}
        {...props}
      />

      {isPlaceModalOpen && (
        <MapModal
          alias={typeof value === 'object' ? value : undefined}
          query={typeof value === 'string' ? placeValue : undefined}
          userPassportId={user?.id}
          onFocus={preventDefault}
          onBlur={preventDefault}
          onClose={handleModalClose}
          onAliasSaved={handleAliasSaved}
        />
      )}
    </div>
  );
}

function preventDefault(event: SyntheticEvent) {
  event.preventDefault();
  event.stopPropagation();
}
