import { createContext } from 'react';
import { NIL } from 'uuid';

import { EntityTypeName, UUID } from '@famirytree/treelib/interfaces';

import { noop } from '@/utils';

export const IsNotAuthAndSharedContext = createContext(false);
export const OnOpenAuthModalContext = createContext<(...args: any[]) => (...args: any[]) => void>(
  () => () => undefined,
);
export const OnOpenPersonEditModalContext = createContext(noop);
export const OnOpenRelationModalContext = createContext(noop);
export const OnOpenRelationsModalContext = createContext<(id: string) => void>(noop);

export interface PageEntityContextInterface {
  entityType: EntityTypeName;
  entityId: UUID;
  treeId: UUID;
}

export const PageEntityContext = createContext<PageEntityContextInterface>({
  entityType: EntityTypeName.u_tree,
  entityId: NIL,
  treeId: NIL,
});
