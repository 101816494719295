import { ImageFrameInfo } from '@famirytree/treelib/build/interfaces';

export const getCropperUrlParameters = (
  parameters: ImageFrameInfo | undefined = undefined,
  width: number,
  height: number,
) => {
  const parts: string[] = [];

  if (parameters?.rotate) {
    parts.push(
      `rot:${parameters.rotate > 0 ? parameters.rotate : 360 - Math.abs(parameters.rotate)}`,
    );
  }
  if (parameters?.x !== undefined && parameters?.y !== undefined) {
    parts.push(`g:nowe:${parameters.x || 0}:${parameters.y || 0}`);
  }
  if (parameters?.width && parameters?.height) {
    parts.push(`c:${parameters.width}:${parameters.height}`);
  }

  parts.push(`rs:fill:${width}:${height}:1`);

  return parts.join('/');
};
