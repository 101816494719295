import { GenderType, type UUID } from '@famirytree/treelib/interfaces';

import { getCropperUrlParameters } from '@/helpers/cropper';
import { usePerson } from '@/hooks/person';
import { useStore } from '@/hooks/store';
import { useCurrentTreeId } from '@/hooks/tree';

export function usePersonAvatar(
  personId: UUID,
  {
    size = 70,
    width = size,
    height = size,
    mainCropFrame = false,
  }: { size?: number; width?: number; height?: number; mainCropFrame?: boolean } = {},
) {
  const currentTreeId = useCurrentTreeId();
  const person = usePerson(personId);
  const avatar = useStore(s => s.persons[personId]?.avatar);
  const avatarDocument = useStore(s => s.documents[avatar?.resource ?? '']);

  const hasError = !!avatarDocument?.importState?.error;
  const isLoading = !!(
    avatarDocument?.importState?.src &&
    avatarDocument?.importState?.complete === false &&
    !hasError
  );
  const isLoaded = avatar && !isLoading && !hasError;
  const avatarGender = person?.gender?.type ?? GenderType.Unknown;
  const avatarUrl =
    avatar?.resource && isLoaded
      ? `/tree/v1/media/resizer/${getCropperUrlParameters(
          mainCropFrame ? avatar?.mainFrame : avatar?.squareFrame,
          width,
          height,
        )}/doc/${currentTreeId}/${avatar.resource}`
      : undefined;

  return {
    avatar: avatar ?? { resource: '' },
    avatarDocument,
    avatarGender,
    avatarUrl,
    hasError,
    isLoading,
    isLoaded,
  };
}
