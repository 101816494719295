import * as React from 'react';

export const useClickOutside = (_elements, handler) => {
  const listener = React.useCallback(
    e => {
      const elements = _elements instanceof Function ? _elements() : _elements;
      if (
        elements.every(element => {
          return element.current && !element.current.contains(e.target);
        })
      ) {
        handler(e);
      }
    },
    [handler, _elements],
  );

  React.useEffect(() => {
    document.addEventListener('mousedown', listener, true);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener, true);
      document.removeEventListener('touchstart', listener);
    };
  }, [listener]);
};
