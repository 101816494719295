import cn from 'classnames';
import type { ReactNode } from 'react';

import './Button.css';

const Button = ({
  disabled = false,
  className,
  children,
  asLink = false,
  dataFor = '',
  onClick = () => {},
  ...otherProps
}: {
  asLink?: boolean;
  disabled?: boolean;
  dataFor?: string;
  onClick?: () => void;
  id?: string;
  className?: string;
  children?: ReactNode | ReactNode[];
}) => {
  return (
    <button
      {...otherProps}
      data-for={dataFor}
      className={cn('buttonUi', className, { buttonUiDisabled: disabled, buttonAsLink: asLink })}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
