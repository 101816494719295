import { Alias, Marker, Place } from '@/lib/map';

export type State = {
    aliases: Alias[] | null;
    alias: Alias | null;
    error: any | null;
    markers: Marker[] | null;
    marker: Marker | null;
    places: Place[] | null;
    place: Place | null;
};

export default {
    aliases: null,
    alias: null,
    error: null,
    markers: null,
    marker: null,
    places: null,
    place: null
} as State;
