import { useSelector } from 'react-redux';

import { AppState } from '@famirytree/crdt-storage';

type Selector<T = any> = (state: AppState) => T;

const defaultSelector: Selector = (state: AppState): AppState => state;

export default useStore;

export function useStore<T = AppState>(
  selector: Selector<T> = defaultSelector,
  equalityFn?: (left: T, right: T) => boolean,
): T {
  const state = useSelector<AppState, T>(selector, equalityFn);

  return state;
}
