import { useCallback, useRef } from 'react';

import { Button, Heading, List, ListItem } from '@/fui';

export default function SearchHistory({
    query,
    items,
    onSelect,
    onClear
}: {
    query?: string;
    items: string[];
    onSelect: (item: string) => void;
    onClear: () => void;
}) {
    const rootRef = useRef<HTMLDivElement>(null);

    const handleItemClick = useCallback(
        event => {
            onSelect(event.currentTarget.textContent);
        },
        [onSelect]
    );

    const filteredItems = query
        ? items?.filter(item =>
              item.toLowerCase().includes(query.toLowerCase())
          )
        : items;

    if (filteredItems?.length === 0) return null;

    return (
        <div
            ref={rootRef}
            className="SearchHistory"
        >
            <div className="SearchHistory__header">
                <Heading
                    type="h5"
                    muted
                >
                    История поиска
                </Heading>

                <Button
                    content="Очистить"
                    variant="text"
                    size="small"
                    onClick={onClear}
                />
            </div>

            <List>
                {filteredItems.map(item => (
                    <ListItem
                        key={item}
                        content={item}
                        interactive
                        onClick={handleItemClick}
                    />
                ))}
            </List>
        </div>
    );
}
