import { useCallback, useEffect, useMemo, useState } from 'react';

export default function useSearchHistory() {
    const [items, setItems] = useState<string[]>(() => {
        const items = localStorage.getItem('search-history');
        return items ? JSON.parse(items) : [];
    });

    useEffect(() => {
        localStorage.setItem('search-history', JSON.stringify(items));
    }, [items]);

    const addItem = useCallback(item => {
        setItems(items => (items.includes(item) ? items : items.concat(item)));
    }, []);

    const clear = useCallback(() => {
        localStorage.setItem('search-history', '');
        setItems([]);
    }, []);

    return useMemo(
        () => ({
            items,
            add: addItem,
            clear
        }),
        [items]
    );
}
