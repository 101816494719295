import { REACT_APP_BLOCKED_TREE } from '@/vars/env';

export default useIsBlockedTree;

export function useIsBlockedTree(treeId: string) {
  if (REACT_APP_BLOCKED_TREE.length > 0 && REACT_APP_BLOCKED_TREE.includes(treeId)) {
    console.log(`>>>> current tree ${treeId}`);
  }

  return REACT_APP_BLOCKED_TREE.length > 0 && REACT_APP_BLOCKED_TREE.includes(treeId);
}
