import { Spinner, SpinnerProps } from '@famiryui/components';

import { cn } from '@/utils';

import styles from './Loader.module.scss';

export default function Loader({
  className,
  size,
  ...props
}: {
  className?: string;
  size?: SpinnerProps['size'];
}) {
  return (
    <div
      className={cn(className, styles.root)}
      {...props}
    >
      <Spinner size={size} />
    </div>
  );
}
