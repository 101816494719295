import PlaceItem from '@/components/PlaceItem';
import { Card, Heading, Icon, List, Text } from '@/fui';
import Map from '@/fui/icons/map.svg';
import type { Place } from '@/lib/map';

export default function PlaceSearchResults({
    places,
    selectedPlace,
    onSelectPlace,
    onEditPlace
}: {
    places: Place[];
    selectedPlace?: Place;
    onSelectPlace: (place: Place) => void;
    onEditPlace: (place: Place) => void;
}) {
    return (
        <section className="PlaceSearchResults">
            <Heading
                className="SearchResults__heading"
                type="h4"
                start={
                    <Icon
                        size="small"
                        muted
                    >
                        <Map />
                    </Icon>
                }
                content="Поиск по карте"
            />

            {places?.length ? (
                <List className="SearchResults__list">
                    {places.map(place => (
                        <PlaceItem
                            key={place.placeId}
                            className="SearchResultsItem"
                            place={place}
                            selected={place.placeId === selectedPlace?.placeId}
                            interactive
                            onSelect={onSelectPlace}
                            onEdit={onEditPlace}
                        />
                    ))}
                </List>
            ) : (
                <Card>
                    <Text weight="bold">Упс, на карте ничего не найдено.</Text>

                    <Text>
                        Попробуйте изменить запрос или{' '}
                        <Text
                            as="span"
                            color="primary"
                        >
                            добавьте объект на карте
                        </Text>
                        .
                    </Text>
                </Card>
            )}
        </section>
    );
}
