import cn from 'classnames';

import { FEventDate } from '@famirytree/treelib';
import { PersonEventDatesFormat } from '@famirytree/treelib/interfaces';
import { ReactComponent as NavArrow } from '@famiryui/icons/svg/nav-arrow-down.svg';
import { ReactComponent as PlusIcon } from '@famiryui/icons/svg/plus.svg';

import { Combobox, CustomSelect, Input } from '@/components/common';
import { DATE_OPTIONS, DATE_SCOPE, DAYS, MONTHS } from '@/constants/dates';

import PlaceInput from './PlaceInput';

import './DateAndPlace.css';

const getFormatedDateValue = (dateValue: PersonEventDatesFormat) => {
  if (typeof dateValue[dateValue.current_date_type] !== 'undefined') {
    const date = new FEventDate();
    // @ts-ignore
    date.parseObject(dateValue[dateValue.current_date_type]);
    const formated = date.getFormattedDate();
    return Array.isArray(dateValue[dateValue.current_date_type])
      ? {
          // @ts-ignore
          startDate: dateValue[dateValue.current_date_type][0],
          // @ts-ignore
          endDate: dateValue[dateValue.current_date_type][1],
          formated,
        }
      : {
          startDate: dateValue[dateValue.current_date_type],
          endDate: {},
          formated,
        };
  }
  return {
    startDate: {},
    endDate: {},
    formated: '',
  };
};

const DateAndPlace = ({
  formLabel,
  placeFormLabel,
  onFormOpen,
  formActive,
  placeInputName,
  extendedPlaceInputName,
  dataDateName,
  disabled,
  dateValue,
  placeValue,
  extendedPlaceValue,
  onChangeDateType,
  onUpdateDateValue,
  onChangeValue,
  expandAccessMode,
}) => {
  const formatedDateValue = getFormatedDateValue(dateValue); //, [dateValue]);

  const empty = placeValue === '' && formatedDateValue.formated === '';

  return (
    <>
      <div className="fam-form__date_and_place__date_play_wrapper">
        <h3
          className="fam-form__date_and_place__title"
          data-empty={empty}
          onClick={onFormOpen}
        >
          {formLabel}

          {!formActive && (
            <span className="fam-form__date_and_place__short_info">
              {formatedDateValue.formated.replace(/(^\s+)|(\s+$)/g, '')}
              {formatedDateValue.formated !== '' && placeValue !== '' && (
                <>
                  &nbsp;
                  <span />
                  &nbsp;
                </>
              )}
              {placeValue || ''}
            </span>
          )}

          <button
            className="fam-form__date_and_place__title_button"
            type="button"
            data-active={formActive}
          >
            {empty && !formActive ? <PlusIcon /> : <NavArrow />}
          </button>
        </h3>

        <div
          className="fam-form__date_and_place"
          data-active={formActive}
          data-date-type={dateValue.current_date_type}
        >
          <div>
            <CustomSelect
              options={DATE_OPTIONS}
              selected={dateValue.current_date_type}
              extraData={{
                'data-date': dataDateName,
              }}
              classNames={{
                wrapper: cn('popup__select_date_type', {
                  'popup_select_date-type_range':
                    dateValue.current_date_type === DATE_SCOPE.interval,
                }),
              }}
              onSelect={onChangeDateType}
              disabled={disabled}
            />

            {typeof dateValue[dateValue.current_date_type] !== 'undefined' && (
              <div className="popup__selectors_group">
                {!formatedDateValue.startDate?.isFreeFormat ? (
                  <>
                    <Combobox
                      label="День"
                      selected={formatedDateValue.startDate?.date?.day}
                      extraData={{
                        date: dataDateName,
                        range: 'start',
                      }}
                      name="day"
                      options={DAYS}
                      defaultValue={'—'}
                      onSelect={onUpdateDateValue}
                      withEmptyOptions
                      disabled={disabled}
                    />

                    <Combobox
                      label="Месяц"
                      selected={formatedDateValue.startDate?.date?.month}
                      extraData={{
                        date: dataDateName,
                        range: 'start',
                      }}
                      name="month"
                      options={MONTHS}
                      defaultValue={'—'}
                      onSelect={onUpdateDateValue}
                      withEmptyOptions
                      disabled={disabled}
                    />

                    <Input
                      label="Год"
                      type="number"
                      name="year"
                      data-range="start"
                      data-date={dataDateName}
                      id="person-inaccurate-month-input"
                      autoComplete="off"
                      maxLength={4}
                      max={9999}
                      value={formatedDateValue.startDate?.date?.year || ''}
                      onChange={ev => {
                        ev.stopPropagation();
                        onUpdateDateValue({
                          name: 'year',
                          value: ev.target.value,
                          dataset: {
                            range: 'start',
                            date: dataDateName,
                          },
                        });
                      }}
                      disabled={disabled}
                      withDash
                      isFilled
                    />

                    {formatedDateValue.endDate?.date && (
                      <>
                        <Combobox
                          label="День"
                          selected={formatedDateValue.endDate?.date?.day}
                          extraData={{
                            date: dataDateName,
                            range: 'end',
                          }}
                          name="day"
                          options={DAYS}
                          onSelect={onUpdateDateValue}
                          withEmptyOptions
                          disabled={disabled}
                        />

                        <Combobox
                          label="Месяц"
                          selected={formatedDateValue.endDate?.date?.month}
                          extraData={{
                            date: dataDateName,
                            range: 'end',
                          }}
                          name="month"
                          options={MONTHS}
                          withEmptyOptions
                          onSelect={onUpdateDateValue}
                        />

                        <Input
                          label="Год"
                          type="number"
                          name="year"
                          autoComplete="off"
                          data-range="end"
                          data-date={dataDateName}
                          maxLength={4}
                          max={9999}
                          value={formatedDateValue.endDate?.date?.year || ''}
                          onChange={ev => {
                            ev.stopPropagation();
                            onUpdateDateValue({
                              name: 'year',
                              value: ev.target.value,
                              dataset: {
                                date: dataDateName,
                                range: 'end',
                              },
                            });
                          }}
                          disabled={disabled}
                          withDash
                          isFilled
                        />
                      </>
                    )}
                  </>
                ) : (
                  <Input
                    label="Дата в свободном формате"
                    type="text"
                    name="free"
                    data-date={dataDateName}
                    autoComplete="off"
                    value={formatedDateValue.startDate.date}
                    onChange={ev => {
                      ev.stopPropagation();
                      onUpdateDateValue({
                        name: 'free',
                        value: ev.target.value,
                        dataset: {
                          date: dataDateName,
                        },
                      });
                    }}
                    disabled={disabled}
                    classNames={{
                      wrapper: 'popup__input_free-format',
                    }}
                  />
                )}
              </div>
            )}
          </div>

          <PlaceInput
            placeFormLabel={placeFormLabel}
            disabled={disabled}
            placeInputName={placeInputName}
            extendedPlaceInputName={extendedPlaceInputName}
            placeValue={placeValue}
            extendedPlaceValue={extendedPlaceValue}
            onChangeValue={onChangeValue}
          />
        </div>
      </div>
    </>
  );
};

export default DateAndPlace;
