import { useStore } from '@/hooks/store';
import { useCurrentTree } from '@/hooks/tree';

export default function useRelationships() {
  const tree = useCurrentTree();

  const relationships = useStore(state =>
    tree?.relationships
      .map(({ resource }) => (state?.relationships ?? {})[resource])
      .filter(Boolean),
  );

  return relationships;
}
