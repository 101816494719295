import { type Dispatch, useMemo, useReducer } from 'react';

import {
    type Action,
    actions,
    reducer,
    type State,
    state as defaultState,
    utils
} from '../store';

type Actions = typeof actions;

export { type State };

export type InitialState = Partial<State>;

export type Store = {
    state: State;
    dispatch: Dispatch<Action>;
    actions: Actions;
};

export default function useStore(initialState: Partial<State>) {
    const [state, dispatch] = useReducer(reducer, {
        ...defaultState,
        ...initialState
    });
    const boundActions = useMemo<Actions>(
        () => utils.bindActions<Actions>(actions, dispatch),
        []
    );

    return { state, dispatch, actions: boundActions };
}
