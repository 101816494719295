import { useRef } from 'react';

export default function useAuthorizationModal() {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const onIframeLoaded = () => {
    let registered = false;

    function onMessage(event) {
      const iframe = iframeRef.current;
      const data = event.data;

      if (!iframe || !data || typeof data !== 'object') return;

      if (data.type === 'registered') {
        registered = true;
      }

      if (data.type === 'resize') {
        iframe.style.height = data.height + 'px';
        const modalWindowPaddings = 40;
        if (data.available_max_widths) {
          for (const i in data.available_max_widths) {
            let modal_width = 0;
            if (window.innerWidth > data.available_max_widths[i] + modalWindowPaddings) {
              modal_width = Math.max(data.available_max_widths[i], modal_width);
            }
            if (modal_width) {
              iframe.style.maxWidth = modal_width + 'px';
            }
          }
        }
      }
    }

    if (iframeRef.current) {
      window.addEventListener('message', onMessage);
    }

    const interval = window.setInterval(() => {
      if (registered) {
        clearInterval(interval);
        return;
      }
      iframeRef.current?.contentWindow?.postMessage('register', '*');
    }, 100);

    return () => {
      window.removeEventListener('message', onMessage);
      clearInterval(interval);
    };
  };

  return { iframeRef, onIframeLoaded };
}
