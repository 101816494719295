import L from 'leaflet';

import LeafletClient, { LeafletOptions } from './LeafletClient';

import 'mapbox-gl-leaflet';
import 'mapbox-gl/dist/mapbox-gl.css';

export type MapboxOptions = LeafletOptions & {
    project: string;
    accessToken: string;
    styleId: string;
    vector?: boolean;
};

export default class MapboxClient extends LeafletClient {
    protected _options: MapboxOptions = {} as MapboxOptions;

    constructor(
        root: HTMLElement,
        options: MapboxOptions = {} as MapboxOptions
    ) {
        super(root, options);

        this._options = options;
    }

    async init() {
        await super.init();

        this._container.classList.add('mapbox');

        const {
            accessToken,
            project,
            styleId,
            projection = 'mercator',
            maxZoom = 18,
            vector = true
        } = this._options;

        if (vector) {
            this._map.addLayer(
                L.mapboxGL({
                    accessToken,
                    style: `mapbox://styles/${project}/${styleId}`,
                    // @ts-ignore 'projection' does not exist in type 'MapboxGLOptions'
                    projection
                })
            );
        } else {
            this._map.addLayer(
                L.tileLayer(
                    `https://api.mapbox.com/styles/v1/${project}/${styleId}/tiles/256/{z}/{x}/{y}?access_token=${accessToken}`,
                    {
                        maxZoom
                    }
                )
            );
        }
    }
}
