import { useMemo } from 'react';

import { PersonFilter, PersonFilterRule, personFilterSet } from '@famirytree/renderer';
import { calculatePersonAge, PersonFamiry } from '@famirytree/treelib';
import { GenderType } from '@famirytree/treelib/interfaces';

import routes from '@/constants/routes';
import { useArrangerReady } from '@/hooks/useArrangerReady';
import useRenderer from '@/hooks/useRenderer';
import { average, generateUri, percentage, plural } from '@/utils';

import { useCurrentTreeId } from './useCurrentTree';

export default useTreeStats;

export type TreeStats = {
  personsCount: number;
  personsCountPlural: string;
  malesPercent: number;
  femalesPercent: number;
  surnamesCount: number;
  surnamesCountPlural: string;
  averageMalesAge: number;
  averageMalesAgePlural: string;
  averageFemalesAge: number;
  averageFemalesAgePlural: string;
  placesCount: number;
  placesCountPlural: string;
  minYear: number | null;
  generations: number;
  generationsPlural: string;
  date: string;
  link: string;
};

export function useTreeStats(): TreeStats {
  const treeId = useCurrentTreeId();
  const { arranger } = useRenderer();
  const { isArrangerReady } = useArrangerReady();

  const arrangerPersons: PersonFamiry[] = (
    Array.from(arranger?.preProcessedPersons || []) as PersonFamiry[]
  ).filter(
    personFilterSet
      .get(arranger?.personFilterRule || PersonFilterRule.ALL_PERSONS)
      ?.bind(arranger!) as PersonFilter,
  );

  const persons = arrangerPersons.map(person => person.serialized);

  const personsCount = persons.length;
  const surnames = persons.map(person => person.basicInfo.lastName);
  const surnamesAtBirth = persons.map(person => person.basicInfo.lastNameAtBirth);
  const surnamesCount = [...surnames, ...surnamesAtBirth]
    .filter((x, i, a) => a.indexOf(x) === i)
    .filter(Boolean).length;

  const birthPlaces = persons.map(person =>
    // @ts-ignore
    person.basicInfo.placeOfBirthObject && person.basicInfo.placeOfBirthObject.place
      ? // @ts-ignore
        person.basicInfo.placeOfBirthObject.place.displayName
      : person.basicInfo.placeOfBirth,
  );

  const deathPlaces = persons.map(person =>
    // @ts-ignore
    person.basicInfo.placeOfDeathObject && person.basicInfo.placeOfDeathObject.place
      ? // @ts-ignore
        person.basicInfo.placeOfDeathObject.place.displayName
      : person.basicInfo.placeOfDeath,
  );

  const placesCount = [...birthPlaces, ...deathPlaces]
    .filter((x, i, a) => a.indexOf(x) === i)
    .filter(Boolean).length;

  const birthYears: number[] = persons
    // @ts-ignore
    .map(person => person.basicInfo.birthday?.simple?.date.year || null)
    .filter(Boolean);
  const deathYears: number[] = persons
    // @ts-ignore
    .map(person => person.basicInfo.deathday?.simple?.date.year || null)
    .filter(Boolean);

  const years = [...birthYears, ...deathYears];
  const minYear: number | null = years.length ? Math.min(...years) : null;

  const males = persons.filter(person => person.gender?.type === GenderType.Male);
  const females = persons.filter(person => person.gender?.type === GenderType.Female);

  const malesAge = males.map(person => calculatePersonAge(person).age).filter(Number);
  const femalesAge = females.map(person => calculatePersonAge(person).age).filter(Number);

  const generations = useMemo(() => {
    if (!isArrangerReady) {
      return 0;
    }
    // @ts-ignore Property 'perRowPersons' is private and only accessible within class 'PersonsNet'.
    return Object.values(arranger.personsNet.perRowPersons).filter(
      (persons: any) => persons.size > 0,
    ).length;
  }, [arranger, isArrangerReady]);

  const link = generateUri(routes.CURRENT_TREE, { treeId: treeId });

  const date = new Date().toLocaleString('ru', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  const averageMalesAge = Math.round(average(malesAge));
  const averageFemalesAge = Math.round(average(femalesAge));

  return {
    personsCount,
    personsCountPlural: plural(['персона', 'персоны', 'персон'], personsCount),
    malesPercent: Math.round(percentage(males.length, personsCount)),
    femalesPercent: Math.round(percentage(females.length, personsCount)),
    surnamesCount,
    surnamesCountPlural: plural(['фамилия', 'фамилии', 'фамилий'], surnamesCount),
    averageMalesAge,
    averageMalesAgePlural: plural(['год', 'года', 'лет'], averageMalesAge),
    averageFemalesAge,
    averageFemalesAgePlural: plural(['год', 'года', 'лет'], averageFemalesAge),
    placesCount,
    placesCountPlural: plural(
      ['населенный пункт', 'населенных пункта', 'населенных пунктов'],
      placesCount,
    ),
    minYear,
    generations,
    generationsPlural: plural(['поколение', 'поколения', 'поколений'], generations),
    date,
    link,
  };
}
