import { useCallback, useEffect, useState } from 'react';

import type { FlexDate } from '@famirytree/treelib/interfaces';
import { Input, Select } from '@famiryui/components';

import DateInput from '@/components/common/DateInput';
import { DATE_OPTIONS } from '@/constants/dates';
import { getFormattedDateValue } from '@/helpers/dates';
import { usePersonDates } from '@/hooks/person';
import classnames from '@/utils/classnames';

const dateTypeOptions = [
  ...DATE_OPTIONS.map(({ value, label }) => ({
    value,
    label,
  })),
];

export default function FlexibleDateInput({
  name,
  value,
  disabled,
  onChange,
}: {
  name: string;
  value?: FlexDate;
  disabled?: boolean;
  onChange: (data: { name: string; value: FlexDate }) => void;
}) {
  const {
    personDates: { birthday: dateValue },
    initPersonDates,
    handleDateTypeChange: onChangeDateType,
    handleDateValueChange: onUpdateDateValue,
  } = usePersonDates();
  const formattedDateValue = getFormattedDateValue(dateValue);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (initialized) return;

    if (value) {
      initPersonDates(value, '');
      setInitialized(true);
    }
  }, [initialized]);

  useEffect(() => {
    onChange({
      name,
      value: dateValue,
    });
  }, [name, dateValue]);

  const handleDateTypeChange = useCallback(
    ({ value }: { value?: string }) => {
      onChangeDateType({
        value,
        dataset: {
          date: 'birthday',
        },
      });
    },
    [onChangeDateType],
  );

  const handleStartDateChange = useCallback(
    ({
      details,
    }: {
      name?: string;
      value: {
        day?: string | number;
        month?: string | number;
        year?: string | number;
      };
      details: {
        name?: string;
        value?: string;
      };
    }) => {
      onUpdateDateValue({
        name: details.name,
        value: details.value?.trim() || '',
        dataset: {
          range: 'start',
          date: 'birthday',
        },
      });
    },
    [onUpdateDateValue],
  );

  const handleEndDateChange = useCallback(
    ({
      details,
    }: {
      name?: string;
      value: {
        day?: string | number;
        month?: string | number;
        year?: string | number;
      };
      details: {
        name?: string;
        value?: string;
      };
    }) => {
      onUpdateDateValue({
        name: details.name,
        value: details.value?.trim() || '',
        dataset: {
          range: 'end',
          date: 'birthday',
        },
      });
    },
    [onUpdateDateValue],
  );

  const handleFreeFormatDateChange = useCallback(
    ({ value }: { value?: string }) => {
      onUpdateDateValue({
        name: 'free',
        value: value,
        dataset: {
          date: 'birthday',
        },
      });
    },
    [onUpdateDateValue],
  );

  const classNames = classnames(
    'FlexibleDateInput',
    `FlexibleDateInput--${dateValue.current_date_type}`,
  );

  return (
    <div className={classNames}>
      <Select
        className="FlexibleDateInput__type-select"
        label="Тип даты"
        value={dateValue.current_date_type}
        disabled={disabled}
        options={dateTypeOptions}
        onChange={handleDateTypeChange}
      />

      {typeof dateValue[dateValue.current_date_type] !== 'undefined' &&
      !formattedDateValue.startDate?.isFreeFormat ? (
        <>
          <DateInput
            name={formattedDateValue.endDate?.date ? 'startDate' : 'date'}
            // @ts-ignore
            value={formattedDateValue.startDate?.date}
            disabled={disabled}
            onChange={handleStartDateChange}
          />

          {formattedDateValue.endDate?.date && (
            <>
              {'—'}
              <DateInput
                name="endDate"
                // @ts-ignore
                value={formattedDateValue.endDate?.date}
                disabled={disabled}
                onChange={handleEndDateChange}
              />
            </>
          )}
        </>
      ) : (
        <Input
          placeholder="Дата в свободном формате"
          name="free"
          // @ts-ignore
          value={formattedDateValue.startDate.date}
          disabled={disabled}
          autoComplete="off"
          onChange={handleFreeFormatDateChange}
        />
      )}
    </div>
  );
}
