import Spinner from './Spinner';

import './LoadingSpinner.css';

export const LoadingSpinner = ({ fullScreen = false }) => {
  return fullScreen ? (
    <div className="fullscreen">
      <Spinner />
    </div>
  ) : (
    <Spinner />
  );
};

export default LoadingSpinner;
