import { store, uploadDocument as _uploadDocument } from '@famirytree/crdt-storage';

export function uploadDocument(file, treeId = '') {
  return store.dispatch(_uploadDocument(file)).then(resp => {
    return resp.payload;
  });
}

export const documentsApi = {
  uploadDocument,
};

export default documentsApi;
