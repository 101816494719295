import './Skeleton.css';

export const Skeleton = ({
  width,
  height,
  borderRadius,
}: {
  height?: string;
  width?: string;
  borderRadius?: string;
}) => (
  <span
    className="skeleton_loader"
    style={{ width, height, borderRadius }}
  />
);

export default Skeleton;
