import { Buffer } from 'buffer';

export function toBase64(value: any): string {
  return Buffer.from(JSON.stringify(value)).toString('base64');
}

export function fromBase64(value: string): string {
  return Buffer.from(value, 'base64').toString();
}

export function bytesToSize(bytes: number): string {
  const sizes = ['Байт', 'Кб', 'Мб', 'Гб', 'Тб'];

  if (bytes === 0) return '0 Байт';

  const i = Math.floor(Math.log(bytes) / Math.log(1024));

  return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
}
