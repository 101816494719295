import Button from '@/components/common/Button/Button';
import Typography from '@/components/common/Typography/Typography';

import './ErrorState.scss';

export default function ErrorState({
  icon = false,
  title,
  subtitle,
  description,
  buttonContent,
  onButtonClick,
}: {
  icon?: boolean;
  title?: string;
  subtitle?: string;
  description?: string;
  buttonContent?: string;
  onButtonClick?: () => void;
}) {
  return (
    <div className="server-error">
      <div className="server-error__wrapper">
        <Typography
          as="h4"
          className="server-error__header"
          fontSize="XXL"
          fontWeight="bold"
        >
          {icon && <div className="server-error__icon" />}
          {title}
        </Typography>

        {subtitle && (
          <Typography
            as="p"
            fontSize="L"
          >
            {subtitle}
          </Typography>
        )}

        {description && (
          <Typography
            as="p"
            fontSize="L"
          >
            {description}
          </Typography>
        )}

        {buttonContent && onButtonClick && (
          <Button
            className="server-error__button"
            onClick={onButtonClick}
          >
            {buttonContent}
          </Button>
        )}
      </div>
    </div>
  );
}
