import { useMemo } from 'react';

import { Storage as EditorDocumentStorage } from '@famiryeditor/editor';

import documentsApi from '@/api/documents';
import { addDocumentSources } from '@/helpers/document';
import { useStore } from '@/hooks/store';

export default useDocumentStorage;

export function useDocumentStorage() {
  const currentTreeId = useStore(state => state.currentTree.treeId);

  const storage: EditorDocumentStorage = useMemo(
    () => ({
      upload(file: File) {
        return documentsApi.uploadDocument(file, currentTreeId).then(document => {
          if (!document || document instanceof Error) return;

          return addDocumentSources(document, currentTreeId);
        });
      },
      delete() {
        return Promise.resolve();
      },
    }),
    [currentTreeId],
  );

  return storage;
}
