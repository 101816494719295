import { forwardRef, useCallback } from 'react';

export type FileInputProps = {
  accept?: string;
  onChange: (files: FileList | null) => void;
};

const FileInput = forwardRef<HTMLInputElement, FileInputProps>(
  ({ accept, onChange, ...props }, ref): JSX.Element => {
    const handleChange = useCallback(
      event => {
        onChange(event.target.files);
      },
      [onChange],
    );

    return (
      <input
        ref={ref}
        className="FileInput"
        type="file"
        accept={accept}
        onChange={handleChange}
        {...props}
      />
    );
  },
);

FileInput.displayName = 'FileInput';

export default FileInput;
