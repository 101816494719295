import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 } from 'uuid';

import {
  createPersonRef,
  store,
  updatePersonMainCard,
  updatePersonRef,
  updateTreeRef,
  useDispatch,
} from '@famirytree/crdt-storage';
import {
  GenderType,
  MainInfoPageType,
  MainInfoTabContentType,
  MainInfoTabType,
  PersonDBView,
  PersonRoleType,
  PersonStatusType,
  TreeDBView,
} from '@famirytree/treelib/interfaces';

import routes from '@/constants/routes';
import { generateUri } from '@/utils';

const MONTHES_PAR = [
  'января',
  'февраля',
  'марта',
  'апреля',
  'мая',
  'июня',
  'июля',
  'августа',
  'сентября',
  'октября',
  'ноября',
  'декабря',
];

const MONTHES = [
  'январь',
  'февраль',
  'март',
  'апрель',
  'май',
  'июнь',
  'июль',
  'август',
  'сентябрь',
  'октябрь',
  'ноябрь',
  'декабрь',
];

export interface PromoPersonProps {
  firstName: string;
  lastName: string;
  middleName: string;
  rank: string;
  specialty: string;
  start_month: string;
  start_year: string;
  end_month: string;
  end_year: string;
  story: string | null;
  user_id: string | null;
}

const clone = v => JSON.parse(JSON.stringify(v));

enum V2BlockType {
  Text = 'https://famiry.ru/refs/BlockType/v2/Paragraph',
  Heading = 'https://famiry.ru/refs/BlockType/v2/Heading',
}

const addBlockToPerson = (person: PersonDBView, block: any) => {
  const tabRef = Object.values(person.pages)[0]!.content.find(t => t.type === MainInfoTabType.Main);
  if (!tabRef) {
    console.error('Tab is not found');
    return;
  }
  const tab = person.tabs[tabRef.resource];
  tab.content.push({ resource: block.id, type: MainInfoTabContentType.Block });
  // @ts-ignore
  tab.version = 2;
  person.blocks[block.id] = block;
};

const generateBlock = (type: V2BlockType, value: string, props: unknown = undefined) => {
  const id = v4();
  const ret = {
    id,
    type,
    version: 1,
    children: [
      {
        detail: 0,
        format: 0,
        mode: 'normal',
        style: '',
        text: value,
        type: 'text',
        version: 1,
      },
    ],
  } as any;
  if (props) {
    ret.props = props;
  }
  return ret;
};

export default () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const createPerson = useCallback(
    (personInfo: PromoPersonProps) => {
      // в этом месте нельзя использовать useSelector, т.к. это приводит к зацикленному рендерингу компонент
      const state = store.getState();
      const currentTree = state.trees[state.currentTree.treeId] as TreeDBView;
      if (!currentTree) {
        return undefined;
      }
      const ts = new Date().toISOString();
      const id = v4(),
        pageId = v4(),
        tabId = v4();

      const start_month = parseInt(personInfo.start_month ?? 1);
      const start_year = personInfo.start_year ?? '';
      const end_month = parseInt(personInfo.end_month ?? 1);
      const end_year = personInfo.end_year ?? '';

      let person: PersonDBView;
      if (personInfo.user_id === null) {
        person = {
          id,
          avatar: { resource: '' },
          basicInfo: {
            birthday: '',
            deathday: '',
            firstName: personInfo.firstName ?? '',
            lastName: personInfo.lastName ?? '',
            middleName: personInfo.middleName ?? '',
            lastNameAtBirth: '',
            placeOfBirth: '',
            placeOfDeath: '',
            status: PersonStatusType.Unknown,
          },
          // @ts-ignore
          blocks: {},
          tabs: {
            [tabId]: {
              id: tabId,
              created: ts,
              lastEdit: ts,
              content: [],
              name: 'Главная',
              type: MainInfoTabType.Main,
              // @ts-ignore
              version: 2,
            },
          },
          pages: {
            [pageId]: {
              id: pageId,
              content: [{ resource: tabId, type: MainInfoTabType.Main }],
              created: ts,
              lastEdit: ts,
              name: '',
              type: MainInfoPageType.Main,
            },
          },
          mainInfo: [{ resource: pageId }],
          created: ts,
          documents: [],
          facts: [],
          gender: { type: GenderType.Unknown },
          names: [],
          mainPersonRelationship: null,
        };
      } else {
        person = clone(state.persons[personInfo.user_id]) as PersonDBView;
      }
      if (personInfo.rank) {
        addBlockToPerson(
          person,
          generateBlock(V2BlockType.Heading, `Воинское звание`, { level: 2 }),
        );
        addBlockToPerson(person, generateBlock(V2BlockType.Text, `${personInfo.rank ?? ''}`));
        if (personInfo.specialty) {
          addBlockToPerson(
            person,
            generateBlock(V2BlockType.Text, `${personInfo.specialty ?? ''}`),
          );
        }
      }
      if (start_year && end_year) {
        addBlockToPerson(person, generateBlock(V2BlockType.Heading, `Годы службы`, { level: 3 }));
        addBlockToPerson(
          person,
          generateBlock(
            V2BlockType.Text,
            `C ${MONTHES_PAR[start_month - 1] ?? ''} ${start_year} по ${MONTHES[end_month - 1] ?? ''} ${end_year}`,
          ),
        );
      }
      if (personInfo.story) {
        addBlockToPerson(
          person,
          generateBlock(V2BlockType.Heading, 'История моего героя', { level: 2 }),
        );
        addBlockToPerson(person, generateBlock(V2BlockType.Text, personInfo.story ?? ''));
      }
      if (!personInfo.user_id) {
        dispatch(createPersonRef(person));
        dispatch(
          updateTreeRef({
            ...currentTree,
            persons: [...currentTree.persons, { resource: id, role: PersonRoleType.Unknown }],
          }),
        );
      } else {
        dispatch(updatePersonRef(person));
      }
      dispatch(
        updatePersonMainCard({
          person: person,
          // @ts-ignore
          refs: {
            blocks: person.blocks,
            pages: person.pages,
            tabs: person.tabs,
          },
        }),
      );
      navigate(
        generateUri(routes.TREE_BY_PERSON, {
          treeId: currentTree.id,
          personId: person.id,
        }),
      );
      return person.id;
    },
    [dispatch, navigate],
  );

  return createPerson;
};
