import { FallbackRender } from '@sentry/react';

import Button from '@/components/common/Button/Button';
import Typography from '@/components/common/Typography/Typography';
import Reload from '@/images/icons/sync-alt-solid.svg';
import { IS_DEV } from '@/vars/env';

import './FallbackError.css';

const FallbackError: FallbackRender = ({ resetError }) => {
  return IS_DEV ? (
    <div className="fallback-error">
      <Typography>Что-то пошло не так</Typography>
      <Button
        onClick={() => resetError()}
        className="fallback-error__button"
      >
        <img src={Reload} />
      </Button>
    </div>
  ) : (
    <></>
  );
};

export default FallbackError;
