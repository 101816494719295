import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { NIL } from 'uuid';

import { AppState } from '@famirytree/crdt-storage';
import { AccessMode } from '@famirytree/treelib/famiry-path';
import { EntityTypeName } from '@famirytree/treelib/interfaces';

import { PageEntityContext } from '@/context';
import { EntryAccessMode } from '@/hooks/access';
import { useIsSharedPerson } from '@/hooks/person';
import { useCurrentTree, useIsSharedTree } from '@/hooks/tree';

export default useUserMode;

export function useUserMode() {
  const currentTree = useCurrentTree();
  const currentUser = useSelector((s: AppState) => s.currentUser.user);
  const { grants, personId } = useSelector((s: AppState) => s.currentTree);

  const sharedPerson = useIsSharedPerson(personId);
  const sharedTree = useIsSharedTree(currentTree?.id ?? NIL);
  const accessContext = useContext(PageEntityContext);

  if (!currentUser || (!currentTree?.id && !sharedPerson)) {
    return EntryAccessMode.Unknown;
  }

  if (currentTree?.owner?.resource === currentUser.id) {
    return EntryAccessMode.Owner;
  }

  const treeGrant = grants.find(
    g => g.refId === accessContext.treeId && g.refType === EntityTypeName.u_tree,
  );

  const personGrant = grants.find(
    g => g.refId === accessContext.entityId && g.refType === EntityTypeName.u_person,
  );

  if (personGrant) {
    return personGrant.rules[0].defaultMode === AccessMode.ReadOnly
      ? EntryAccessMode.CollaborativeCard_View
      : EntryAccessMode.CollaborativeCard_Edit;
  }

  if (treeGrant) {
    return treeGrant.rules[0].defaultMode === AccessMode.ReadOnly
      ? EntryAccessMode.Collaborative_View
      : EntryAccessMode.Collaborative_Edit;
  }

  if (personId && sharedPerson) {
    return EntryAccessMode.PublicPerson_Guest;
  }

  if (sharedTree) {
    return currentUser.passportUser
      ? EntryAccessMode.Public_Authorized
      : EntryAccessMode.Public_Guest;
  }

  return EntryAccessMode.Unknown;
}
