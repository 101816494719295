import type { DOMAttributes, SelectHTMLAttributes } from 'react';

import { noop } from '@/utils';

import './Select.css';

const Select = ({
  className = '',
  label = '',
  name,
  value,
  options = [],
  disabled,
  onChange = noop,
  onClick = noop,
}: {
  className?: string;
  label?: string;
  name?: string;
  value?: any;
  options?: any[];
  disabled?: boolean;
  onChange?: SelectHTMLAttributes<HTMLSelectElement>['onChange'];
  onClick?: DOMAttributes<HTMLSelectElement>['onClick'];
}) => {
  return (
    <label className="selectLabel selectLabelCustom">
      <span
        style={{ zIndex: '2' }}
        className="selectPlaceholder selectPlaceholderInputFilled"
      >
        {label}
      </span>

      <select
        name={name}
        className={`select ${className}`}
        value={value}
        onChange={onChange}
        onClick={onClick}
        disabled={disabled}
      >
        {options.map((opt: any, i) => {
          return (
            <option
              key={i}
              id={opt.value}
              value={opt.value}
            >
              {opt.name}
            </option>
          );
        })}
      </select>
    </label>
  );
};

export default Select;
