import { useEffect, useState } from 'react';

import FamiryMap, { FamiryMapProps, MapboxOptions } from '@famirymaps/map';

import { useMetrics } from '@/hooks/metrics';
import useSession from '@/hooks/useSession';
import { MAPBOX_ACCESS_TOKEN, MAPBOX_PROJECT, MAPBOX_STYLE_ID, MAPS_SERVICE_URL } from '@/vars/env';

import '@famirymaps/map/dist/index.css';

export type MapProps<DetailViewData = unknown> = Omit<
  FamiryMapProps<MapboxOptions, DetailViewData>,
  'apiUrl'
>;

const options: MapboxOptions = {
  accessToken: MAPBOX_ACCESS_TOKEN,
  project: MAPBOX_PROJECT,
  styleId: MAPBOX_STYLE_ID,
  imagePath: 'https://drevodrive.famiry.ru/map/images/',
  vector: true,
};

export default function Map<DetailViewData = unknown>({ ...props }: MapProps<DetailViewData>) {
  const { emitEvent } = useMetrics();
  const { sessionId } = useSession();

  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (initialized) return;

    setInitialized(true);
    emitEvent('map_init', { session: sessionId });
  }, [initialized, emitEvent, sessionId]);

  return (
    <FamiryMap<MapboxOptions, DetailViewData>
      apiUrl={MAPS_SERVICE_URL}
      options={options}
      center={[56.839104, 60.60825]}
      zoom={4}
      maxZoom={14}
      minZoom={3}
      {...props}
    />
  );
}
