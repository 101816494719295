export function trimObject(object: Record<string, any> = {}) {
    return Object.entries(object).reduce(
        (result, [key, value]) => {
            if (value !== undefined && value !== '') {
                result[key] = value;
            }

            return result;
        },
        {} as Record<string, any>
    );
}

export function throttle(callback: (...args: any[]) => void, delay: number) {
    let timeout: number | null = null;

    return (...args: any[]) => {
        if (timeout) return;

        return new Promise(resolve => {
            timeout = window.setTimeout(() => {
                timeout = null;
                resolve(callback(...args));
            }, delay);
        });
    };
}

export function debounce(callback: (...args: any[]) => void, delay: number) {
    let timeout: number;

    return (...args: any[]) => {
        clearTimeout(timeout);
        timeout = window.setTimeout(() => callback(...args), delay);
    };
}

export async function loadScript(
    id: string,
    url: string,
    nonce: string
): Promise<void> {
    return new Promise((resolve, reject) => {
        if (document.getElementById(id)) {
            resolve();
            return;
        }

        const script = document.createElement('script');
        script.id = id;
        script.type = 'text/javascript';
        script.src = url;
        script.async = true;
        script.defer = true;
        script.onload = () => resolve();
        script.onerror = reject;

        if (nonce) {
            script.nonce = nonce;
        }

        document.head.appendChild(script);
    });
}
