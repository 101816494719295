import { useCallback, useRef, useState } from 'react';

import { Button, Flex, Heading, Textarea } from '@/fui';
import AddCircle from '@/fui/icons/add-circled-outline.svg';
import RemoveCircle from '@/fui/icons/remove-empty.svg';
import type { Alias } from '@/lib/map';

import AliasInput from '../AliasInput';
import PlaceItem from '../PlaceItem';

const defaultAlias = {
    name: '',
    aliases: [],
    comment: ''
};

export default function AliasForm({
    alias = defaultAlias,
    onSave,
    onCancel
}: {
    alias: Partial<Alias>;
    onSave: (alias: Partial<Alias>) => void;
    onCancel: () => void;
}) {
    const nameInputRef = useRef<HTMLInputElement>(null);
    const commentInputRef = useRef<HTMLTextAreaElement>(null);

    const [aliases, setAliases] = useState(alias.aliases || []);

    const handleSubmit = useCallback(
        event => {
            event.preventDefault();
            event.target.checkValidity();

            onSave({
                ...alias,
                name: nameInputRef.current?.value,
                comment: commentInputRef.current?.value,
                aliases
            });
        },
        [alias, aliases, onSave]
    );

    const handleNameReset = useCallback(() => {
        nameInputRef.current!.value = '';
        nameInputRef.current?.focus();
    }, []);

    const handleAliasesChange = useCallback((value, index) => {
        setAliases(aliases => {
            const newAliases = aliases.slice();
            newAliases[index] = value;
            return newAliases;
        });
    }, []);

    const handleAliasReset = useCallback(index => {
        setAliases(aliases => {
            const newAliases = aliases.slice();
            newAliases[index] = '';
            return newAliases;
        });
    }, []);

    const handleAddAlias = useCallback(() => {
        setAliases(aliases => aliases.concat(''));
    }, []);

    const handleRemoveAlias = useCallback(index => {
        setAliases(aliases => {
            const newAliases = aliases.slice();
            newAliases.splice(index, 1);
            return newAliases;
        });
    }, []);

    return (
        <form
            className="AliasForm"
            onSubmit={handleSubmit}
        >
            <Heading
                type="h4"
                content={
                    alias.id ? 'Редактирование объекта' : 'Добавление объекта'
                }
                bold
            />

            {alias.place && <PlaceItem place={alias.place} />}

            <AliasInput
                ref={nameInputRef}
                defaultValue={alias.name}
                placeholder="Введите название объекта"
                required
                onReset={handleNameReset}
            />

            {/* <Label text="Координаты:" size="small" inline>
                {alias.place ?
                    <Pill end={<BackIcon />} size="small">
                        {alias.place.lat}, {alias.place.lon}
                    </Pill>
                    :
                    <Text size="small">укажите точку на карте</Text>
                }
            </Label> */}

            {aliases.map((alias, index) => (
                <Flex
                    key={index}
                    gap="small"
                    align="center"
                >
                    <AliasInput
                        placeholder="Введите название объекта"
                        value={alias}
                        onChange={({ value }: { value: string }) =>
                            handleAliasesChange(value, index)
                        }
                        onReset={() => handleAliasReset(index)}
                    />

                    <Button
                        icon={<RemoveCircle />}
                        color="danger"
                        size="small"
                        variant="plain"
                        title="Удалить псевдоним"
                        onClick={() => handleRemoveAlias(index)}
                    />
                </Flex>
            ))}

            <Button
                icon={<AddCircle />}
                content="Добавить еще название"
                variant="text"
                iconProps={{ size: 'smaller' }}
                onClick={handleAddAlias}
            />

            <Textarea
                ref={commentInputRef}
                defaultValue={alias.comment}
                placeholder="Введите комментарий"
            />

            <Button
                type="submit"
                content="Сохранить"
                variant="filled"
                color="primary"
                fluid
            />

            <Button
                type="button"
                content="Назад"
                fluid
                onClick={onCancel}
            />
        </form>
    );
}
