import { Avatar } from '@famiryui/components';

export default function Persona({
  name,
  imageUrl,
  relation,
}: {
  name: string;
  imageUrl?: string;
  relation?: string;
}) {
  return (
    <div className="Persona">
      <Avatar
        className="Persona__avatar"
        content={name.at(0)}
        src={imageUrl}
        shape="rounded"
        size="small"
      />

      <span className="Persona__name">{name}</span>

      {relation && <span className="Persona__relation">{relation}</span>}
    </div>
  );
}
