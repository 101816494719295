import { useCallback } from 'react';

import classnames from 'classnames';

import Coordinates from '@/components/Coordinates';
import { Button, Field, Flex, ListItem, Pill, PillGroup, Text } from '@/fui';
import EditPencil from '@/fui/icons/edit-pencil.svg';
import type { Alias } from '@/lib/map';

export default function AliasItem({
    alias,
    onSelect,
    onEdit,

    className,
    ...props
}: {
    alias: Alias;
    selected: boolean;
    interactive?: boolean;
    onSelect: (alias: Alias) => void;
    onEdit: (alias: Alias) => void;
    className?: string;
}) {
    const handleRootClick = useCallback(() => {
        onSelect(alias);
    }, [alias, onSelect]);

    const handleEditButtonClick = useCallback(
        event => {
            event.stopPropagation();

            onEdit(alias);
        },
        [alias, onEdit]
    );

    const { lat, lon } = alias.place || alias;

    return (
        <ListItem
            className={classnames('AliasItem', className)}
            content={
                <Flex
                    dir="column"
                    gap="smallest"
                >
                    <Text
                        content={alias.name}
                        color="primary"
                        weight="semibold"
                    />

                    {alias.aliases?.length > 0 && (
                        <PillGroup>
                            {alias.aliases.map(alias => (
                                <Pill
                                    key={alias}
                                    content={alias}
                                    size="small"
                                    variant="plain"
                                />
                            ))}
                        </PillGroup>
                    )}

                    {alias.place && (
                        <Text
                            type="p3"
                            content={alias.place.address?.displayName}
                        />
                    )}

                    {alias.comment && (
                        <Field
                            label="Комментарий:"
                            size="small"
                            inline
                        >
                            <Text
                                type="p4"
                                content={alias.comment}
                            />
                        </Field>
                    )}

                    {lat && lon && (
                        <Coordinates
                            lat={lat}
                            lon={lon}
                        />
                    )}
                </Flex>
            }
            end={
                <Button
                    className="SearchResultsItem__end-action"
                    icon={<EditPencil />}
                    size="small"
                    variant="plain"
                    iconProps={{ size: 'smaller' }}
                    title="Изменить элемент"
                    onClick={handleEditButtonClick}
                />
            }
            shape="rounded"
            onClick={handleRootClick}
            {...props}
        />
    );
}
