import type { ReactNode } from 'react';

import styles from './PremiumBadge.module.scss';

export default function PremiumBadge({
  content = 'Премиум',
  children = content,
}: {
  content?: ReactNode;
  children?: ReactNode;
}) {
  return <span className={styles.root}>{children}</span>;
}
