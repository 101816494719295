/* eslint-disable simple-import-sort/imports */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { store } from '@famirytree/crdt-storage';
import { Provider as UIProvider } from '@famiryui/components';

import '@famiryui/components/dist/index.css';
import 'material-symbols/rounded.css';

import Router from './components/Router';
import SentryErrorBoundary from './components/SentryErrorBoundary';
import ServerError from './components/ServerError';

import './index.scss';
import './debug/setupGlobals';
import './components/GlobalProductProvider';

setTimeout(() => {
  // eslint-disable-next-line react/no-deprecated
  ReactDOM.render(
    <Provider store={store}>
      <React.StrictMode>
        <SentryErrorBoundary>
          <BrowserRouter>
            <ServerError>
              <UIProvider>
                <Router />
              </UIProvider>
            </ServerError>
          </BrowserRouter>
        </SentryErrorBoundary>
      </React.StrictMode>
    </Provider>,
    document.getElementById('root'),
  );
}, 1);
