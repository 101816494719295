import { type ReactNode, useCallback, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

import { Button } from '@famiryui/components';
import { ReactComponent as CancelIcon } from '@famiryui/icons/svg/cancel.svg';

import { useDisableBodyScroll } from '@/hooks/ui';
import { useClickOutside } from '@/hooks/useClickOutside';
import useReactPortal from '@/hooks/useReactPortal';
import { cn, noop } from '@/utils';

import styles from './ModalCentered.module.scss';

export default function ModalCentered({
  withBackdrop = false,
  children,
  className,
  contentClassName,
  onClose = noop,
}: {
  withBackdrop?: boolean;
  children: ReactNode;
  className?: string;
  contentClassName?: string;
  onClose?: () => void;
}) {
  const element = useReactPortal('modal');

  const contentRef = useRef<HTMLDivElement>(null);

  useDisableBodyScroll();
  useClickOutside([contentRef], onClose);

  const handleBackdropClick = useCallback(
    e => {
      if (e.target === e.currentTarget) {
        e.stopPropagation();
        onClose?.();
      }
    },
    [onClose],
  );

  useEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = originalStyle;
    };
  }, []);

  return createPortal(
    <div className={cn(className, styles.root)}>
      {withBackdrop && <div className={styles.backdrop} />}

      <div
        ref={contentRef}
        className={cn(contentClassName, styles.content)}
        onClick={handleBackdropClick}
      >
        {children}

        <Button
          className={styles.button}
          icon={<CancelIcon />}
          onClick={onClose}
        />
      </div>
    </div>,
    element,
  );
}
