import { MapboxClient, MapboxOptions, OsmClient, OsmOptions } from './clients';
import {
    LatLon,
    MapClient,
    MapOptions,
    MapProvider,
    MarkerOptions
} from './types';

export default class Map {
    static getClient<TOptions extends MapOptions>(
        element: HTMLElement,
        provider: MapProvider,
        options?: TOptions
    ): MapClient {
        switch (provider) {
            case MapProvider.Mapbox:
                return new MapboxClient(element, options as MapboxOptions);
            case MapProvider.OSM:
                return new OsmClient(element, options as OsmOptions);
        }

        throw new Error(`Map provider '${provider}' not found`);
    }

    private _client: MapClient;

    constructor(
        element: HTMLElement,
        provider: MapProvider,
        options: MapOptions
    ) {
        if (!element) throw new Error('Root element not found');
        if (!provider) throw new Error('Map provider not specified');

        this._client = Map.getClient(element, provider, options);
    }

    get markers() {
        return this._client.markers;
    }

    async init() {
        return this._client.init();
    }

    destroy() {
        this._client.destroy();
    }

    async setProvider(provider: MapProvider, options?: MapOptions) {
        this._client.destroy();
        this._client = Map.getClient(this._client.element, provider, options);
        await this._client.init();
    }

    getCenter() {
        return this._client.getCenter();
    }

    setCenter(position: LatLon, zoom?: number) {
        this._client.setCenter(position, zoom);
    }

    getZoom() {
        return this._client.getZoom();
    }

    setZoom(zoom: number) {
        this._client.setZoom(zoom);
    }

    fitBounds(bounds: [LatLon, LatLon]) {
        this._client.fitBounds(bounds);
    }

    createMarker(position: LatLon, options: MarkerOptions) {
        return this._client.createMarker(position, options);
    }

    addMarker(position: LatLon, options: MarkerOptions) {
        return this._client.addMarker(position, options);
    }

    removeMarkers() {
        this._client.removeMarkers();
    }

    addClusterGroup(markers: L.Marker[]) {
        return this._client.addClusterGroup(markers);
    }

    clear() {
        this._client.clear();
    }

    layout() {
        this._client.layout();
    }
}
