import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { NIL } from 'uuid';

import { AppState } from '@famirytree/crdt-storage';
import { EntityTypeName, UUID } from '@famirytree/treelib/interfaces';

export default useAccessContext;

export function useAccessContext(entityType: EntityTypeName, entityId: UUID = NIL) {
  const treeId = useSelector((s: AppState) => s.currentTree.treeId) ?? NIL;

  return useMemo(
    () => ({
      entityType,
      entityId,
      treeId,
    }),
    [entityId, entityType, treeId],
  );
}
