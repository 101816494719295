import type { MediDocumentFullView as MediaDocument, UUID } from '@famirytree/treelib/interfaces';

export type MediaDocumentWithPreview = MediaDocument & {
  src?: string;
  previewSrc?: string;
};

export function getDocumentUrl(document: MediaDocument, treeId: UUID) {
  return `/tree/v1/media/${treeId}/${document.id}/`;
}

export function getDocumentPreviewUrl(document: MediaDocument, treeId: UUID) {
  if (/^image\//i.test(document.contentType)) {
    return `/tree/v1/media/resizer/height:500/doc/${treeId}/${document.id}/`;
  } else {
    return getDocumentUrl(document, treeId);
  }
}

export function addDocumentSources(doc: MediaDocument, treeId: string): MediaDocumentWithPreview {
  const src = `/tree/v1/media/${treeId}/${doc.id}/`;
  let previewSrc = src;

  if (/^image\//i.test(doc.contentType)) {
    previewSrc = `/tree/v1/media/resizer/height:500/doc/${treeId}/${doc.id}/`;
  }

  return {
    ...doc,
    src,
    previewSrc,
  };
}
