import * as Sentry from '@sentry/react';
import { ReactNode } from 'react';

import FallbackError from '@/components/FallbackError';

export const SentryErrorBoundary = ({
  tags = {},
  fallback = FallbackError,
  children,
}: {
  tags?: Record<string, string>;
  fallback?: Sentry.FallbackRender;
  children: ReactNode | ReactNode[];
}) => {
  return (
    <Sentry.ErrorBoundary
      beforeCapture={scope => {
        Object.keys(tags).forEach(tag => {
          scope.setTag(tag, tags[tag]);
        });
      }}
      fallback={fallback}
      showDialog={false}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default SentryErrorBoundary;
