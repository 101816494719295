import { useNavigate } from 'react-router-dom';

import ErrorState from '@/components/common/ErrorState';
import routes from '@/constants/routes';

export default function AuthError() {
  const navigate = useNavigate();

  const handleResetError = () => {
    navigate(routes.ROOT_TREE, { replace: true });
  };

  return (
    <ErrorState
      icon
      title="Ошибка: нет доступа"
      description="Это древо принадлежит другому пользователю и недоступно для просмотра"
      buttonContent="Вернуться в мое древо"
      onButtonClick={handleResetError}
    />
  );
}
