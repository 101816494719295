import { useFacts } from './useFacts';

export function useFactsTags() {
  const facts = useFacts();

  const allTags = Object.values(facts)?.reduce((tags, fact) => {
    return tags.concat(fact.tags);
  }, [] as string[]);

  return Array.from(new Set(allTags));
}
