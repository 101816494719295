import SupportLink from '@/components/common/SupportLink/SupportLink';
import Typography from '@/components/common/Typography/Typography';

import './GeneralErrorState.scss';

export default function GeneralErrorState() {
  return (
    <div className="GeneralErrorState">
      <div className="GeneralErrorState__icon" />
      <Typography
        as="p"
        fontSize="XL"
        fontWeight="bold"
      >
        Что-то сломалось...
      </Typography>
      <Typography
        as="p"
        fontSize="M"
        className=""
      >
        Мы уже знаем об этой проблеме и решаем её
      </Typography>
      <SupportLink>
        <Typography
          as="p"
          fontSize="M"
          className=""
        >
          Техподдержка
        </Typography>
      </SupportLink>
    </div>
  );
}
