import { useEffect, useState } from 'react';

import { ArrangerEventType } from '@famirytree/renderer';

import useRenderer from './useRenderer';

export interface UseArrangerReadyReturnType {
  isArrangerReady: boolean;
  isArrangerPreiterated: boolean;
}

export const useArrangerReady = (): UseArrangerReadyReturnType => {
  const { arranger } = useRenderer();

  const [arrangerState, setArrangerState] = useState<UseArrangerReadyReturnType>({
    isArrangerReady: arranger?.isReady || false,
    isArrangerPreiterated: arranger?.isPreiterated || false,
  });

  useEffect(() => {
    if (arranger) {
      const updateArrangerState = () => {
        setArrangerState({
          isArrangerPreiterated: arranger.isPreiterated || false,
          isArrangerReady: arranger.isReady || false,
        });
      };

      updateArrangerState();

      arranger.on(ArrangerEventType.PREITERATED, updateArrangerState);
      arranger.on(ArrangerEventType.ARRANGED, updateArrangerState);
      arranger.on(ArrangerEventType.RESET, updateArrangerState);

      return () => {
        arranger.off(ArrangerEventType.PREITERATED, updateArrangerState);
        arranger.off(ArrangerEventType.ARRANGED, updateArrangerState);
        arranger.off(ArrangerEventType.RESET, updateArrangerState);
      };
    }
  }, [arranger]);

  return arrangerState;
};
