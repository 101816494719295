"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.arrowPosition = exports.sameWidth = exports.maxSize = void 0;
const core_1 = require("@popperjs/core");
exports.maxSize = {
    name: 'maxSize',
    enabled: true,
    phase: 'main',
    requiresIfExists: ['offset', 'preventOverflow', 'flip'],
    fn({ options, state }) {
        const { cssPropertyName, viewportMargin = 0 } = options;
        const overflow = (0, core_1.detectOverflow)(state);
        const { x, y } = state.modifiersData.preventOverflow || { x: 0, y: 0 };
        const { width, height } = state.rects.popper;
        const [basePlacement] = state.placement.split('-');
        const widthProp = basePlacement === 'left' ? 'left' : 'right';
        const heightProp = basePlacement === 'top' ? 'top' : 'bottom';
        const maxWidth = width - overflow[widthProp] - x;
        const maxHeight = height - overflow[heightProp] - y - viewportMargin;
        if (cssPropertyName) {
            state.elements.popper.style.setProperty(cssPropertyName, `${maxHeight}px`);
        }
        else {
            state.elements.popper.style.maxWidth = `${maxWidth}px`;
        }
    }
};
exports.sameWidth = {
    name: 'sameWidth',
    enabled: true,
    phase: 'beforeWrite',
    requires: ['computeStyles'],
    fn: ({ state }) => {
        state.styles.popper.width = `${state.rects.reference.width}px`;
    },
    effect: ({ state }) => {
        state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`;
    }
};
exports.arrowPosition = {
    name: 'arrowPosition',
    enabled: true,
    phase: 'write',
    fn({ state }) {
        const basePlacement = state.placement.split('-')[0];
        switch (basePlacement) {
            case 'bottom':
                state.styles.arrow.top = '-4px';
                break;
            case 'top':
                state.styles.arrow.bottom = '-4px';
                break;
            case 'left':
                state.styles.arrow.right = '-4px';
                break;
            case 'right':
                state.styles.arrow.left = '-4px';
                break;
        }
    }
};
